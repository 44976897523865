import { Box, Flex } from '@chakra-ui/react'
import React from 'react'

import FormItem from '~/components/form/form-item'
import TitleSteps from '~/components/title-steps/title-steps'
import { JobApiInterface } from '~/model/interfaces/api/job.interface'

export const JobDescription = ({ job }: { job?: JobApiInterface }) => {
  return (
    <>
      <TitleSteps text="Título da Vaga" as="h3" mt="l4" mb="m3" />
      <FormItem name="jobName" placeholder="Título da vaga" inputType="text" />

      <Box mt="l4">
        <TitleSteps text="Perfil da Empresa" as="h3" mb="m3" />

        <Flex gap="m3" my="m2">
          <FormItem
            name="companyName"
            label="Nome da Empresa"
            placeholder="Nome da empresa"
            inputType="text"
          />
          <FormItem
            name="companyVideoUrl"
            label="Vídeo Institucional"
            placeholder="https://www.youtube.com/watch?v=videoID"
            inputType="text"
          />
        </Flex>
        <Flex gap="m3" my="m2">
          <FormItem
            name="companyRecruiterName"
            inputType="text"
            label="Responsavel pela vaga na empresa"
          />
          <FormItem
            name="companyRecruiterEmail"
            inputType="text"
            label="Email do responsavel"
            placeholder="responsavel@company.com.br"
          />
          <FormItem
            name="companyRecruiterPhone"
            inputType="phone"
            label="Telefone do responsavel"
            placeholder="+55 (13) 99999-9999"
            defaultValue={job?.companyRecruiterPhone}
          />
        </Flex>
        <FormItem
          name="jobDescription"
          inputType="textArea"
          minHeight="150px"
          placeholder="Descrição do perfil da Empresa"
        />
      </Box>
    </>
  )
}
