import { IconInterface } from '../../model/interfaces/components/icon.interface'

const ContractTypeIcon = ({
  width = 35,
  height = 30,
  ...props
}: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 35 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.1942 15.9052C12.4854 15.9388 11.7922 15.6904 11.2662 15.2142L11.2612 15.2092C10.687 14.6702 10.3383 13.9338 10.2852 13.1482C10.2847 13.045 10.3054 12.9429 10.3462 12.8482L12.3462 8.18715C12.6165 7.55525 13.1098 7.04484 13.7322 6.75315C15.2822 5.94689 16.9288 5.34199 18.6322 4.95315C20.2322 4.80315 24.5602 4.91715 25.6022 4.94815L27.9812 3.52115C28.1516 3.42112 28.3546 3.39239 28.5461 3.44123C28.7376 3.49006 28.9021 3.61251 29.0038 3.78195C29.1056 3.95139 29.1363 4.15413 29.0893 4.34611C29.0424 4.53808 28.9216 4.70377 28.7532 4.80715L26.1862 6.34715C26.0626 6.42149 25.9203 6.45864 25.7762 6.45415C25.7232 6.45415 20.4672 6.28915 18.7762 6.44715C17.2473 6.81893 15.7696 7.37641 14.3762 8.10715C14.0874 8.24273 13.8585 8.47978 13.7332 8.77316L11.8172 13.2472C11.9032 13.5902 12.0841 13.9021 12.3392 14.1472C12.6594 14.3201 13.0194 14.4059 13.3831 14.3961C13.7469 14.3862 14.1018 14.2811 14.4122 14.0912C15.3809 12.973 16.2302 11.7568 16.9462 10.4622C17.0124 10.3494 17.107 10.256 17.2205 10.1911C17.3339 10.1262 17.4624 10.0921 17.5932 10.0922H22.2142C22.4131 10.0922 22.6038 10.1712 22.7445 10.3118C22.8851 10.4525 22.9642 10.6432 22.9642 10.8422C22.9642 11.0411 22.8851 11.2318 22.7445 11.3725C22.6038 11.5131 22.4131 11.5922 22.2142 11.5922H18.0142C17.2736 12.9167 16.3779 14.1484 15.3462 15.2612C14.7868 15.6501 14.1271 15.8693 13.4462 15.8922C13.3615 15.9008 13.2775 15.9052 13.1942 15.9052Z"
      fill="#4ED7E7"
    />
    <path
      d="M33.4962 12.6239C33.3245 12.6238 33.158 12.5647 33.0246 12.4566C32.8912 12.3485 32.7989 12.1979 32.7632 12.0299C31.8896 9.16868 30.1867 6.63093 27.8702 4.73793C27.7806 4.66028 27.7108 4.56252 27.6663 4.45265C27.6219 4.34279 27.6041 4.22395 27.6144 4.10589C27.6248 3.98782 27.663 3.87389 27.7258 3.77343C27.7887 3.67297 27.8745 3.58884 27.9762 3.52793L33.1112 0.446935C33.2816 0.346898 33.4846 0.318176 33.6761 0.367011C33.8676 0.415846 34.0321 0.538292 34.1338 0.707733C34.2356 0.877174 34.2663 1.07992 34.2194 1.27189C34.1724 1.46386 34.0516 1.62955 33.8832 1.73294L29.6122 4.29593C31.816 6.28897 33.4164 8.86045 34.2312 11.7179C34.2541 11.8272 34.2523 11.9403 34.226 12.0488C34.1997 12.1574 34.1495 12.2587 34.0791 12.3454C34.0087 12.4321 33.9198 12.502 33.819 12.55C33.7181 12.598 33.6079 12.6229 33.4962 12.6229V12.6239Z"
      fill="#4ED7E7"
    />
    <path
      d="M30.929 18.7847C30.8022 18.7845 30.6776 18.7522 30.5666 18.6908C30.4557 18.6294 30.3622 18.5409 30.2948 18.4335C30.2273 18.3261 30.1882 18.2034 30.1811 18.0768C30.174 17.9502 30.1991 17.8239 30.254 17.7097C30.5553 17.0771 30.81 16.4234 31.016 15.7537C31.0689 15.5624 31.1955 15.3998 31.368 15.3017L33.126 14.3017C33.2971 14.2194 33.4931 14.205 33.6743 14.2615C33.8556 14.318 34.0087 14.4412 34.1027 14.6061C34.1967 14.7711 34.2247 14.9656 34.181 15.1503C34.1372 15.3351 34.025 15.4964 33.867 15.6017L32.367 16.4517C32.1505 17.1 31.8947 17.7345 31.601 18.3517C31.5412 18.4799 31.4464 18.5885 31.3275 18.6652C31.2086 18.7418 31.0705 18.7832 30.929 18.7847Z"
      fill="#4ED7E7"
    />
    <path
      d="M28.1722 24.4536C27.5358 24.455 26.921 24.2222 26.4452 23.7996L21.2212 19.6516C21.0654 19.5279 20.9651 19.3473 20.9424 19.1496C20.9197 18.952 20.9765 18.7534 21.1002 18.5976C21.2239 18.4418 21.4045 18.3415 21.6021 18.3188C21.7998 18.2961 21.9984 18.3529 22.1542 18.4766L27.3952 22.6386L27.4312 22.6686C27.652 22.8659 27.942 22.9678 28.2377 22.9518C28.5335 22.9359 28.8109 22.8035 29.0092 22.5836C29.206 22.3619 29.307 22.0714 29.2901 21.7755C29.2732 21.4796 29.1399 21.2024 28.9192 21.0046L22.7322 15.5166C22.6585 15.4509 22.5985 15.3714 22.5556 15.2825C22.5126 15.1937 22.4876 15.0972 22.4819 14.9987C22.4763 14.9002 22.4901 14.8015 22.5226 14.7083C22.555 14.6151 22.6055 14.5292 22.6712 14.4556C22.7368 14.3819 22.8164 14.3219 22.9052 14.279C22.9941 14.236 23.0905 14.211 23.1891 14.2053C23.2876 14.1997 23.3863 14.2135 23.4794 14.2459C23.5726 14.2784 23.6585 14.3289 23.7322 14.3946L29.9212 19.8836C30.3093 20.2279 30.5862 20.6799 30.7166 21.1821C30.847 21.6842 30.825 22.2139 30.6534 22.7035C30.4819 23.1931 30.1685 23.6206 29.7531 23.9316C29.3378 24.2425 28.8393 24.4228 28.3212 24.4496C28.2662 24.4536 28.2182 24.4536 28.1722 24.4536Z"
      fill="#4ED7E7"
    />
    <path
      d="M26.0342 27.515C25.4007 27.5158 24.7888 27.2854 24.3132 26.867L20.2082 23.767C20.0491 23.6477 19.9439 23.47 19.9157 23.2731C19.8876 23.0761 19.9389 22.8761 20.0582 22.717C20.1775 22.5579 20.3552 22.4527 20.5521 22.4245C20.7491 22.3964 20.9491 22.4476 21.1082 22.567L25.2372 25.685C25.2545 25.6983 25.2712 25.712 25.2872 25.726C25.4159 25.8401 25.569 25.9231 25.7347 25.9688C25.9004 26.0146 26.0745 26.0217 26.2434 25.9898C26.4123 25.9578 26.5717 25.8876 26.7093 25.7845C26.8469 25.6814 26.9591 25.5482 27.0372 25.395C27.0801 25.3046 27.1407 25.2237 27.2154 25.157C27.2901 25.0904 27.3773 25.0394 27.472 25.007C27.5667 24.9746 27.6669 24.9615 27.7668 24.9684C27.8666 24.9754 27.964 25.0022 28.0533 25.0474C28.1426 25.0926 28.222 25.1552 28.2867 25.2315C28.3515 25.3079 28.4003 25.3964 28.4303 25.4919C28.4603 25.5873 28.4708 25.6879 28.4614 25.7875C28.4519 25.8871 28.4226 25.9839 28.3752 26.072C28.1664 26.4831 27.8533 26.8321 27.4672 27.0841C27.0811 27.3361 26.6356 27.4823 26.1752 27.508C26.1279 27.5127 26.0809 27.515 26.0342 27.515Z"
      fill="#4ED7E7"
    />
    <path
      d="M22.3261 28.777C21.6822 28.7769 21.0606 28.5415 20.5782 28.115L19.6602 27.335C19.5831 27.2719 19.5194 27.194 19.4728 27.106C19.4261 27.018 19.3975 26.9216 19.3885 26.8223C19.3796 26.7231 19.3905 26.6231 19.4206 26.5282C19.4507 26.4332 19.4994 26.3452 19.5639 26.2693C19.6284 26.1934 19.7074 26.1311 19.7962 26.086C19.885 26.041 19.982 26.014 20.0813 26.0068C20.1807 25.9996 20.2805 26.0123 20.3749 26.0441C20.4693 26.0759 20.5564 26.1262 20.6312 26.192L21.5551 26.976L21.5681 26.987C21.7426 27.1436 21.9616 27.242 22.1945 27.2685C22.4275 27.2951 22.6629 27.2484 22.8682 27.135C22.9542 27.0849 23.0494 27.0524 23.1482 27.0395C23.2469 27.0265 23.3473 27.0334 23.4433 27.0596C23.5394 27.0859 23.6293 27.131 23.7078 27.1923C23.7862 27.2537 23.8516 27.3301 23.9003 27.417C23.9489 27.504 23.9797 27.5997 23.9909 27.6987C24.0021 27.7976 23.9935 27.8978 23.9655 27.9934C23.9376 28.089 23.8909 28.1781 23.8282 28.2555C23.7654 28.3328 23.6879 28.3969 23.6001 28.444C23.211 28.6622 22.7723 28.7769 22.3261 28.777Z"
      fill="#4ED7E7"
    />
    <path
      d="M7.82411 6.46182C7.66196 6.46204 7.50409 6.40977 7.37411 6.31282L1.21211 1.69082C1.05298 1.57148 0.947781 1.3938 0.91965 1.19689C0.89152 0.999976 0.942765 0.799953 1.06211 0.640823C1.18146 0.481693 1.35913 0.376492 1.55605 0.348361C1.75296 0.320231 1.95298 0.371476 2.11211 0.490823L7.96211 4.88182C9.60728 4.45575 11.3124 4.30971 13.0061 4.44982C13.1046 4.45639 13.2008 4.48229 13.2893 4.52605C13.3778 4.56981 13.4568 4.63056 13.5218 4.70485C13.5868 4.77914 13.6365 4.8655 13.6682 4.959C13.6998 5.05251 13.7127 5.15133 13.7061 5.24982C13.6995 5.34831 13.6736 5.44455 13.6299 5.53303C13.5861 5.62151 13.5254 5.70051 13.4511 5.76551C13.3768 5.83051 13.2904 5.88024 13.1969 5.91187C13.1034 5.94349 13.0046 5.95639 12.9061 5.94982C11.2833 5.79907 9.64652 5.95823 8.08311 6.41882C7.99995 6.44817 7.91229 6.46273 7.82411 6.46182Z"
      fill="#4ED7E7"
    />
    <path
      d="M3.20322 17.7574C3.08677 17.7575 2.97191 17.7304 2.86775 17.6783C2.7636 17.6263 2.67302 17.5506 2.60322 17.4574C2.17332 16.9437 1.69771 16.4701 1.18222 16.0424C1.08758 15.9633 1.01389 15.8621 0.967697 15.7478C0.921499 15.6335 0.904211 15.5095 0.917363 15.3869C0.930515 15.2642 0.973702 15.1468 1.0431 15.0448C1.1125 14.9429 1.20596 14.8596 1.31522 14.8024C4.55522 13.1024 6.06322 8.1164 6.07822 8.0664C6.13444 7.87558 6.26417 7.71491 6.43886 7.61974C6.61355 7.52456 6.81889 7.50268 7.00972 7.5589C7.20054 7.61513 7.36121 7.74486 7.45639 7.91955C7.55156 8.09424 7.57344 8.29958 7.51722 8.49041C7.45522 8.70141 6.07522 13.2834 2.90422 15.5764C3.22867 15.8801 3.52954 16.208 3.80422 16.5574C3.88779 16.6688 3.93868 16.8013 3.95119 16.94C3.96369 17.0788 3.93733 17.2182 3.87504 17.3428C3.81275 17.4674 3.717 17.5722 3.59851 17.6454C3.48003 17.7186 3.3435 17.7574 3.20422 17.7574H3.20322Z"
      fill="#4ED7E7"
    />
    <path
      d="M7.26231 17.0342C7.60077 17.0343 7.93512 17.1083 8.24204 17.2509C8.54896 17.3936 8.82105 17.6015 9.03931 17.8602L9.31031 18.1842C9.43153 18.3269 9.53465 18.4841 9.61731 18.6522C9.8493 19.1184 9.91774 19.6491 9.81164 20.159C9.70553 20.6688 9.43104 21.1282 9.03231 21.4632L8.99831 21.4902L7.16131 22.8632C6.68788 23.2512 6.08131 23.4383 5.47158 23.3845C4.86184 23.3306 4.29744 23.0401 3.89931 22.5752L3.88531 22.5632L3.62631 22.2462C3.23437 21.7715 3.04451 21.1619 3.09763 20.5486C3.15075 19.9353 3.44259 19.3674 3.91031 18.9672L3.94531 18.9392L5.78131 17.5642C6.19915 17.2221 6.72232 17.0349 7.26231 17.0342ZM7.89331 18.8282C7.82335 18.7449 7.73762 18.6761 7.64106 18.626C7.54449 18.5759 7.43898 18.5453 7.33057 18.536C7.22216 18.5267 7.11298 18.5389 7.00929 18.5719C6.9056 18.6048 6.80944 18.658 6.72631 18.7282L6.69631 18.7522L4.86731 20.1212C4.70537 20.2673 4.60627 20.4704 4.5908 20.688C4.57533 20.9055 4.64468 21.1206 4.78431 21.2882V21.2932L5.03731 21.6022C5.11743 21.6937 5.21611 21.7672 5.32679 21.8177C5.43748 21.8682 5.55765 21.8947 5.67931 21.8952C5.87605 21.8942 6.06604 21.8233 6.21531 21.6952L6.24631 21.6702L8.07731 20.3012C8.21401 20.1809 8.30706 20.0187 8.34197 19.84C8.37688 19.6613 8.35169 19.4761 8.27031 19.3132C8.24187 19.255 8.20626 19.2006 8.16431 19.1512V19.1452L7.89331 18.8282Z"
      fill="#4ED7E7"
    />
    <path
      d="M8.66924 25.6594C8.60258 25.6594 8.53324 25.6564 8.46124 25.6504C8.15323 25.6247 7.85333 25.5384 7.57876 25.3964C7.30418 25.2545 7.06035 25.0598 6.86124 24.8234L6.58324 24.4893C6.26578 24.0929 6.07659 23.6092 6.04086 23.1026C6.00513 22.5959 6.12456 22.0904 6.38324 21.6533C6.43358 21.5675 6.50051 21.4924 6.58014 21.4327C6.65977 21.3729 6.75049 21.3296 6.84703 21.3053C6.94356 21.281 7.04398 21.2761 7.14241 21.291C7.24085 21.3059 7.33534 21.3402 7.42037 21.392C7.5054 21.4437 7.57928 21.5119 7.63768 21.5926C7.69609 21.6732 7.73786 21.7646 7.76056 21.8616C7.78326 21.9585 7.78643 22.059 7.7699 22.1571C7.75336 22.2553 7.71744 22.3492 7.66424 22.4333C7.56966 22.6026 7.52552 22.7953 7.53704 22.9889C7.54856 23.1824 7.61526 23.3685 7.72924 23.5254L8.00724 23.8594C8.15413 24.0327 8.36383 24.1406 8.59024 24.1594C8.70154 24.1689 8.81362 24.1561 8.91991 24.1218C9.02621 24.0874 9.12458 24.0322 9.20924 23.9593L9.22824 23.9443L12.2142 21.5744C12.3844 21.4258 12.49 21.2167 12.5087 20.9916C12.5273 20.7665 12.4576 20.5429 12.3142 20.3684L12.0402 20.0514L12.0352 20.0453C11.9565 19.9535 11.8592 19.8793 11.7498 19.8276C11.6403 19.7759 11.5212 19.7479 11.4002 19.7454C11.3018 19.7429 11.2047 19.721 11.1147 19.681C11.0246 19.641 10.9434 19.5837 10.8755 19.5122C10.8076 19.4408 10.7544 19.3568 10.7191 19.2648C10.6837 19.1729 10.6667 19.0749 10.6692 18.9764C10.6717 18.8779 10.6936 18.7808 10.7336 18.6908C10.7736 18.6008 10.8309 18.5195 10.9023 18.4516C10.9738 18.3837 11.0578 18.3306 11.1498 18.2952C11.2417 18.2598 11.3398 18.2429 11.4382 18.2454C11.7702 18.2538 12.0967 18.3321 12.3964 18.4753C12.6961 18.6185 12.9621 18.8233 13.1772 19.0764L13.4542 19.3974L13.4632 19.4084C13.8622 19.8888 14.0556 20.5071 14.0015 21.1292C13.9474 21.7513 13.6501 22.327 13.1742 22.7314L13.1572 22.7454L10.1692 25.1083C9.75014 25.4636 9.21867 25.6589 8.66924 25.6594Z"
      fill="#4ED7E7"
    />
    <path
      d="M11.6881 27.9891C11.6214 27.9891 11.5548 27.9861 11.4881 27.9801C11.1759 27.9549 10.8719 27.8679 10.5936 27.7242C10.3154 27.5805 10.0684 27.383 9.86709 27.1431L9.58309 26.8031C9.17887 26.3177 8.98232 25.6925 9.03605 25.0632C9.08977 24.4338 9.38945 23.851 9.87009 23.4411C9.94534 23.3776 10.0324 23.3294 10.1262 23.2995C10.2201 23.2696 10.3189 23.2584 10.417 23.2667C10.5152 23.275 10.6107 23.3025 10.6983 23.3477C10.7858 23.3929 10.8635 23.4549 10.9271 23.5301C10.9907 23.6054 11.0388 23.6924 11.0687 23.7862C11.0986 23.8801 11.1098 23.9789 11.1015 24.0771C11.0932 24.1752 11.0657 24.2708 11.0205 24.3583C10.9753 24.4458 10.9133 24.5236 10.8381 24.5871C10.749 24.6628 10.6758 24.7554 10.6227 24.8596C10.5697 24.9638 10.5378 25.0775 10.5291 25.1941C10.5183 25.3089 10.5304 25.4247 10.5648 25.5347C10.5991 25.6447 10.655 25.7469 10.7291 25.8351L11.0131 26.1751C11.0878 26.2635 11.1793 26.3362 11.2823 26.3889C11.3853 26.4416 11.4977 26.4733 11.6131 26.4821C11.7269 26.4924 11.8416 26.4801 11.9506 26.4457C12.0596 26.4114 12.1607 26.3558 12.2481 26.2821L12.2571 26.2751L15.6041 23.5291C15.7815 23.3778 15.8916 23.1622 15.9104 22.9298C15.9291 22.6974 15.8549 22.467 15.7041 22.2891L15.4251 21.9531C15.3539 21.8717 15.2685 21.8039 15.1731 21.7531C15.0011 21.6569 14.8739 21.4968 14.819 21.3075C14.7641 21.1182 14.786 20.9149 14.8798 20.7416C14.9736 20.5683 15.1319 20.4388 15.3204 20.3813C15.5089 20.3238 15.7125 20.3427 15.8871 20.4341C16.147 20.5746 16.3786 20.7621 16.5701 20.9871L16.5761 20.9941L16.8571 21.3321C17.2616 21.815 17.4584 22.4383 17.4046 23.0658C17.3509 23.6934 17.0508 24.2741 16.5701 24.6811L16.5611 24.6881L13.2041 27.4301C12.7807 27.7898 12.2436 27.9878 11.6881 27.9891Z"
      fill="#4ED7E7"
    />
    <path
      d="M15.3312 29.5668C15.2645 29.5668 15.1959 29.5638 15.1252 29.5578C14.8246 29.532 14.532 29.4468 14.2646 29.3071C13.9971 29.1673 13.7601 28.9759 13.5672 28.7438L13.3012 28.4338V28.4278C13.1066 28.1972 12.9599 27.9301 12.8696 27.6422C12.7793 27.3543 12.7472 27.0513 12.7752 26.7508C12.8008 26.4465 12.8868 26.1503 13.0281 25.8795C13.1694 25.6088 13.3632 25.3689 13.5982 25.1738C13.6735 25.108 13.7612 25.0578 13.8561 25.0264C13.951 24.9949 14.0513 24.9828 14.151 24.9906C14.2507 24.9985 14.3478 25.0262 14.4366 25.0721C14.5254 25.1181 14.6042 25.1813 14.6682 25.2582C14.7322 25.335 14.7802 25.4238 14.8094 25.5195C14.8386 25.6151 14.8483 25.7157 14.8381 25.8152C14.8278 25.9146 14.7978 26.0111 14.7497 26.0988C14.7017 26.1865 14.6366 26.2637 14.5582 26.3258C14.4755 26.3948 14.4073 26.4796 14.3577 26.5752C14.308 26.6709 14.278 26.7754 14.2692 26.8828C14.2591 26.9859 14.2696 27.09 14.3 27.189C14.3303 27.2881 14.3801 27.3801 14.4462 27.4598L14.7132 27.7698L14.7212 27.7798C14.788 27.8608 14.8702 27.9275 14.9631 27.9763C15.056 28.0251 15.1577 28.0548 15.2622 28.0638C15.3655 28.0733 15.4696 28.0621 15.5685 28.0308C15.6674 27.9996 15.7591 27.9489 15.8382 27.8818L15.8592 27.8648L17.1412 26.8578C17.2989 26.7209 17.3969 26.5277 17.414 26.3196C17.4312 26.1114 17.3663 25.9048 17.2332 25.7438C17.1053 25.5919 17.0429 25.3955 17.0596 25.1976C17.0763 24.9997 17.1708 24.8165 17.3224 24.6882C17.474 24.56 17.6703 24.497 17.8682 24.5133C18.0661 24.5295 18.2495 24.6235 18.3782 24.7748C18.7692 25.2408 18.9597 25.8427 18.908 26.4488C18.8562 27.0549 18.5665 27.6158 18.1022 28.0088L18.0802 28.0268L16.7942 29.0368C16.3843 29.3806 15.8661 29.5683 15.3312 29.5668Z"
      fill="#4ED7E7"
    />
  </svg>
)
export default ContractTypeIcon
