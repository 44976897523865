import { Box, Flex, IconButton } from '@chakra-ui/react'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import DeleteIcon from '~/assets/icons/delete.icon'
import PlusIcon from '~/assets/icons/plus.icon'
import FormItem from '~/components/form/form-item'
import TitleSteps from '~/components/title-steps/title-steps'

import { languageLevelOptions, languageOptions } from '../register-options'

const languageFields = [
  {
    options: languageOptions,
    name: 'language',
    placeholder: 'Selecione o Idioma',
    label: 'Idioma',
  },
  {
    options: languageLevelOptions,
    name: 'written',
    placeholder: 'Selecione o nível',
    label: 'Escrita',
  },
  {
    options: languageLevelOptions,
    name: 'reading',
    placeholder: 'Selecione o nível',
    label: 'Leitura',
  },
  {
    options: languageLevelOptions,
    name: 'spoken',
    placeholder: 'Selecione o nível',
    label: 'Oratória',
  },
]

const LanguageSelectField = ({
  options,
  name,
  index,
  label,
  placeholder,
}: any) => {
  return (
    <FormItem
      inputType="select"
      options={options}
      label={label}
      placeholder={placeholder}
      name={`languages.${index}.${name}`}
    />
  )
}

export const Languages = () => {
  const { control } = useFormContext()

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'languages',
  })

  React.useEffect(() => {
    if (fields.length === 0)
      append({ language: '', reading: '', spoken: '', written: '' })
  }, [fields])

  const fieldArraySize = React.useMemo(() => fields.length - 1, [fields])

  return (
    <Box mt="l4">
      <TitleSteps text="Idiomas" as="h3" />
      {fields.map((field, index) => {
        return (
          <Flex key={field.id} gap="m2" alignItems="flex-start" mt="m3">
            {languageFields.map((languageField) => (
              <LanguageSelectField
                key={languageField.name}
                options={languageField.options}
                name={languageField.name}
                index={index}
                label={languageField.label}
                placeholder={languageField.placeholder}
              />
            ))}
            {fieldArraySize <= index && (
              <IconButton
                aria-label="add"
                colorScheme="secondary"
                variant="outline"
                mt="28px"
                onClick={() =>
                  append({ language: '', reading: '', spoken: '', written: '' })
                }
              >
                <PlusIcon width={20} height={20} />
              </IconButton>
            )}
            {fieldArraySize > index && (
              <IconButton
                aria-label="delete"
                onClick={() => remove(index)}
                colorScheme="red"
                variant="outline"
                mt="28px"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Flex>
        )
      })}
    </Box>
  )
}
