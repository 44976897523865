import * as c from '@chakra-ui/react'
import React from 'react'

import { ErrorMessage } from '~/components/form/error-message'
import { ErrorMessageInterface } from '~/model/interfaces/components/error-message.interface'

interface FormInputProps extends c.InputProps {
  name: string
  label?: string
  helperText?: string
  spacing?: c.SpaceProps & c.FormControlProps
  error?: ErrorMessageInterface
}

const TextInput = React.forwardRef<React.ElementRef<'input'>, FormInputProps>(
  ({ name, label, helperText, spacing = {}, error, ...props }, ref) => {
    return (
      <c.FormControl isInvalid={!!error} variant="floating" {...spacing}>
        <c.InputGroup display="flex" flexDir="column">
          {label && <c.FormLabel htmlFor={name}>{label}</c.FormLabel>}
          <c.Input
            id={props.id || name}
            name={name}
            placeholder=" "
            errorBorderColor="red.300"
            boxShadow="none"
            ref={ref}
            {...props}
          />
          {helperText && <c.FormHelperText>{helperText}</c.FormHelperText>}
          {error?.message && <ErrorMessage error={error} />}
        </c.InputGroup>
      </c.FormControl>
    )
  }
)

export default TextInput
