import { IconInterface } from '../../../model/interfaces/components/icon.interface'

const EnglishIcon = ({
  width = 35.165,
  height = 35.166,
  ...props
}: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 35.165 35.166"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="Caminho_11481"
      data-name="Caminho 11481"
      d="M44.954,160.842a17.5,17.5,0,0,0,10.719-3.65H34.235a17.5,17.5,0,0,0,10.719,3.65"
      transform="translate(-27.371 -125.676)"
      fill="#fff"
    />
    <path
      id="Caminho_11482"
      data-name="Caminho 11482"
      d="M9.151,125.9H37.477a17.548,17.548,0,0,0,1.983-3.45H7.169a17.568,17.568,0,0,0,1.982,3.45"
      transform="translate(-5.732 -97.896)"
      fill="#fff"
    />
    <path
      id="Caminho_11483"
      data-name="Caminho 11483"
      d="M89.827.134A17.766,17.766,0,0,0,87.7,0V.134Z"
      transform="translate(-70.115 -0.001)"
      fill="#fff"
    />
    <path
      id="Caminho_11484"
      data-name="Caminho 11484"
      d="M.341,91.147H34.825a17.641,17.641,0,0,0,.34-3.45H0a17.672,17.672,0,0,0,.341,3.45"
      transform="translate(0 -70.114)"
      fill="#fff"
    />
    <path
      id="Caminho_11485"
      data-name="Caminho 11485"
      d="M87.7,56.4h17.23a17.41,17.41,0,0,0-1.085-3.45H87.7Z"
      transform="translate(-70.115 -42.332)"
      fill="#fff"
    />
    <path
      id="Caminho_11486"
      data-name="Caminho 11486"
      d="M87.7,21.653h14.116a17.7,17.7,0,0,0-3.4-3.45H87.7Z"
      transform="translate(-70.115 -14.553)"
      fill="#fff"
    />
    <path
      id="Caminho_11487"
      data-name="Caminho 11487"
      d="M87.7.663V4.18H98.417A17.491,17.491,0,0,0,89.827.663Z"
      transform="translate(-70.115 -0.53)"
      fill="#e60012"
    />
    <path
      id="Caminho_11488"
      data-name="Caminho 11488"
      d="M87.7,38.927h16.145a17.565,17.565,0,0,0-2.03-3.516H87.7Z"
      transform="translate(-70.115 -28.311)"
      fill="#e60012"
    />
    <path
      id="Caminho_11489"
      data-name="Caminho 11489"
      d="M17.583,73.522H0c0,.051,0,.1,0,.152H35.165a17.654,17.654,0,0,0-.352-3.516H17.583Z"
      transform="translate(0 -56.092)"
      fill="#e60012"
    />
    <path
      id="Caminho_11490"
      data-name="Caminho 11490"
      d="M2.8,108.423H35.087a17.481,17.481,0,0,0,1.1-3.516H1.7a17.456,17.456,0,0,0,1.1,3.516"
      transform="translate(-1.358 -83.874)"
      fill="#e60012"
    />
    <path
      id="Caminho_11491"
      data-name="Caminho 11491"
      d="M20.5,143.169H41.938a17.683,17.683,0,0,0,3.444-3.516H17.056a17.684,17.684,0,0,0,3.444,3.516"
      transform="translate(-13.636 -111.654)"
      fill="#e60012"
    />
    <path
      id="Caminho_11492"
      data-name="Caminho 11492"
      d="M75.178.855l-.1.012h.092Z"
      transform="translate(-60.028 -0.684)"
      fill="#1d2088"
    />
    <path
      id="Caminho_11493"
      data-name="Caminho 11493"
      d="M17.6,14.067V0a17.763,17.763,0,0,0-1.863.1l.028.085h.968l-.782.57.3.922-.784-.568-.785.568.3-.922L14.355.3a17.457,17.457,0,0,0-3.792,1.165l.067.209-.2-.148A17.539,17.539,0,0,0,7.933,2.9h.374l-.783.571.3.921-.785-.568-.784.568.106-.327c-.363.3-.713.62-1.05.951H5.5L4.831,5.5l-.106.111.289.894-.661-.479A17.558,17.558,0,0,0,.839,12.256h.281l.3-.922.3.922h.969l-.783.571.3.921-.785-.568-.784.568.3-.921-.219-.16a17.579,17.579,0,0,0-.7,4.763H17.6Zm-1.352-2.733-.784-.567-.785.567.3-.921-.782-.57.968,0,.3-.921.3.921.968,0-.782.57Zm-9.509.922.3-.922.3.922h.969l-.783.571.3.921-.785-.568-.784.568.3-.921-.783-.571Zm2.622-1.843-.783-.57.968,0,.3-.921.3.921.969,0-.783.57.3.921-.784-.567-.785.567ZM10.63,6.506l-.784-.568-.785.568.3-.921-.783-.571.968,0,.3-.92.3.92.969,0-.783.571Zm2.808,7.243-.784-.568-.785.568.3-.921-.783-.571h.968l.3-.922.3.922h.968l-.782.571Zm-.484-6.322.968,0L13.14,8l.3.921-.784-.568-.785.568.3-.921-.783-.571.968,0,.3-.921Zm3.291-.921-.784-.568-.785.568.3-.921-.782-.571.968,0,.3-.92.3.92.968,0-.782.571ZM11.385,2.9l.968,0,.3-.921.3.921.968,0-.782.571.3.921-.784-.568-.785.568.3-.921ZM7.038,6.506l.3.921.969,0L7.524,8l.3.921-.785-.568-.784.568L6.551,8l-.783-.571.968,0ZM2.961,9.843l.969,0,.3-.921.3.921.968,0-.782.57.3.921-.784-.567-.784.567.3-.921Zm2.053,6.32L4.23,15.6l-.784.568.3-.921-.783-.571.969,0,.3-.921.3.921.968,0-.782.571Zm5.616,0L9.846,15.6l-.785.568.3-.921-.783-.571.968,0,.3-.921.3.921.969,0-.783.571Zm5.616,0-.784-.568-.785.568.3-.921-.782-.571.968,0,.3-.921.3.921.968,0-.782.571Z"
      transform="translate(-0.015 -0.001)"
      fill="#0b318f"
    />
    <path
      id="Caminho_11494"
      data-name="Caminho 11494"
      d="M71.846,2.072l.785-.568.784.568-.3-.922L73.9.58h-.968L72.9.494c-.191.02-.38.047-.57.072l0,.012h-.093C72,.613,71.76.654,71.524.7l.62.452Z"
      transform="translate(-57.184 -0.395)"
      fill="#fff"
    />
    <path
      id="Caminho_11495"
      data-name="Caminho 11495"
      d="M52.065,7.324l-.136.062.2.148Z"
      transform="translate(-41.518 -5.856)"
      fill="#fff"
    />
    <path
      id="Caminho_11496"
      data-name="Caminho 11496"
      d="M72.284,21.328l-.3-.92-.3.92-.968,0,.782.571-.3.921.785-.568.784.568-.3-.921.782-.571Z"
      transform="translate(-56.536 -16.316)"
      fill="#fff"
    />
    <path
      id="Caminho_11497"
      data-name="Caminho 11497"
      d="M24.5,25c-.163.159-.321.322-.478.487L24.688,25Z"
      transform="translate(-19.204 -19.991)"
      fill="#fff"
    />
    <path
      id="Caminho_11498"
      data-name="Caminho 11498"
      d="M22.007,27.988c-.127.136-.25.275-.373.415l.661.479Z"
      transform="translate(-17.297 -22.377)"
      fill="#fff"
    />
    <path
      id="Caminho_11499"
      data-name="Caminho 11499"
      d="M44.272,21.328l-.3-.92-.3.92-.968,0,.783.571-.3.921.785-.568.784.568-.3-.921.783-.571Z"
      transform="translate(-34.141 -16.316)"
      fill="#fff"
    />
    <path
      id="Caminho_11500"
      data-name="Caminho 11500"
      d="M73.252,45.411l-.968,0-.3-.921-.3.921-.968,0,.782.57-.3.921.785-.567.784.567-.3-.921Z"
      transform="translate(-56.536 -35.569)"
      fill="#fff"
    />
    <path
      id="Caminho_11501"
      data-name="Caminho 11501"
      d="M15.176,46.9l.784-.567.784.567-.3-.921.782-.57-.968,0-.3-.921-.3.921-.969,0,.783.57Z"
      transform="translate(-11.746 -35.569)"
      fill="#fff"
    />
    <path
      id="Caminho_11502"
      data-name="Caminho 11502"
      d="M44.756,46.9l-.3-.921.783-.57-.969,0-.3-.921-.3.921-.968,0,.783.57-.3.921.785-.567Z"
      transform="translate(-34.141 -35.569)"
      fill="#fff"
    />
    <path
      id="Caminho_11503"
      data-name="Caminho 11503"
      d="M72.284,69.491l-.3-.921-.3.921-.968,0,.782.571-.3.921.785-.568.784.568-.3-.921.782-.571Z"
      transform="translate(-56.536 -54.823)"
      fill="#fff"
    />
    <path
      id="Caminho_11504"
      data-name="Caminho 11504"
      d="M16.261,69.491l-.3-.921-.3.921-.969,0,.783.571-.3.921.784-.568.784.568-.3-.921.782-.571Z"
      transform="translate(-11.746 -54.823)"
      fill="#fff"
    />
    <path
      id="Caminho_11505"
      data-name="Caminho 11505"
      d="M44.272,69.491l-.3-.921-.3.921-.968,0,.783.571-.3.921.785-.568.784.568-.3-.921.783-.571Z"
      transform="translate(-34.141 -54.823)"
      fill="#fff"
    />
    <path
      id="Caminho_11506"
      data-name="Caminho 11506"
      d="M28.7,33.369l.783.571-.3.921.784-.568.785.568-.3-.921.783-.571-.969,0-.3-.921-.3.921Z"
      transform="translate(-22.944 -25.941)"
      fill="#fff"
    />
    <path
      id="Caminho_11507"
      data-name="Caminho 11507"
      d="M57.675,33.367l-.968,0,.783.571-.3.921.785-.568.784.568-.3-.921.782-.571-.968,0-.3-.921Z"
      transform="translate(-45.338 -25.941)"
      fill="#fff"
    />
    <path
      id="Caminho_11508"
      data-name="Caminho 11508"
      d="M29.182,58.942l.784-.568.785.568-.3-.921.783-.571h-.969l-.3-.922-.3.922H28.7l.783.571Z"
      transform="translate(-22.944 -45.194)"
      fill="#fff"
    />
    <path
      id="Caminho_11509"
      data-name="Caminho 11509"
      d="M3.1,58.943l.784-.568.785.568-.3-.921.783-.571H4.188l-.3-.922-.3.922H3.305c-.044.136-.082.275-.123.412l.219.16Z"
      transform="translate(-2.481 -45.195)"
      fill="#fff"
    />
    <path
      id="Caminho_11510"
      data-name="Caminho 11510"
      d="M58.277,57.449l-.3-.922-.3.922h-.968l.783.571-.3.921.785-.568.784.568-.3-.921.782-.571Z"
      transform="translate(-45.338 -45.194)"
      fill="#fff"
    />
    <path
      id="Caminho_11511"
      data-name="Caminho 11511"
      d="M31.9,15.373l.785.568-.3-.921.783-.57h-.374a17.58,17.58,0,0,0-1.574,1.166l-.106.327Z"
      transform="translate(-24.876 -11.552)"
      fill="#fff"
    />
    <path
      id="Caminho_11512"
      data-name="Caminho 11512"
      d="M57.192,12.265l.785-.568.784.568-.3-.921.782-.57-.968,0-.3-.921-.3.921-.968,0,.783.57Z"
      transform="translate(-45.338 -7.876)"
      fill="#fff"
    />
  </svg>
)
export default EnglishIcon
