import { ChatApiInterface, ChatService } from '@recrutae/components'
import React from 'react'

import { HttpService } from '~/services/http.service'

export const useChatsByJobs = (jobId: string) => {
  const [loading, setLoading] = React.useState(false)
  const [chats, setChats] = React.useState<ChatApiInterface[]>([])

  const fetch = React.useCallback(async () => {
    setLoading(true)

    try {
      const http = new HttpService()
      const service = new ChatService(http)
      const { data } = await service.fetchChatByJob({ jobId })
      React.startTransition(() => {
        setChats(data.data)
        setLoading(false)
      })
    } catch (e) {
      React.startTransition(() => {
        setChats([])
        setLoading(false)
      })
    }
  }, [jobId])

  React.useEffect(() => {
    if (jobId) fetch().then()
  }, [fetch])

  return { chats, loading, reFetch: fetch }
}
