import { Box, Button, Flex, Heading, useToast } from '@chakra-ui/react'
import { CandidateJourneyService } from '@recrutae/components'
import { motion } from 'framer-motion'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormItem from '~/components/form/form-item'
import { JobJourneyEventApiType } from '~/model/interfaces/api/common/job-journey-event.interface'
import { CandidateJourneyByJobApiInterface } from '~/model/interfaces/api/job-journey-event.interface'
import { HttpService } from '~/services/http.service'
import {
  candidateStatusFormatter,
  CandidateStatusFormatterType,
} from '~/utils/from-to/candidate-status.formatter'

interface CandidateEventFormInterface {
  candidate?: CandidateJourneyByJobApiInterface
  jobId: string
  setStepVisible: React.Dispatch<React.SetStateAction<number>>
}

interface FormInterface {
  status: JobJourneyEventApiType
  message: string
  date: string
}

const CandidateEventForm = ({
  candidate,
  jobId,
  setStepVisible,
}: CandidateEventFormInterface) => {
  const form = useForm<FormInterface>({
    defaultValues: { date: '', message: '', status: '' as any },
  })

  const toast = useToast()

  const onSubmit = React.useCallback(async (values: FormInterface) => {
    const http = new HttpService()
    const service = new CandidateJourneyService(http)
    try {
      await service.createJourneyEvent(jobId, candidate?.id as string, {
        eventType: values.status,
        message: values.message,
        date: new Date(),
      })

      toast({
        status: 'success',
        title: 'Evento criado com sucesso',
        position: 'top-right',
      })

      setStepVisible(0)
    } catch (e) {
      toast({
        status: 'error',
        title: 'Erro ao criar evento',
        description: 'Houve um erro ao tentar criar o evento',
        position: 'top-right',
      })
    }
  }, [])

  return (
    <motion.div
      initial={{ x: 999, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 999, opacity: 0 }}
    >
      <Heading>Registrar iteração</Heading>
      <FormProvider {...form}>
        <Box mt="m2" as="form" onSubmit={form.handleSubmit(onSubmit)}>
          <Flex gap="m2">
            <FormItem
              name="status"
              inputType="select"
              label="Status"
              options={Object.keys(candidateStatusFormatter).map(
                (key: any) => ({
                  label:
                    candidateStatusFormatter?.[
                      key as CandidateStatusFormatterType
                    ],
                  value: key,
                })
              )}
            />
            <FormItem name="message" inputType="text" label="Mensagem" />
          </Flex>
          <Flex justifyContent="flex-end" mt="m4">
            <Button colorScheme="red" mr="m3" onClick={() => setStepVisible(0)}>
              Cancelar
            </Button>
            <Button colorScheme="secondary" type="submit">
              Registrar evento
            </Button>
          </Flex>
        </Box>
      </FormProvider>
    </motion.div>
  )
}

export default CandidateEventForm
