import { IconInterface } from '../../model/interfaces/components/icon.interface'

const EditIcon = ({
  width = 32,
  height = 32,
  color = '#36174d',
  ...props
}: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g data-name="Grupo 9396" transform="translate(.203 .204)">
      <g
        fill={color}
        clipPath="url(#clip-path)"
        data-name="Grupo 9396"
        transform="translate(-.276 -.276)"
      >
        <path
          d="M241.061 142.056l-1.054 5.136a.444.444 0 00.518.518l5.136-1.054.225-.121 11.046-11.047a.222.222 0 000-.313l-4.39-4.39a.222.222 0 00-.314 0l-11.046 11.047-.121.225"
          data-name="Caminho 12213"
          transform="translate(-230.263 -125.31)"
        ></path>
        <path
          d="M27.875 95.847h-1.9a.633.633 0 00-.634.633v8.87H3.168V83.177h7.286a.634.634 0 00.634-.634v-1.9a.634.634 0 00-.634-.633h-8.87A1.584 1.584 0 000 81.593v25.341a1.584 1.584 0 001.584 1.584h25.341a1.584 1.584 0 001.584-1.584V96.48a.633.633 0 00-.634-.633"
          data-name="Caminho 12214"
          transform="translate(.233 -76.607)"
        ></path>
        <path
          d="M619.3 3.461L616.165.325a1.108 1.108 0 00-1.568 0l-2.315 2.315a.317.317 0 000 .448l4.256 4.256a.317.317 0 00.448 0l2.314-2.315a1.109 1.109 0 000-1.568"
          data-name="Caminho 12215"
          transform="translate(-587.716 .234)"
        ></path>
      </g>
    </g>
  </svg>
)
export default EditIcon
