import { IconInterface } from '../../model/interfaces/components/icon.interface'

const EmailIcon = ({ width = 32, height = 18, ...props }: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.14697 6.13652V2.23652C7.14697 2.0658 7.1806 1.89676 7.24593 1.73904C7.31126 1.58131 7.40702 1.438 7.52773 1.31728C7.64845 1.19657 7.79176 1.10081 7.94948 1.03548C8.10721 0.970149 8.27625 0.936523 8.44697 0.936523H29.239C29.5838 0.936523 29.9144 1.07349 30.1582 1.31728C30.402 1.56108 30.539 1.89174 30.539 2.23652V15.8805C30.539 16.2253 30.402 16.556 30.1582 16.7998C29.9144 17.0436 29.5838 17.1805 29.239 17.1805H8.44697C8.27625 17.1805 8.10721 17.1469 7.94948 17.0816C7.79176 17.0162 7.64845 16.9205 7.52773 16.7998C7.40702 16.679 7.31126 16.5357 7.24593 16.378C7.1806 16.2203 7.14697 16.0512 7.14697 15.8805V13.2805"
      stroke="#4ED7E7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M28.59 2.88574L21.597 9.87474C20.8657 10.6057 19.874 11.0163 18.84 11.0163C17.806 11.0163 16.8143 10.6057 16.083 9.87474L9.09698 2.88574"
      stroke="#4ED7E7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M9.34497 15.0817L14.017 10.4097"
      stroke="#4ED7E7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M28.813 15.0817L23.362 10.4097"
      stroke="#4ED7E7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M2.336 6.5166H9.344"
      stroke="#4ED7E7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M0 8.85254H7.008"
      stroke="#4ED7E7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M3.11499 11.1885H10.123"
      stroke="#4ED7E7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
)
export default EmailIcon
