import { Text } from '@chakra-ui/react'
import React from 'react'

interface ExpectedSalaryInterface {
  lastExpectedSalary: number
  maxRangeSalary: number
}

export const ExpectedSalary = ({
  lastExpectedSalary,
  maxRangeSalary,
}: ExpectedSalaryInterface) => {
  const iconSalary = React.useMemo(() => {
    if (lastExpectedSalary === 0) return 'primaryDark.300'
    if (lastExpectedSalary < maxRangeSalary) return 'primaryLight.900'
    return 'red'
  }, [lastExpectedSalary])

  return (
    <Text fontSize="t-sm" color={iconSalary}>
      {lastExpectedSalary !== 0 ? `R$ ${lastExpectedSalary},00` : 'N/A'}
    </Text>
  )
}
