export const formatInitialsByName = (name: string) => {
  const nameSplit = name?.split(' ')

  return Array.isArray(nameSplit)
    ? nameSplit.length >= 2
      ? nameSplit[0].slice(0, 1).toUpperCase() +
        nameSplit[nameSplit.length - 1].slice(0, 1).toUpperCase()
      : nameSplit[0].slice(0, 1).toUpperCase()
    : ''
}
