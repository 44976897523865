import { Button, Flex, Image } from '@chakra-ui/react'
import React from 'react'

import PhotoIcon from '~/assets/icons/photo.icon'
import { useAuth } from '~/store/auth.context'

export const UploadImage = () => {
  const { user } = useAuth()

  return (
    <Flex
      flexDir="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="30%"
      mt="40px"
      mr="40px"
      gap="20px"
    >
      <Image
        borderRadius="50px"
        border="2px"
        borderColor="secondary.500"
        objectFit="cover"
        objectPosition="center"
        minWidth="220px"
        maxWidth="220px"
        maxHeight="220px"
        minHeight="220px"
        src={
          user?.profileImage
            ? (process.env.REACT_APP_GCP_CLOUD_STORAGE as string) +
              user?.profileImage
            : './user-default.svg'
        }
        alt=""
      />
      <input type="file" id="upload-profile" hidden />
      <Button
        borderRadius="2xl"
        fontSize="sm"
        colorScheme="secondary.500"
        width="80%"
        variant="outline"
        fontWeight="bold"
        borderColor="secondary.500"
        gap="5px"
        as="label"
        htmlFor="upload-profile"
        cursor="pointer"
      >
        <PhotoIcon />
        Editar Imagem
      </Button>
    </Flex>
  )
}
