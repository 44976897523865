import { Box, Container, Flex } from '@chakra-ui/react'

import { withAuth } from '~/HOC/with-auth'
import { JobLists } from '~/screens/home/components/job-list'

import { ScheduledInterview } from './components/history-interview'
import { InitialCard } from './components/initial-card'

const HomeScreen = () => {
  return (
    <Container minW="100%" mt="40px">
      <Flex w="100%" flexWrap="nowrap">
        <Box w="70%">
          <InitialCard />
          <JobLists />
        </Box>
        <Box width="calc(30% - 80px)" display="block" ml="80px">
          <ScheduledInterview />
        </Box>
      </Flex>
    </Container>
  )
}

export default withAuth(HomeScreen)
