import { RouteObject } from 'react-router-dom'

import HomeScreen from '~/screens/home'
import RegisterJobsScreen from '~/screens/job/register'
import LoginScreen from '~/screens/login'

import ClientViewScreen from './screens/job/client-view'
import DetailRecruiterScreen from './screens/job/detail'
import NotFound from './screens/not-found'
import ProfileScreen from './screens/profile'

export const appRoutes: RouteObject[] = [
  { path: '/', element: <LoginScreen /> },
  { path: '/home', element: <HomeScreen /> },
  { path: '/profile', element: <ProfileScreen /> },
  // { path: '/search', element: <SearchPage /> },
  {
    path: 'job',
    children: [
      { path: 'new', element: <RegisterJobsScreen /> },
      { path: ':id', element: <RegisterJobsScreen /> },
      { path: ':id/detail', element: <DetailRecruiterScreen /> },
      { path: ':id/clientview', element: <ClientViewScreen /> },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]
