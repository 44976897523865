export enum JobLevelApiTypes {
  Intern = 'Intern',
  Trainee = 'Trainee',
  Junior = 'Junior',
  Pleno = 'Pleno',
  Senior = 'Senior',
  Lead = 'Lead',
  Manager = 'Manager',
}

export type JobLevelApiType = keyof typeof JobLevelApiTypes

export enum JobContractApiTypes {
  CLT = 'CLT',
  PJ = 'PJ',
}
export type JobContractApiType = keyof typeof JobContractApiTypes

export enum JobStatusApiTypes {
  Draft = 'Draft',
  Recruiting = 'Recruiting',
  Finished = 'Finished',
  Canceled = 'Canceled',
}
export type JobStatusApiType = keyof typeof JobStatusApiTypes

export enum JobWorkApiTypes {
  OnSite = 'OnSite',
  Remote = 'Remote',
  Hybrid = 'Hybrid',
}
export type JobWorkApiType = keyof typeof JobWorkApiTypes

export enum JobAdvertisementApiTypes {
  Public = 'Public',
  Private = 'Private',
}
export type JobAdvertisementApiType = keyof typeof JobAdvertisementApiTypes

export interface JobPositionApiInterface {
  title: string
  companyName: string
  companyId: string
  jobDescription: string
  level: JobLevelApiType
  startDate: string
  endDate: string
}
