import { JobApiInterface, JobService } from '@recrutae/components'
import React from 'react'

import { HttpService } from '~/services/http.service'

interface UseJobsParams {
  id: string
}

export const useJob = ({ id }: UseJobsParams) => {
  const [loading, setLoading] = React.useState(false)
  const [job, setJob] = React.useState<JobApiInterface>({} as JobApiInterface)

  const fetch = React.useCallback(async () => {
    setLoading(true)

    try {
      const http = new HttpService()
      const service = new JobService(http)
      const { data } = await service.getById(id)
      React.startTransition(() => {
        setJob(data)
        setLoading(false)
      })
    } catch (e) {
      React.startTransition(() => {
        setJob({} as JobApiInterface)
        setLoading(false)
      })
    }
  }, [id])

  React.useEffect(() => {
    if (id) fetch().then()
  }, [fetch])

  return { job, loading }
}
