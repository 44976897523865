import TextInput from '~/components/form/text.input'

export const FormProfissionalCandidateData = ({ register, errors }: any) => {
  return (
    <>
      <TextInput
        label="Perfil do Linkedin"
        name="linkedinProfile"
        {...register('linkedinProfile')}
        error={errors?.linkedinProfile}
        spacing={{ mt: '20px' }}
      />
    </>
  )
}
