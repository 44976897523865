import { Flex, Text } from '@chakra-ui/react'

import { ChatApiInterface } from '~/model/interfaces/api/chat.interface'

import CandidatePicture from '../../candidate-picture'

interface NavChatActiveInterface {
  chatActive?: ChatApiInterface
}

const NavChatActive = ({ chatActive }: NavChatActiveInterface) => {
  return (
    <Flex
      backgroundColor="secondary.300"
      borderRadius="0 16px 0 0"
      minWidth="100%"
      h="86px"
      justifyContent="space-between"
    >
      <Flex alignItems="center" gap="12px" p="12px">
        <CandidatePicture name={chatActive?.candidateName || ''} />
        <Text fontWeight="bold" color="white">
          {chatActive?.candidateName || 'Candidato'}
        </Text>
      </Flex>
      {/* <OptionsButton/> */}
    </Flex>
  )
}

export default NavChatActive
