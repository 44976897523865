import { Box, Button, Flex, Link, Text } from '@chakra-ui/react'
import {
  CandidateJourneyByJobApiInterface,
  DataTable,
} from '@recrutae/components'
import { format } from 'date-fns'
import React, { SetStateAction } from 'react'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

import { DataTableSortField } from '~/components/data-table/interfaces'
import { PaginationApiInterface } from '~/model/interfaces/api/common/pagination.interface'
import {
  candidateStatusFormatter,
  CandidateStatusFormatterType,
} from '~/utils/from-to/candidate-status.formatter'

import { ExpectedSalary } from './expected-salary-candidate'
import { InterviewDate } from './interview-date'
import { ScoreProgress } from './score-progress'

interface CandidatesJourneyListTabInterface {
  jobJourneys: PaginationApiInterface<CandidateJourneyByJobApiInterface>
  loading: boolean
  jobScore: number
  typeformId: string
  maxPaymentRange: number
  reFetch: () => Promise<void>
  setSelectedCandidate: React.Dispatch<
    SetStateAction<CandidateJourneyByJobApiInterface | undefined>
  >
  uncontrolledPagination: {
    setPerPage: React.Dispatch<SetStateAction<number>>
    setPage: React.Dispatch<SetStateAction<number>>
    page: number
    perPage: number
  }
  uncontrolledSort: {
    sort: DataTableSortField[]
    setSort: React.Dispatch<SetStateAction<DataTableSortField[]>>
  }
}

const CandidatesJourneyListTab = ({
  jobJourneys,
  jobScore,
  loading,
  typeformId,
  maxPaymentRange,
  uncontrolledPagination,
  uncontrolledSort,
  setSelectedCandidate,
}: CandidatesJourneyListTabInterface) => {
  return (
    <>
      <DataTable<CandidateJourneyByJobApiInterface>
        pagination={{ total: jobJourneys.totalItems }}
        uncontrolledPagination={{
          setUncontrolledPage: uncontrolledPagination.setPage,
          page: uncontrolledPagination.page,
          perPage: uncontrolledPagination.perPage,
          setUncontrolledPerPage: uncontrolledPagination.setPerPage,
        }}
        uncontrolledSort={uncontrolledSort}
        columns={[
          {
            headerLabel: 'Candidato',
            selector: ({ name }) => (
              <Flex alignItems="center">
                <Box ml="m2">
                  <Text>{name}</Text>
                </Box>
              </Flex>
            ),
          },
          {
            headerLabel: 'Contato',
            selector: ({ phone, linkedinProfile }) => (
              <>
                {phone && (
                  <Button
                    variant="link"
                    colorScheme="secondary"
                    as={Link}
                    fontSize="t-sm"
                    href={`https://wa.me/${phone}`}
                    isExternal={true}
                  >
                    {formatPhoneNumberIntl(`+${phone.toString()}`)}
                  </Button>
                )}
                <Button
                  colorScheme="secondary"
                  disabled={linkedinProfile === null}
                  variant="link"
                  size="sm"
                  mt="m1"
                  onClick={() => window.open(linkedinProfile)}
                >
                  Linkedin
                </Button>
              </>
            ),
          },
          {
            headerLabel: 'Pretensão salarial',
            isSortable: true,
            selector: ({ lastExpectedSalary }) => (
              <ExpectedSalary
                lastExpectedSalary={lastExpectedSalary}
                maxRangeSalary={maxPaymentRange}
              />
            ),
          },
          {
            headerLabel: 'Etapa',
            selector: ({ status }) => (
              <Text fontSize="t-sm">
                {
                  candidateStatusFormatter?.[
                    status as CandidateStatusFormatterType
                  ]
                }
              </Text>
            ),
          },
          {
            headerLabel: 'Data da inscrição',
            isSortable: true,
            selector: ({ applicationDate }) => (
              <Text fontSize="t-sm">
                {format(new Date(applicationDate), 'dd/MM/yyyy')}
              </Text>
            ),
          },
          {
            headerLabel: 'Data da entrevista',
            isSortable: true,
            selector: (journey) => <InterviewDate journey={journey} />,
          },
          {
            headerLabel: 'Score',
            isSortable: true,
            selector: ({ score }) => (
              <ScoreProgress
                score={score}
                jobScore={jobScore}
                typeformId={typeformId}
              />
            ),
          },
          {
            headerLabel: 'Ações',
            selector: (candidate) => (
              <Flex justifyContent="center">
                <Button
                  colorScheme="secondary"
                  onClick={() => setSelectedCandidate(candidate)}
                >
                  Detalhes
                </Button>
              </Flex>
            ),
          },
        ]}
        data={jobJourneys.data}
        isLoadingData={loading}
      />
    </>
  )
}

export default CandidatesJourneyListTab
