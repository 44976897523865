import { useTheme } from '@emotion/react'
import { Bar, BarChart as Chart, Tooltip, XAxis, YAxis } from 'recharts'

interface BarChartInterface {
  data: Array<{ label: string; total: number }>
}

const BarChart = ({ data }: BarChartInterface) => {
  const { colors } = useTheme()

  return (
    <Chart width={900} height={250} data={data}>
      <XAxis dataKey="label" stroke={colors.black['500']} />
      <YAxis stroke={colors.black['500']} />
      <Tooltip contentStyle={{ color: colors.black['500'] }} />
      <Bar dataKey="total" fill={colors.secondary['600']} />
    </Chart>
  )
}

export default BarChart
