import { Box, Flex, Text } from '@chakra-ui/react'

export const CTASelectConversation = () => {
  return (
    <Box
      as={Flex}
      alignItems="flex-end"
      justifyContent="center"
      m="8px"
      w="95%"
      h="90%"
    >
      <Flex
        p="24px"
        h="100px"
        w="460px"
        borderRadius="16px"
        backgroundColor="white"
        gap="12px"
      >
        <Text textAlign="center">
          <b>Selecione uma conversa</b> para ver todo o histórico de interação
          do <i>Recrutabot</i> com o candidato.
        </Text>
      </Flex>
    </Box>
  )
}
