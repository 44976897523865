import { Container, Divider, Flex, Link, Text } from '@chakra-ui/react'
import React from 'react'
import { useLocation } from 'react-router-dom'

import EmailIcon from '~/assets/icons/email.icon'
import PhoneIcon from '~/assets/icons/phone.icon'
import { appRoutes } from '~/utils/routes/app-routes'

import LogoCompanyImg from '../../assets/img/logo-company.img'

export const Footer = () => {
  const { pathname } = useLocation()

  if (
    pathname === '/' ||
    pathname.includes('forgot') ||
    pathname.includes('unsubscribe') ||
    pathname.includes('signup')
  )
    return <></>

  return (
    <Container>
      <Flex
        flexDir="column"
        mt={['60px', '80px']}
        mb={['40px', '80px']}
        alignItems="center"
      >
        <Divider
          mb={['40px', '40px']}
          border="1px solid"
          borderBottomColor="primaryDark.500"
        />
        <LogoCompanyImg />
        <Text color="primaryDark.500" mt={['40px']} alignContent="center">
          Rua Castilho 392, Brooklin
        </Text>
        <Text color="primaryDark.500" pb="40px" alignContent="center">
          São Paulo, SP - Brasil
        </Text>
        <Flex
          gap={['20px', '60px']}
          flexWrap={['wrap', 'nowrap']}
          flexDir="row"
          justifyContent="center"
          alignContent="center"
          mb="50px"
          color="primaryDark.500"
        >
          <Link href="https://www.recrutae.com.br" isExternal={true}>
            Institucional
          </Link>
          <Link isExternal={true} href={appRoutes.policyPrivacy.url()}>
            Termos e serviços
          </Link>
          <Link isExternal={true} href={appRoutes.policyPrivacy.url()}>
            Política e Privacidade
          </Link>
          <Link isExternal={true} href="https://www.recrutae.com.br">
            Sobre nós
          </Link>
        </Flex>
        <Flex
          flexDir={['column-reverse', 'row']}
          alignItems="center"
          gap={['15px', '60px']}
          fontWeight="bold"
          color="primaryDark.500"
          alignContent="center"
        >
          <Flex gap="2px" flexDir="row" alignItems="center">
            <EmailIcon />
            <Link
              isExternal={true}
              href="mailto:contato@recrutae.com.br"
              fontSize="smaller"
              color="primaryDark.300"
            >
              contato@recrutae.com.br
            </Link>
          </Flex>

          <Flex gap="2px" flexDir="row" alignItems="center">
            <PhoneIcon />
            <Link isExternal={true} href="https://wa.me/5511916686422">
              <Text fontSize="smaller">+55 11 91668-6422</Text>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  )
}
