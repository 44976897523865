export const candidateStatusFormatter = {
  None: '',
  Approached: 'Abordado',
  Applied: 'Aplicou para vaga',
  TestStarted: 'Iniciou o teste',
  TestFinish: 'Finalizou o teste',
  TestApproved: 'Aprovado no teste',
  TestDisapproved: 'Reprovado no teste',
  InterviewScheduled: 'Entrevista agendada',
  InterviewCancelled: 'Entrevista cancelada',
  InterviewRescheduled: 'Entrevista reagendada',
  MeetingJoined: 'Entrou na entrevista',
  Placement: 'Contratado',
  Displacement: 'Reprovado na entrevista',
  CandidateInteraction: 'Contato com o candidato',
}

export type CandidateStatusFormatterType = keyof typeof candidateStatusFormatter
