import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Text,
  useToast,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { useForm } from 'react-hook-form'

import Header from '~/components/header'
import { withAuth } from '~/HOC/with-auth'
import ProfileSchema from '~/model/schemas/auth/profile.schema '

import { FormPersonalInfoCandidateData } from './components/form-personal-data-candidate'
import { ProgressBar } from './components/progress-bar'
import { UploadImage } from './components/upload-image'

interface ProfileFormInterface {
  name: string
  document: string
  linkedinProfile: string
  phone: string
  email: string
}

const ProfileScreen = () => {
  const toast = useToast()

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<ProfileFormInterface>({
    defaultValues: {
      //   name: user.name || '',
      //   document: user.cpf || '',
      //   linkedinProfile: user.linkedinProfile || '',
      //   phone: user.phone || '',
      //   email: user.email || '',
    },
    resolver: zodResolver(ProfileSchema),
  })

  const onSubmit = () => {
    toast({
      title: 'Erro ao atualizar perfil',
      description:
        'Não foi possível concluir sua solicitação, tente novamente mais tarde',
      status: 'error',
      position: 'top-right',
      duration: 5000,
      isClosable: true,
    })
  }

  return (
    <Container>
      <Flex minHeight="100vh" overflow="hidden">
        <Box w="100%" as="form" onSubmit={handleSubmit(onSubmit)}>
          <Header
            title="Editar meu perfil"
            description="Mantenha sempre seus dados atualizados."
            descriptionOg=""
            imageOg=""
            titleOg=""
          />
          <Flex flexDir="column" mt="60px">
            <Text fontWeight="bold" fontSize="3xl">
              Meu Perfil
            </Text>
            <Text fontSize="lg" mt="20px">
              Mantenha sempre seus <b>dados atualizados.</b>
            </Text>
            <Divider
              mt="40px"
              mb="60px"
              variant="solid"
              borderBottomWidth="2px"
              borderBottomColor="primaryDark.500"
            />
            <ProgressBar percentage={10} />
            <Flex gap="60px">
              <Flex flexDir="column" width={['100%']}>
                <Text fontWeight="bold" fontSize="md" mt="20px">
                  Dados de Acesso
                </Text>
                <Flex>
                  <UploadImage />
                  <FormPersonalInfoCandidateData
                    register={register}
                    errors={errors}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex width="100%" mt="60px" justifyContent="center" gap="60px">
              <Button
                borderRadius="2xl"
                size="lg"
                colorScheme="secondary"
                borderColor="secondary.500"
                variant="outline"
                width="20%"
              >
                Voltar
              </Button>
              <Button
                borderRadius="2xl"
                size="lg"
                colorScheme="secondary"
                width="20%"
                type="submit"
                isLoading={isSubmitting}
              >
                Salvar
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Container>
  )
}

export default withAuth(ProfileScreen)
