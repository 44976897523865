import { FormErrorMessage } from '@chakra-ui/react'

import { ErrorMessageInterface } from '~/model/interfaces/components/error-message.interface'

export const ErrorMessage = ({ error }: { error: ErrorMessageInterface }) => {
  return (
    <FormErrorMessage fontSize="12px" color="red.600">
      {error?.message}
    </FormErrorMessage>
  )
}
