import { IconInterface } from '../../model/interfaces/components/icon.interface'

const WorkTypeIcon = ({
  width = 30,
  height = 30,
  color = '#4ED7E7',
  ...props
}: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.25722 17.1585C7.05831 17.1585 6.86754 17.0795 6.72689 16.9388C6.58624 16.7982 6.50722 16.6074 6.50722 16.4085V12.5755C6.50718 12.189 6.44782 11.8049 6.33122 11.4365L4.67622 6.19548C4.53837 5.76008 4.50127 5.29905 4.56772 4.84721C4.63416 4.39537 4.80242 3.96455 5.05979 3.58727C5.31715 3.20999 5.65688 2.89613 6.05332 2.6694C6.44976 2.44267 6.89254 2.309 7.34822 2.27848C9.47922 2.13548 11.5832 2.06348 13.6012 2.06348C13.8001 2.06348 13.9909 2.14249 14.1316 2.28315C14.2722 2.4238 14.3512 2.61456 14.3512 2.81348C14.3512 3.01239 14.2722 3.20315 14.1316 3.34381C13.9909 3.48446 13.8001 3.56348 13.6012 3.56348C11.6162 3.56348 9.54622 3.63448 7.44822 3.77448C7.21927 3.78993 6.99682 3.85722 6.79769 3.97127C6.59857 4.08531 6.42797 4.24313 6.2988 4.4328C6.16962 4.62247 6.08525 4.83901 6.05207 5.06608C6.01888 5.29314 6.03774 5.52477 6.10722 5.74348L7.76222 10.9845C7.92482 11.4991 8.00745 12.0357 8.00722 12.5755V16.4085C8.00722 16.6074 7.9282 16.7982 7.78755 16.9388C7.6469 17.0795 7.45613 17.1585 7.25722 17.1585Z"
      fill={color}
    />
    <path
      d="M7.2572 16.2509H5.4452C4.64555 16.2501 3.87888 15.932 3.31344 15.3666C2.748 14.8012 2.42999 14.0345 2.4292 13.2348V11.4229C2.4292 11.2239 2.50822 11.0332 2.64887 10.8925C2.78952 10.7519 2.98029 10.6729 3.1792 10.6729C3.37811 10.6729 3.56888 10.7519 3.70953 10.8925C3.85018 11.0332 3.9292 11.2239 3.9292 11.4229V13.2359C3.92973 13.6378 4.08962 14.0231 4.37381 14.3072C4.658 14.5914 5.04329 14.7513 5.4452 14.7519H7.2582C7.45711 14.7519 7.64788 14.8309 7.78853 14.9715C7.92918 15.1122 8.0082 15.3029 8.0082 15.5019C8.0082 15.7008 7.92918 15.8915 7.78853 16.0322C7.64788 16.1728 7.45711 16.2519 7.2582 16.2519L7.2572 16.2509Z"
      fill={color}
    />
    <path
      d="M4.554 12.0771H1C0.801088 12.0771 0.610322 11.9981 0.46967 11.8575C0.329018 11.7168 0.25 11.5261 0.25 11.3271C0.25 11.1282 0.329018 10.9375 0.46967 10.7968C0.610322 10.6562 0.801088 10.5771 1 10.5771H4.554C4.75222 10.5782 4.94196 10.6577 5.08175 10.7982C5.22153 10.9388 5.3 11.1289 5.3 11.3271C5.3 11.5254 5.22153 11.7155 5.08175 11.8561C4.94196 11.9966 4.75222 12.0761 4.554 12.0771Z"
      fill={color}
    />
    <path
      d="M13.6011 17.0171C15.7201 17.0054 17.8343 17.2193 19.9081 17.6551C20.3806 17.7667 20.8616 17.8389 21.3461 17.8711C21.4787 17.8795 21.6114 17.8547 21.7321 17.7991C21.9059 17.7096 22.0986 17.663 22.2941 17.6631C22.4961 17.6627 22.6949 17.7127 22.8727 17.8085C23.0504 17.9044 23.2015 18.0431 23.3121 18.2121C23.42 18.3774 23.485 18.5671 23.5011 18.7639C23.5171 18.9607 23.4838 19.1584 23.4041 19.3391C22.1271 22.2741 19.3041 22.596 13.6041 22.596C7.90409 22.596 5.07609 22.274 3.80409 19.335C3.72484 19.155 3.69174 18.958 3.70781 18.7619C3.72387 18.5658 3.78859 18.3768 3.89609 18.2121C4.00697 18.0429 4.15834 17.904 4.33646 17.8082C4.51458 17.7123 4.71381 17.6624 4.91609 17.6631C5.10283 17.663 5.28714 17.7054 5.4551 17.7871C5.60414 17.8501 5.76545 17.8788 5.9271 17.8711C6.47796 17.8372 7.02512 17.7583 7.56309 17.6351C9.54921 17.2223 11.5726 17.0152 13.6011 17.0171ZM21.3461 19.3711C20.7638 19.3429 20.1854 19.2612 19.6181 19.1271C17.6394 18.7109 15.622 18.5064 13.6001 18.5171C11.6639 18.5158 9.73273 18.7138 7.8371 19.1081C7.2075 19.2522 6.56629 19.3399 5.9211 19.3701C5.78065 19.3704 5.64033 19.3614 5.50109 19.3431C6.44009 20.7331 8.3431 21.0951 13.6011 21.0951C18.8591 21.0951 20.7571 20.735 21.7011 19.35C21.5832 19.3631 21.4647 19.3688 21.3461 19.3671V19.3711Z"
      fill={color}
    />
    <path
      d="M13.438 29.8464C13.2391 29.8464 13.0483 29.7674 12.9077 29.6267C12.767 29.4861 12.688 29.2953 12.688 29.0964V24.3584C12.688 24.1595 12.767 23.9687 12.9077 23.8281C13.0483 23.6874 13.2391 23.6084 13.438 23.6084C13.6369 23.6084 13.8277 23.6874 13.9683 23.8281C14.109 23.9687 14.188 24.1595 14.188 24.3584V29.0964C14.188 29.2953 14.109 29.4861 13.9683 29.6267C13.8277 29.7674 13.6369 29.8464 13.438 29.8464Z"
      fill={color}
    />
    <path
      d="M4.53805 29.8462C4.4102 29.8462 4.28449 29.8134 4.17286 29.751C4.06124 29.6887 3.9674 29.5988 3.90027 29.49C3.83314 29.3812 3.79494 29.2571 3.7893 29.1293C3.78366 29.0016 3.81077 28.8745 3.86805 28.7602L4.32105 27.8542C4.3745 27.7475 4.45265 27.6551 4.54901 27.5847C4.64536 27.5142 4.75714 27.4678 4.87505 27.4492L13.485 26.0902C13.6816 26.0592 13.8824 26.1075 14.0433 26.2245C14.2042 26.3416 14.312 26.5177 14.3431 26.7142C14.3741 26.9108 14.3258 27.1116 14.2087 27.2725C14.0917 27.4334 13.9156 27.5412 13.719 27.5722L5.49005 28.8723L5.20905 29.4332C5.14653 29.5575 5.05065 29.662 4.93215 29.735C4.81365 29.8079 4.6772 29.8464 4.53805 29.8462Z"
      fill={color}
    />
    <path
      d="M22.665 29.8463C22.5257 29.8463 22.389 29.8074 22.2705 29.7341C22.152 29.6608 22.0563 29.556 21.994 29.4313L21.713 28.8703L13.484 27.5703C13.2875 27.5393 13.1113 27.4314 12.9943 27.2705C12.8773 27.1096 12.829 26.9088 12.86 26.7123C12.891 26.5158 12.9989 26.3396 13.1598 26.2226C13.3207 26.1056 13.5215 26.0573 13.718 26.0883L22.328 27.4473C22.4459 27.4658 22.5577 27.5123 22.654 27.5827C22.7504 27.6532 22.8286 27.7456 22.882 27.8523L23.335 28.7583C23.3923 28.8726 23.4194 28.9996 23.4138 29.1274C23.4081 29.2551 23.3699 29.3793 23.3028 29.4881C23.2357 29.5969 23.1418 29.6867 23.0302 29.7491C22.9186 29.8114 22.7929 29.8442 22.665 29.8443V29.8463Z"
      fill={color}
    />
    <path
      d="M14.961 25.3142H12.242C11.9237 25.3142 11.6185 25.1878 11.3935 24.9627C11.1684 24.7377 11.042 24.4325 11.042 24.1142V23.6582C11.042 23.4593 11.121 23.2685 11.2617 23.1279C11.4023 22.9872 11.5931 22.9082 11.792 22.9082C11.9909 22.9082 12.1817 22.9872 12.3223 23.1279C12.463 23.2685 12.542 23.4593 12.542 23.6582V23.8142H14.667V23.6582C14.667 23.4593 14.746 23.2685 14.8867 23.1279C15.0273 22.9872 15.2181 22.9082 15.417 22.9082C15.6159 22.9082 15.8067 22.9872 15.9473 23.1279C16.088 23.2685 16.167 23.4593 16.167 23.6582V24.1112C16.1674 24.2696 16.1364 24.4264 16.0759 24.5727C16.0154 24.7191 15.9266 24.852 15.8145 24.9638C15.7024 25.0756 15.5692 25.1642 15.4228 25.2243C15.2763 25.2844 15.1193 25.315 14.961 25.3142Z"
      fill={color}
    />
    <path
      d="M14.7859 15.9722C14.6659 15.9721 14.5477 15.9433 14.4412 15.8882C14.3346 15.833 14.2429 15.7531 14.1736 15.6551C14.1043 15.5572 14.0596 15.444 14.0431 15.3252C14.0266 15.2063 14.0389 15.0853 14.0789 14.9722L15.1089 12.0722C14.1447 10.4202 13.815 8.47364 14.1815 6.5963C14.548 4.71896 15.5856 3.03931 17.1003 1.8713C18.6151 0.703275 20.5033 0.126816 22.4121 0.249656C24.321 0.372496 26.1198 1.18623 27.4723 2.53877C28.8248 3.89131 29.6386 5.69009 29.7614 7.59893C29.8843 9.50776 29.3078 11.396 28.1398 12.9108C26.9717 14.4255 25.2921 15.4631 23.4148 15.8296C21.5374 16.1961 19.5909 15.8664 17.9389 14.9022L15.0389 15.9322C14.9575 15.9599 14.8719 15.9735 14.7859 15.9722ZM18.0269 13.3222C18.1721 13.3221 18.3141 13.3641 18.4359 13.4432C19.7564 14.3013 21.3479 14.6399 22.9033 14.3939C24.4588 14.1479 25.8681 13.3346 26.8593 12.1109C27.8505 10.8872 28.3535 9.3398 28.2712 7.76715C28.1889 6.1945 27.5271 4.70804 26.4136 3.59449C25.3 2.48093 23.8136 1.81918 22.2409 1.73688C20.6683 1.65457 19.1209 2.15755 17.8972 3.14878C16.6734 4.14001 15.8601 5.54928 15.6141 7.10475C15.3681 8.66021 15.7068 10.2517 16.5649 11.5722C16.6277 11.6688 16.6675 11.7786 16.681 11.8931C16.6945 12.0075 16.6815 12.1236 16.6429 12.2322L16.0199 13.9892L17.7769 13.3662C17.8565 13.3376 17.9403 13.3227 18.0249 13.3222H18.0269Z"
      fill={color}
    />
    <path
      d="M21.7579 11.876C21.6683 11.876 21.5808 11.9025 21.5063 11.9523C21.4318 12.0021 21.3737 12.0729 21.3394 12.1556C21.3051 12.2384 21.2962 12.3295 21.3136 12.4174C21.3311 12.5052 21.3743 12.5859 21.4376 12.6493C21.501 12.7127 21.5817 12.7558 21.6696 12.7733C21.7574 12.7907 21.8485 12.7818 21.9313 12.7475C22.0141 12.7132 22.0848 12.6551 22.1346 12.5807C22.1844 12.5062 22.2109 12.4186 22.2109 12.329C22.2111 12.2695 22.1994 12.2105 22.1767 12.1555C22.154 12.1004 22.1206 12.0505 22.0786 12.0084C22.0365 11.9663 21.9865 11.9329 21.9314 11.9102C21.8764 11.8875 21.8175 11.8758 21.7579 11.876Z"
      fill={color}
    />
    <path
      d="M21.7581 13.5318C21.5208 13.5318 21.2888 13.4615 21.0914 13.3296C20.8941 13.1977 20.7403 13.0103 20.6494 12.7911C20.5586 12.5718 20.5349 12.3305 20.5812 12.0977C20.6275 11.865 20.7418 11.6511 20.9096 11.4833C21.0774 11.3155 21.2912 11.2012 21.524 11.1549C21.7568 11.1086 21.998 11.1324 22.2173 11.2232C22.4366 11.314 22.624 11.4678 22.7559 11.6651C22.8877 11.8625 22.9581 12.0945 22.9581 12.3318C22.9581 12.6501 22.8317 12.9553 22.6066 13.1804C22.3816 13.4054 22.0764 13.5318 21.7581 13.5318Z"
      fill={color}
    />
    <path
      d="M21.7579 10.8093C21.559 10.8093 21.3683 10.7303 21.2276 10.5897C21.087 10.449 21.0079 10.2582 21.0079 10.0593V8.70434C21.0079 8.50542 21.087 8.31466 21.2276 8.17401C21.3683 8.03335 21.559 7.95434 21.7579 7.95434C22.0784 7.95434 22.3916 7.85933 22.658 7.68132C22.9244 7.50331 23.132 7.2503 23.2546 6.95428C23.3772 6.65827 23.4093 6.33254 23.3468 6.01829C23.2843 5.70404 23.13 5.41538 22.9035 5.18882C22.6769 4.96226 22.3882 4.80797 22.074 4.74546C21.7597 4.68296 21.434 4.71504 21.138 4.83765C20.842 4.96026 20.589 5.16791 20.411 5.43431C20.233 5.70072 20.1379 6.01393 20.1379 6.33434C20.1379 6.53325 20.0589 6.72401 19.9183 6.86466C19.7776 7.00532 19.5869 7.08434 19.3879 7.08434C19.189 7.08434 18.9983 7.00532 18.8576 6.86466C18.717 6.72401 18.6379 6.53325 18.6379 6.33434C18.6382 5.74954 18.8029 5.17659 19.1131 4.68085C19.4233 4.1851 19.8665 3.78652 20.3923 3.53055C20.9181 3.27459 21.5053 3.17156 22.0868 3.23321C22.6684 3.29485 23.2209 3.5187 23.6813 3.87921C24.1418 4.23972 24.4917 4.72238 24.691 5.27214C24.8904 5.82191 24.9312 6.41663 24.8089 6.98849C24.6866 7.56035 24.406 8.08632 23.9991 8.5064C23.5923 8.92648 23.0756 9.22376 22.5079 9.36434V10.0643C22.5066 10.2624 22.427 10.4519 22.2865 10.5914C22.146 10.731 21.956 10.8093 21.7579 10.8093Z"
      fill={color}
    />
  </svg>
)
export default WorkTypeIcon
