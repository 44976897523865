import { Flex } from '@chakra-ui/react'

import FormItem from '~/components/form/form-item'
import TitleSteps from '~/components/title-steps/title-steps'

import { statusOptions } from '../register-options'

export const Status = () => {
  return (
    <>
      <TitleSteps text="Processo Seletivo" as="h3" mt="l4" />
      <Flex gap="m3" mt="m3">
        <FormItem
          label="Adicione código do modelo do Typeform"
          placeholder="Selecione"
          name="typeFormId"
          inputType="text"
        />
        <FormItem
          placeholder="Selecione"
          name="status"
          inputType="select"
          options={statusOptions}
          label="Status do processo"
        />
      </Flex>
    </>
  )
}
