import { Flex, Text } from '@chakra-ui/react'

import { formatInitialsByName } from './formatInitialsByName'

interface CandidatePictureInterface {
  name: string
  picture?: string
}

const CandidatePicture = ({ name, picture }: CandidatePictureInterface) => {
  if (picture) return <img src={picture} alt="" />

  return (
    <Flex
      minWidth="64px"
      maxWidth="64px"
      minHeight="64px"
      maxHeight="64px"
      background="secondary.500"
      alignItems="center"
      justifyContent="center"
      borderRadius="180px"
    >
      <Text fontWeight="bold" fontSize="t-lg" color="white">
        {formatInitialsByName(name)}
      </Text>
    </Flex>
  )
}
export default CandidatePicture
