import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'

import WorkTypeIcon from '~/assets/icons/work-type.icon'
import BarChart from '~/components/charts/bar/bar.chart'
import { JobJourneyCountByStatusAnalyticsApiInterface } from '~/model/interfaces/api/job-analytics.interface'

const TalentByStage = ({
  countByStatus,
}: {
  countByStatus: JobJourneyCountByStatusAnalyticsApiInterface
}) => {
  const chartData = React.useMemo(() => {
    return [
      { label: 'Aprovado', total: countByStatus.totalTestApproved },
      { label: 'Reprovado', total: countByStatus.totalTestDisapproved },
      { label: 'Entrevistas', total: countByStatus.totalInterviewScheduled },
      { label: 'Placements', total: countByStatus.totalPlacement },
    ]
  }, [countByStatus])

  const totalChartData = React.useMemo(() => {
    const sum =
      countByStatus.totalTestApproved +
      countByStatus.totalTestDisapproved +
      countByStatus.totalInterviewScheduled +
      countByStatus.totalPlacement

    return sum
  }, [countByStatus])

  return (
    <Box background="black.100" borderRadius="16px" padding="m4" mb="24px">
      <Heading fontSize="h-sm">Distribuição de talentos por etapa</Heading>
      <Text mt="8px" mb="m4">
        Total de candidatos: {totalChartData}
      </Text>
      {totalChartData === 0 ? (
        <Flex w="100%" alignItems="center" flexDir="column" gap="24px">
          <Text fontSize="lg"> Ainda não há dados para serem analisados.</Text>
          <WorkTypeIcon width={160} height={160} color="#201D8A" />
        </Flex>
      ) : (
        <BarChart data={chartData} />
      )}
    </Box>
  )
}

export default TalentByStage
