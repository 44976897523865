import { Box, Button, Flex } from '@chakra-ui/react'
import React from 'react'

const CandidateEventsOptions = ({
  setStepVisible,
}: {
  setStepVisible: React.Dispatch<React.SetStateAction<number>>
}) => {
  return (
    <Box mb="l1">
      <Flex justifyContent="space-between">
        <Button colorScheme="red" mt="m3" onClick={() => setStepVisible(2)}>
          Declinar candidato
        </Button>
        <Button
          colorScheme="secondary"
          ml="m3"
          mt="m3"
          onClick={() => setStepVisible(1)}
        >
          Registrar interação
        </Button>
      </Flex>
    </Box>
  )
}

export default CandidateEventsOptions
