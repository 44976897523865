export enum LanguageLevelTypes {
  Basic = 'Basic',
  Intermediate = 'Intermediate',
  Advanced = 'Advanced',
  Fluent = 'Fluent',
}
export type LanguageLeveType = keyof typeof LanguageLevelTypes

export enum LanguageTypes {
  English = 'English',
  Spanish = 'Spanish',
  French = 'French',
  German = 'German',
  Japanese = 'Japanese',
}

export type LanguageType = keyof typeof LanguageTypes

export interface LanguageRequirementsApiInterface {
  language: LanguageType
  reading: LanguageLeveType
  written: LanguageLeveType
  spoken: LanguageLeveType
}
