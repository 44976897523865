import { Button, Flex, Link, Text } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import { CandidateJourneyByJobApiInterface } from '@recrutae/components'
import { format } from 'date-fns'
import React from 'react'

import ClockIcon from '~/assets/icons/clock.icon'
import { PopoversContent } from '~/components/popover/popover-content'

interface InterviewDateInterface {
  journey: CandidateJourneyByJobApiInterface
}

export const InterviewDate = ({
  journey: { interviewDate, interviewLink, interviewReScheduleLink },
}: InterviewDateInterface) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const dateIsValid = React.useMemo(() => {
    return interviewDate !== '0001-01-01T00:00:00Z'
  }, [interviewDate])

  const { colors } = useTheme()

  return (
    <PopoversContent
      close={true}
      arrow={true}
      trigger={
        <Button
          variant="link"
          fontWeight="normal"
          onClick={() => setIsOpen(!isOpen)}
          fontSize="t-sm"
        >
          {dateIsValid
            ? format(new Date(interviewDate), `dd/MM/yyyy 'às' HH:mm`)
            : 'N/A'}
        </Button>
      }
      content={
        <>
          {dateIsValid ? (
            <>
              <Flex gap="16px" mb="18px" ml="12px">
                <ClockIcon
                  width={40}
                  height={40}
                  color={colors?.black?.['500']}
                />
                <Text fontSize="t-sm">
                  {'Entrevista agendada para '}
                  <b>
                    {format(new Date(interviewDate), `dd/MM/yyyy 'às' HH:mm`)}
                  </b>
                </Text>
              </Flex>
              <Flex gap="12px" justifyContent="center">
                <Button
                  colorScheme="secondary"
                  borderRadius="12px"
                  fontSize="t-sm"
                  as={Link}
                  isExternal={true}
                  href={interviewLink}
                  isDisabled={!!interviewLink}
                >
                  Reagendar
                </Button>
                <Button
                  colorScheme="red"
                  borderRadius="12px"
                  fontSize="t-sm"
                  as={Link}
                  isExternal={true}
                  href={interviewReScheduleLink}
                  isDisabled={!!interviewReScheduleLink}
                >
                  Cancelar
                </Button>
              </Flex>
            </>
          ) : (
            <Text color="white" fontSize="t-sm" textAlign="center">
              Não há entrevistas agendadas para este(a) candidato(a)
            </Text>
          )}
        </>
      }
    />
  )
}
