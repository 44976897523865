import { IconInterface } from '../../../model/interfaces/components/icon.interface'

const GermanyIcon = ({
  width = 35.165,
  height = 35.165,
  ...props
}: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 35.165 35.166"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#e60012"
      d="M35.167 64.324a17.546 17.546 0 00-1-5.861H1.006a17.6 17.6 0 000 11.722h33.156a17.542 17.542 0 001-5.861"
      data-name="Caminho 11513"
      transform="translate(-.001 -46.742)"
    ></path>
    <path
      fill="#231815"
      d="M21.589 0A17.587 17.587 0 005.011 11.722h33.157A17.586 17.586 0 0021.589 0"
      data-name="Caminho 11514"
      transform="translate(-4.006)"
    ></path>
    <path
      fill="#ffe200"
      d="M38.168 116.928H5.012a17.584 17.584 0 0033.156 0"
      data-name="Caminho 11515"
      transform="translate(-4.007 -93.485)"
    ></path>
  </svg>
)
export default GermanyIcon
