import { IconInterface } from '../../model/interfaces/components/icon.interface'

const PaymentIcon = ({ width = 33, height = 33, ...props }: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.90008 32.1524C5.73913 32.1524 5.58245 32.1006 5.4532 32.0047C5.32395 31.9087 5.22898 31.7738 5.18234 31.6197C5.1357 31.4657 5.13986 31.3007 5.19419 31.1492C5.24853 30.9977 5.35017 30.8677 5.48408 30.7784L6.34608 30.2044L2.24208 23.6324L1.41608 24.1824C1.33407 24.237 1.24212 24.2748 1.14547 24.2939C1.04882 24.3129 0.94937 24.3127 0.852797 24.2933C0.756224 24.2739 0.664419 24.2356 0.582624 24.1808C0.500829 24.1259 0.430646 24.0554 0.376082 23.9734C0.321518 23.8914 0.283641 23.7994 0.264614 23.7028C0.245587 23.6061 0.245783 23.5067 0.265191 23.4101C0.284598 23.3135 0.322837 23.2217 0.377724 23.1399C0.432611 23.0581 0.503071 22.988 0.585082 22.9334L2.05408 21.9564C2.13757 21.9009 2.23133 21.8627 2.32982 21.8441C2.42831 21.8255 2.52954 21.8268 2.62752 21.848C2.7255 21.8691 2.81824 21.9097 2.90026 21.9673C2.98229 22.0249 3.05193 22.0984 3.10508 22.1834L8.00508 30.0294C8.10853 30.195 8.14331 30.3944 8.10204 30.5853C8.06077 30.7761 7.94671 30.9433 7.78408 31.0514L6.31508 32.0284C6.19203 32.1098 6.04763 32.153 5.90008 32.1524Z"
      fill="#4ED7E7"
    />
    <path
      d="M12.2792 24.3077C12.0802 24.3077 11.8895 24.2287 11.7488 24.088C11.6082 23.9474 11.5292 23.7566 11.5292 23.5577C11.5292 23.3588 11.6082 23.168 11.7488 23.0274C11.8895 22.8867 12.0802 22.8077 12.2792 22.8077H18.1581C18.317 22.8077 18.4744 22.7764 18.6212 22.7156C18.768 22.6548 18.9014 22.5657 19.0138 22.4533C19.1261 22.3409 19.2152 22.2075 19.276 22.0607C19.3369 21.9139 19.3682 21.7566 19.3682 21.5977C19.3682 21.4388 19.3369 21.2814 19.276 21.1346C19.2152 20.9878 19.1261 20.8545 19.0138 20.7421C18.9014 20.6297 18.768 20.5406 18.6212 20.4798C18.4744 20.419 18.317 20.3877 18.1581 20.3877H10.5271C9.87978 20.3888 9.24425 20.5614 8.68515 20.8877L3.32715 24.0047C3.15476 24.1047 2.94971 24.1321 2.75711 24.0809C2.56451 24.0297 2.40014 23.9041 2.30015 23.7317C2.20016 23.5593 2.17275 23.3543 2.22395 23.1617C2.27515 22.9691 2.40076 22.8047 2.57315 22.7047L7.93115 19.5877C8.72076 19.1288 9.61786 18.8872 10.5311 18.8877H18.1621C18.8809 18.8877 19.5702 19.1732 20.0784 19.6814C20.5866 20.1897 20.8721 20.879 20.8721 21.5977C20.8721 22.3164 20.5866 23.0057 20.0784 23.5139C19.5702 24.0222 18.8809 24.3077 18.1621 24.3077H12.2831H12.2792Z"
      fill="#4ED7E7"
    />
    <path
      d="M6.85796 30.351C6.69219 30.3525 6.53059 30.2991 6.39841 30.199C6.26623 30.099 6.17093 29.9579 6.12739 29.798C6.08386 29.638 6.09456 29.4681 6.1578 29.3149C6.22105 29.1616 6.33328 29.0337 6.47697 28.951L8.78796 27.592C9.2048 27.3471 9.6795 27.218 10.163 27.218H18.144C18.6952 27.2184 19.2409 27.1086 19.749 26.895L28.398 22.741L28.436 22.724C28.7253 22.5965 28.9534 22.3609 29.0715 22.0675C29.1896 21.7741 29.1883 21.4462 29.068 21.1538C28.9477 20.8613 28.7178 20.6275 28.4275 20.5022C28.1371 20.3768 27.8093 20.37 27.514 20.483L19.158 24.108C18.9755 24.1876 18.7689 24.1914 18.5836 24.1186C18.3983 24.0458 18.2495 23.9025 18.17 23.72C18.0904 23.5375 18.0866 23.3309 18.1593 23.1456C18.2321 22.9603 18.3755 22.8115 18.558 22.732L26.92 19.104H26.932C27.5921 18.8408 28.3292 18.8472 28.9847 19.1219C29.6402 19.3967 30.1616 19.9177 30.4368 20.573C30.712 21.2282 30.719 21.9653 30.4563 22.6257C30.1935 23.286 29.6821 23.8169 29.032 24.104L20.384 28.259L20.352 28.274C19.6543 28.5704 18.904 28.7228 18.146 28.722H10.162C9.94608 28.722 9.73412 28.7797 9.54796 28.889L7.23696 30.248C7.12208 30.3155 6.99122 30.3511 6.85796 30.351Z"
      fill="#4ED7E7"
    />
    <path
      d="M11.0811 4.32614C10.9221 4.32615 10.7672 4.27563 10.6388 4.18186C10.5104 4.08809 10.4151 3.95594 10.3667 3.80448C10.3182 3.65301 10.3192 3.49009 10.3695 3.33922C10.4197 3.18835 10.5166 3.05736 10.6461 2.96514L12.7841 1.44514C13.4145 0.987469 14.1741 0.742303 14.9531 0.745142H19.3901C19.4195 0.745084 19.4489 0.746754 19.4781 0.750142L27.7711 1.72614H29.4431C29.642 1.72614 29.8328 1.80516 29.9734 1.94581C30.1141 2.08646 30.1931 2.27723 30.1931 2.47614C30.1931 2.67505 30.1141 2.86582 29.9734 3.00647C29.8328 3.14712 29.642 3.22614 29.4431 3.22614H27.7271C27.6977 3.2262 27.6683 3.22453 27.6391 3.22114L19.3451 2.24014H14.9521C14.4888 2.23923 14.0373 2.386 13.6631 2.65914H13.6571L11.5151 4.19014C11.3881 4.27948 11.2364 4.32702 11.0811 4.32614Z"
      fill="#4ED7E7"
    />
    <path
      d="M23.3292 12.1039C21.8053 12.0376 20.3387 11.5034 19.1292 10.5739H12.2792C11.9188 10.5808 11.5605 10.5158 11.2255 10.3826C10.8905 10.2495 10.5853 10.0509 10.328 9.79839C10.0706 9.54591 9.86616 9.24466 9.72659 8.91224C9.58703 8.57983 9.51514 8.22292 9.51514 7.86239C9.51514 7.50186 9.58703 7.14495 9.72659 6.81253C9.86616 6.48012 10.0706 6.17886 10.328 5.92639C10.5853 5.67392 10.8905 5.4753 11.2255 5.34214C11.5605 5.20898 11.9188 5.14397 12.2792 5.15089H18.6542C18.8531 5.15089 19.0439 5.22991 19.1845 5.37056C19.3252 5.51121 19.4042 5.70198 19.4042 5.90089C19.4042 6.0998 19.3252 6.29057 19.1845 6.43122C19.0439 6.57187 18.8531 6.65089 18.6542 6.65089H12.2792C12.1172 6.64624 11.9559 6.67412 11.8048 6.73291C11.6538 6.7917 11.516 6.8802 11.3998 6.99316C11.2835 7.10612 11.1911 7.24124 11.128 7.39055C11.0649 7.53985 11.0324 7.7003 11.0324 7.86239C11.0324 8.02448 11.0649 8.18492 11.128 8.33423C11.1911 8.48353 11.2835 8.61866 11.3998 8.73162C11.516 8.84458 11.6538 8.93307 11.8048 8.99186C11.9559 9.05065 12.1172 9.07854 12.2792 9.07389H19.3902C19.5696 9.07379 19.7431 9.13801 19.8792 9.25489C21.0121 10.1902 22.4605 10.6555 23.9262 10.5549C26.2802 10.1629 27.0442 9.45489 27.1352 9.36189C27.2 9.2662 27.2888 9.18922 27.3927 9.13867C27.4966 9.08812 27.612 9.06578 27.7272 9.07389H29.4432C29.6421 9.07389 29.8329 9.15291 29.9735 9.29356C30.1142 9.43421 30.1932 9.62498 30.1932 9.82389C30.1932 10.0228 30.1142 10.2136 29.9735 10.3542C29.8329 10.4949 29.6421 10.5739 29.4432 10.5739H28.0322C26.8974 11.3945 25.565 11.8987 24.1712 12.0349C23.8929 12.081 23.6113 12.1041 23.3292 12.1039Z"
      fill="#4ED7E7"
    />
    <path
      d="M31.4041 12.048H29.4421C29.2432 12.048 29.0525 11.969 28.9118 11.8283C28.7712 11.6877 28.6921 11.4969 28.6921 11.298V1C28.6921 0.801088 28.7712 0.610322 28.9118 0.46967C29.0525 0.329018 29.2432 0.25 29.4421 0.25H31.4041C31.6031 0.25 31.7938 0.329018 31.9345 0.46967C32.0751 0.610322 32.1541 0.801088 32.1541 1C32.1541 1.19891 32.0751 1.38968 31.9345 1.53033C31.7938 1.67098 31.6031 1.75 31.4041 1.75H30.1921V10.55H31.4041C31.6031 10.55 31.7938 10.629 31.9345 10.7697C32.0751 10.9103 32.1541 11.1011 32.1541 11.3C32.1541 11.4989 32.0751 11.6897 31.9345 11.8303C31.7938 11.971 31.6031 12.05 31.4041 12.05V12.048Z"
      fill="#4ED7E7"
    />
    <path
      d="M9.77909 18.2071C9.65859 18.2071 9.53988 18.178 9.43309 18.1221C6.76204 16.7046 4.46415 14.6753 2.72709 12.2001C1.94737 11.1257 1.29721 9.96301 0.790093 8.73614C0.736567 8.59326 0.727896 8.43744 0.76524 8.28949C0.802584 8.14155 0.884164 8.00851 0.999092 7.90814L9.55709 0.435144C9.65409 0.350419 9.77127 0.292099 9.89734 0.265801C10.0234 0.239504 10.1541 0.246116 10.2769 0.284999C10.3997 0.323883 10.5104 0.393728 10.5984 0.487807C10.6863 0.581886 10.7486 0.697028 10.7791 0.822144C11.2077 2.56435 12.1344 4.14402 13.4461 5.36814C13.5164 5.43748 13.5724 5.51999 13.6108 5.61096C13.6493 5.70193 13.6694 5.79959 13.6701 5.89834C13.6708 5.9971 13.652 6.09502 13.6149 6.18652C13.5777 6.27803 13.5229 6.36132 13.4536 6.43164C13.3843 6.50197 13.3017 6.55794 13.2108 6.59638C13.1198 6.63481 13.0222 6.65495 12.9234 6.65565C12.8246 6.65635 12.7267 6.63759 12.6352 6.60044C12.5437 6.56329 12.4604 6.50848 12.3901 6.43914C11.1842 5.29091 10.2531 3.88532 9.66609 2.32714L2.39509 8.67914C2.84081 9.62516 3.37131 10.5289 3.98009 11.3791C5.57592 13.6404 7.68084 15.4949 10.1251 16.7931C10.2739 16.8708 10.3924 16.9961 10.4616 17.149C10.5308 17.302 10.5467 17.4737 10.5067 17.6368C10.4667 17.7998 10.3732 17.9447 10.2411 18.0483C10.109 18.1519 9.94596 18.2082 9.77809 18.2081L9.77909 18.2071Z"
      fill="#4ED7E7"
    />
    <path
      d="M22.0872 19.546C21.9336 19.546 21.7837 19.4989 21.6578 19.4109C21.5319 19.323 21.436 19.1985 21.3831 19.0544C21.3303 18.9102 21.3229 18.7533 21.3621 18.6048C21.4012 18.4563 21.485 18.3234 21.6022 18.224L23.4172 16.688C22.7291 14.5182 21.486 12.5657 19.8112 11.024C19.7328 10.9619 19.6677 10.8847 19.6196 10.797C19.5716 10.7093 19.5415 10.6129 19.5313 10.5134C19.521 10.4139 19.5308 10.3134 19.56 10.2177C19.5891 10.1221 19.6371 10.0332 19.7012 9.95638C19.7652 9.87956 19.8439 9.81631 19.9328 9.77036C20.0216 9.72442 20.1187 9.6967 20.2184 9.68884C20.3181 9.68098 20.4184 9.69314 20.5133 9.7246C20.6082 9.75606 20.6959 9.8062 20.7712 9.87205C22.796 11.7267 24.2621 14.1104 25.0042 16.754C25.0394 16.8907 25.0354 17.0344 24.9926 17.1689C24.9498 17.3033 24.8699 17.423 24.7622 17.514L22.5702 19.369C22.4352 19.4834 22.264 19.5461 22.0872 19.546Z"
      fill="#4ED7E7"
    />
    <path
      d="M18.7659 18.4236C18.6113 18.4235 18.4605 18.3756 18.3342 18.2865C18.2078 18.1974 18.1121 18.0715 18.0601 17.9259C18.0081 17.7803 18.0024 17.6222 18.0437 17.4732C18.085 17.3242 18.1714 17.1916 18.2909 17.0936L19.2449 16.3126C19.1415 15.7971 19.1415 15.2662 19.2449 14.7506L17.7289 13.3156C17.6549 13.2486 17.5951 13.1675 17.5528 13.0771C17.5106 12.9866 17.4869 12.8886 17.483 12.7889C17.4791 12.6891 17.4952 12.5896 17.5303 12.4962C17.5654 12.4027 17.6187 12.3172 17.6873 12.2447C17.7559 12.1721 17.8382 12.114 17.9295 12.0737C18.0209 12.0333 18.1193 12.0117 18.2191 12.0099C18.3189 12.0081 18.4181 12.0263 18.5108 12.0633C18.6035 12.1004 18.6878 12.1556 18.7589 12.2256L20.6269 13.9946C20.7454 14.1067 20.8241 14.2542 20.8512 14.415C20.8782 14.5758 20.8522 14.741 20.7769 14.8856C20.6288 15.3715 20.6348 15.8913 20.7939 16.3736C20.8372 16.5159 20.8374 16.6678 20.7945 16.8101C20.7517 16.9525 20.6676 17.079 20.5529 17.1736L19.2369 18.2506C19.1046 18.3609 18.9382 18.422 18.7659 18.4236Z"
      fill="#4ED7E7"
    />
    <path
      d="M7.18614 13.2263C7.06564 13.2263 6.94693 13.1971 6.84014 13.1413C5.94775 12.5105 5.17442 11.7263 4.55614 10.8253C4.45025 10.6791 4.40065 10.4996 4.41645 10.3197C4.43225 10.1399 4.51239 9.97178 4.64215 9.84627C4.84205 9.65998 4.98969 9.42461 5.07039 9.16355C5.1511 8.9025 5.16204 8.62487 5.10214 8.35827C5.06039 8.22546 5.05642 8.08366 5.09068 7.94873C5.12494 7.81379 5.19609 7.69106 5.29614 7.59427L7.38714 5.57127C7.4578 5.50216 7.54142 5.44769 7.63319 5.41101C7.72497 5.37432 7.82309 5.35613 7.92192 5.35749C8.02074 5.35885 8.11832 5.37974 8.20905 5.41894C8.29978 5.45814 8.38187 5.51488 8.45059 5.58591C8.51932 5.65694 8.57333 5.74086 8.60951 5.83283C8.6457 5.9248 8.66335 6.02302 8.66145 6.12184C8.65955 6.22066 8.63813 6.31813 8.59844 6.40864C8.55874 6.49915 8.50155 6.58093 8.43014 6.64927L6.62314 8.39827C6.69565 9.10542 6.51879 9.81569 6.12314 10.4063C6.51837 10.9444 6.99392 11.4186 7.53314 11.8123C7.68199 11.8899 7.80049 12.0152 7.86968 12.1682C7.93886 12.3211 7.95474 12.4929 7.91476 12.6559C7.87477 12.8189 7.78125 12.9639 7.64915 13.0674C7.51705 13.171 7.35402 13.2273 7.18614 13.2273V13.2263Z"
      fill="#4ED7E7"
    />
    <path
      d="M11.966 16.4163C11.8487 16.4163 11.732 16.4106 11.616 16.3993C11.2204 16.3685 10.8352 16.2576 10.4838 16.0733C10.1325 15.889 9.82222 15.6352 9.572 15.3273C9.2742 14.9235 9.06436 14.4618 8.95602 13.9719C8.84768 13.4821 8.84325 12.9749 8.943 12.4833C9.09214 11.789 9.38893 11.1349 9.81322 10.5655C10.2375 9.99614 10.7794 9.5247 11.402 9.18326C11.5724 9.08323 11.7755 9.0545 11.967 9.10334C12.1585 9.15217 12.323 9.27462 12.4247 9.44406C12.5264 9.6135 12.5571 9.81624 12.5102 10.0082C12.4632 10.2002 12.3424 10.3659 12.174 10.4693C11.7354 10.7026 11.3518 11.0272 11.0491 11.4212C10.7464 11.8151 10.5315 12.2693 10.419 12.7533C10.3584 13.035 10.3563 13.3262 10.4127 13.6088C10.4691 13.8914 10.5829 14.1594 10.747 14.3963C10.8726 14.5449 11.027 14.6666 11.201 14.7539C11.3749 14.8412 11.5647 14.8924 11.759 14.9043C12.5871 14.9344 13.3938 14.6369 14.004 14.0763C14.5214 13.5869 14.8801 12.9536 15.034 12.2583C15.2028 11.7362 15.2049 11.1746 15.04 10.6513L15.024 10.6163L14.809 10.1263C14.7365 9.94532 14.7373 9.74326 14.8113 9.5629C14.8852 9.38255 15.0264 9.23806 15.2051 9.16006C15.3837 9.08205 15.5857 9.07665 15.7682 9.14501C15.9508 9.21337 16.0995 9.35011 16.183 9.52626L16.391 10.0013C16.7191 10.8397 16.7544 11.7643 16.491 12.6253C16.2644 13.6231 15.7378 14.5276 14.982 15.2173C14.15 15.9608 13.0814 16.3856 11.966 16.4163Z"
      fill="#4ED7E7"
    />
  </svg>
)
export default PaymentIcon
