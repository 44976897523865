import { Button, Container, Flex } from '@chakra-ui/react'
import { CandidateJourneyByJobApiInterface } from '@recrutae/components'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

import EditIcon from '~/assets/icons/edit.icon'
import EyeOpenIcon from '~/assets/icons/eye-open.icon'
import { DataTableSortField } from '~/components/data-table/interfaces'
import { withAuth } from '~/HOC/with-auth'
import useCandidateJourneyInJob from '~/hooks/candidate/use-candidate-journey-in-job'
import { useCandidateScore } from '~/hooks/candidate/use-candidate-score'
import { useChatsByJobs } from '~/hooks/chat/use-chats-by-job'
import { useJob } from '~/hooks/jobs/use-job'
import { useJobAnalytics } from '~/hooks/jobs/use-job-analytics'
import ModalCandidateHistory from '~/screens/job/detail/components/candidates/modal-candidate-history'
import ChatTab from '~/screens/job/detail/components/chat-tab'
import { appRoutes } from '~/utils/routes/app-routes'

import AnalyticsTab from './components/analytics/analytics-list.tab'
import CandidatesJourneyListTab from './components/candidates/candidates-journey-list'
import { HeaderJobs } from './components/header-jobs'
import TabsList from './components/tab-list'

const DetailRecruiterScreen = () => {
  const [chatFirstFetch, setChatFirstFetch] = React.useState(true)
  const [selectedCandidate, setSelectedCandidate] = React.useState<
    undefined | CandidateJourneyByJobApiInterface
  >(undefined)

  const { id } = useParams<{ id: string }>()
  const { job } = useJob({ id: id as string })

  const [perPage, setPerPage] = React.useState(10)
  const [page, setPage] = React.useState(1)
  const [sort, setSort] = React.useState<DataTableSortField[]>([
    { columnIndex: 4, sortType: 'DESC' },
  ])

  const sortAsParams = React.useMemo(() => {
    return {
      applicationDateOrder: sort.find((el) => el.columnIndex === 4)?.sortType,
      interviewDateOrder: sort.find((el) => el.columnIndex === 5)?.sortType,
      scoreOrder: sort.find((el) => el.columnIndex === 6)?.sortType,
    }
  }, [sort])

  const { jobJourneys, loading, reFetch } = useCandidateJourneyInJob({
    id: id as string,
    limit: perPage,
    page,
    sort: sortAsParams,
  })

  const { chats, reFetch: reFetchChats } = useChatsByJobs(id as string)

  const { countByStatus } = useJobAnalytics(id as string)
  const { score } = useCandidateScore(id as string)

  return (
    <Container mt="60px">
      <ModalCandidateHistory
        onClose={() => {
          setSelectedCandidate(undefined)
          reFetch().then()
        }}
        isOpen={!!selectedCandidate}
        candidate={selectedCandidate}
      />
      <HeaderJobs job={job} />
      <Flex mt="40px" gap="24px">
        <Button
          variant="outline"
          size="lg"
          leftIcon={<EyeOpenIcon />}
          as={Link}
          to={appRoutes.job.clientView.url(id as string)}
        >
          Visualizar como candidato
        </Button>
        <Button
          variant="solid"
          size="lg"
          colorScheme="secondary"
          leftIcon={<EditIcon height={20} />}
          as={Link}
          to={appRoutes.job.edit.url(id as string)}
        >
          Editar vaga
        </Button>
      </Flex>
      <TabsList
        header={['Candidatos na vaga', 'Chat', 'Analytics']}
        onChange={(index) => {
          if (index === 1) {
            if (chatFirstFetch) setChatFirstFetch(false)
            else reFetchChats().then()
          }
        }}
      >
        <CandidatesJourneyListTab
          setSelectedCandidate={setSelectedCandidate}
          jobScore={job.scoreToApprove}
          typeformId={job.typeFormId}
          jobJourneys={jobJourneys}
          loading={loading}
          reFetch={reFetch}
          maxPaymentRange={job.maxPaymentRange}
          uncontrolledPagination={{ page, setPage, perPage, setPerPage }}
          uncontrolledSort={{ sort, setSort }}
        />
        <ChatTab chats={chats} />
        <AnalyticsTab
          countByStatus={countByStatus}
          score={score}
          maxScore={job.scoreToApprove}
        />
      </TabsList>
    </Container>
  )
}

export default withAuth(DetailRecruiterScreen)
