import { Box } from '@chakra-ui/react'
import React from 'react'

interface PlayerVideoInterface {
  companyVideoUrl: string
}

export const PlayerVideo = ({ companyVideoUrl }: PlayerVideoInterface) => {
  let videoId

  companyVideoUrl.includes('https://www.youtube.com/watch?v=')
    ? (videoId = companyVideoUrl.replace(
        'https://www.youtube.com/watch?v=',
        'https://www.youtube.com/embed/'
      ))
    : ''

  return (
    <>
      {videoId && (
        <Box
          height={['180px', '328px']}
          width={['100%', '538px']}
          as="div"
          aspect-video
          overflow="hidden"
          borderRadius="3xl"
          border="4px"
          bgColor="primaryDark.500"
          borderColor="primaryDark.500"
        >
          <iframe
            width="100%"
            height="100%"
            src={`${videoId}?fs=0&color=white&iv_load_policy=3`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay;"
            allowFullScreen
          />
        </Box>
      )}
    </>
  )
}
