import { Box, Divider, Flex, Text } from '@chakra-ui/react'

import ContractTypeIcon from '~/assets/icons/contract-type.icon'
import LevelIcon from '~/assets/icons/level.icon'
import PaymentIcon from '~/assets/icons/payment.icon'
import WorkTypeIcon from '~/assets/icons/work-type.icon'
import { JobContractApiType } from '~/model/interfaces/api/common/job.interface'
import { jobLevelEnum, JobLevelEnumType } from '~/utils/from-to/level'
import { workTypeEnum, WorkTypeEnumType } from '~/utils/from-to/worktype'

interface IContractInfo {
  workType: string
  level: string
  contractTypes: JobContractApiType[]
}

const ContractItem = ({
  Icon,
  title,
  description,
}: {
  Icon: React.ReactElement
  title: string
  description: string
}) => {
  return (
    <Flex gap="10px">
      <Box background="#EAF8F8" width="60px" p="8px 12px" borderRadius="lg">
        <Flex alignItems="center" justifyContent="center">
          {Icon}
        </Flex>
      </Box>
      <Box>
        <Text as="h2" fontSize="small">
          {title}
        </Text>
        <Text as="h3" fontWeight="bold" fontSize="sm">
          {description}
        </Text>
      </Box>
    </Flex>
  )
}

export const ContractType = ({
  workType,
  level,
  contractTypes,
}: IContractInfo) => {
  return (
    <Box mt="60px">
      <Divider />
      <Flex mt="40px" mb="20px" justifyContent="space-between">
        <ContractItem
          title="Tipo de contratação:"
          description={contractTypes?.join(' ou ') || 'À definir'}
          Icon={<ContractTypeIcon />}
        />
        <ContractItem
          title="Modelo de trabalho"
          description={workTypeEnum[workType as WorkTypeEnumType]}
          Icon={<WorkTypeIcon />}
        />
        <ContractItem
          title="Nivel hierárquico:"
          description={jobLevelEnum[level as JobLevelEnumType]}
          Icon={<LevelIcon />}
        />
        <ContractItem
          title="Faixa salarial"
          description="A combinar"
          Icon={<PaymentIcon />}
        />
      </Flex>
    </Box>
  )
}
