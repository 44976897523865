import { Box } from '@chakra-ui/react'
import React from 'react'

import { ChatApiInterface } from '~/model/interfaces/api/chat.interface'

import CardChat from './card-chat'
import { CTAWithoutConversation } from './cta-without-conversation'

const ChatList = ({
  setActiveChat,
  chats,
}: {
  setActiveChat: React.Dispatch<React.SetStateAction<ChatApiInterface>>
  chats: Array<ChatApiInterface>
}) => {
  return (
    <Box
      background="black.100"
      w="100%"
      h="100%"
      borderRadius="16px 0 0 16px"
      p="12px"
      gap="12px"
      overflow="auto"
    >
      {/* <SearchChat /> */}
      {chats?.length !== 0 ? (
        chats?.map((chat) => (
          <CardChat
            key={chat.id}
            chat={chat}
            onClick={() => setActiveChat(chat)}
          />
        ))
      ) : (
        <CTAWithoutConversation />
      )}
    </Box>
  )
}

export default ChatList
