import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'

import CalendarIcon from '~/assets/icons/calendar.icon'
import { appRoutes } from '~/utils/routes/app-routes'

interface ScheduledInterviewInterface {
  jobId: string
  rescheduleLink: string
  meetingAppointmentDate: string
}

export const ScheduledInterviewHomeCard = ({
  rescheduleLink,
  meetingAppointmentDate,
  jobId,
}: ScheduledInterviewInterface) => {
  return (
    <Box
      width="100%"
      bgColor="secondary.50"
      borderRadius="8px"
      mt="24px"
      height="150px"
      padding="m2"
    >
      <Flex
        gap="24px"
        background="secondary.500"
        borderRadius="8px"
        padding="6px 12px"
        alignItems="center"
      >
        <CalendarIcon width={20} height={20} />
        <Text color="white" fontWeight="bold">
          {`Dia ${format(new Date(meetingAppointmentDate), 'dd/MM/yyyy')}`}
          <br />
          {`às ${format(new Date(meetingAppointmentDate), 'HH:mm')}`}
        </Text>
      </Flex>
      <Flex
        gap="24px"
        alignItems="center"
        justifyContent="space-between"
        mt="20px"
      >
        <Button
          onClick={() => window.open(`${rescheduleLink}`, '_blank')}
          variant="ghost"
          color="secondary.500"
          fontSize="small"
        >
          Reagendar
        </Button>

        <Button
          as={Link}
          to={appRoutes.job.detail.url(jobId)}
          variant="solid"
          colorScheme="secondary"
          fontSize="small"
          borderRadius="16px"
        >
          Ver detalhes
        </Button>
      </Flex>
    </Box>
  )
}
