import { IconInterface } from '../../../model/interfaces/components/icon.interface'

const RussianIcon = ({
  width = 35.165,
  height = 35.165,
  ...props
}: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 35.165 35.166"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#0b318f"
      d="M35.165 64.326a17.542 17.542 0 00-1-5.861H1a17.605 17.605 0 000 11.722h33.16a17.549 17.549 0 001-5.861"
      data-name="Caminho 11522"
      transform="translate(.001 -46.743)"
    ></path>
    <path
      fill="#fff"
      d="M21.587 0A17.587 17.587 0 005.008 11.722h33.157A17.587 17.587 0 0021.587 0"
      data-name="Caminho 11523"
      transform="translate(-4.004)"
    ></path>
    <path
      fill="#e60012"
      d="M38.166 116.931H5.009a17.585 17.585 0 0033.157 0"
      data-name="Caminho 11524"
      transform="translate(-4.005 -93.487)"
    ></path>
  </svg>
)
export default RussianIcon
