import { IconInterface } from '../../model/interfaces/components/icon.interface'

const LogoCompanyImg = ({
  width = 180.725,
  height = 35.432,
  ...props
}: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 180.725 35.432"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Grupo_9229" data-name="Grupo 9229" transform="translate(-6662 736)">
      <path
        id="Caminho_9239"
        data-name="Caminho 9239"
        d="M30.243,30.244A17.723,17.723,0,0,1,9.2,33.257l-3.9-.548,5.218-5.219a12.134,12.134,0,1,0-2.582-2.582L3.963,28.887a17.717,17.717,0,1,1,26.28,1.357"
        transform="translate(6662 -736)"
        fill="#fecc16"
      />
      <path
        id="Caminho_9240"
        data-name="Caminho 9240"
        d="M26.925,14.928a8.482,8.482,0,1,0,0,12,8.493,8.493,0,0,0,0-12M16.177,25.677a6.725,6.725,0,0,1,0-9.5.483.483,0,0,1,.684.684,5.757,5.757,0,0,0,0,8.133.483.483,0,0,1-.684.684"
        transform="translate(6658.79 -739.21)"
        fill="#fecc16"
      />
      <path
        id="Caminho_9241"
        data-name="Caminho 9241"
        d="M221.032,5.755,226.141.936h3.73l5.075,4.819h-5.075l-1.839-2.231-1.891,2.231Z"
        transform="translate(6605.004 -736.242)"
        fill="#fecc16"
      />
      <path
        id="Caminho_9242"
        data-name="Caminho 9242"
        d="M89.552,22.441H74.617a4.966,4.966,0,0,0,1.728,3.141A5.419,5.419,0,0,0,79.93,26.75a6.2,6.2,0,0,0,4.48-1.823l3.917,1.839a9.252,9.252,0,0,1-3.509,3.075,10.978,10.978,0,0,1-4.854,1,9.592,9.592,0,0,1-7.1-2.75A9.361,9.361,0,0,1,70.12,21.2a9.673,9.673,0,0,1,2.734-7.042,9.178,9.178,0,0,1,6.854-2.8,9.519,9.519,0,0,1,7.119,2.8,10.123,10.123,0,0,1,2.742,7.4ZM84.9,18.779a4.666,4.666,0,0,0-1.817-2.52,5.269,5.269,0,0,0-3.147-.971,5.569,5.569,0,0,0-3.412,1.09,6.073,6.073,0,0,0-1.706,2.4Z"
        transform="translate(6643.919 -738.928)"
        fill="#724fff"
      />
      <path
        id="Caminho_9243"
        data-name="Caminho 9243"
        d="M119.2,15.578l-3.87,2.128a6.578,6.578,0,0,0-2.157-1.584,6.473,6.473,0,0,0-2.5-.442,5.818,5.818,0,0,0-4.221,1.556,5.3,5.3,0,0,0-1.611,3.988,5.125,5.125,0,0,0,1.552,3.86,5.624,5.624,0,0,0,4.075,1.5,5.926,5.926,0,0,0,4.86-2.128l3.666,2.51a11.294,11.294,0,0,1-16.068.981,9.629,9.629,0,0,1,2.333-15.293,11.207,11.207,0,0,1,5.39-1.294,10.925,10.925,0,0,1,4.956,1.1,9.4,9.4,0,0,1,3.593,3.125"
        transform="translate(6636.172 -738.928)"
        fill="#724fff"
      />
      <path
        id="Caminho_9244"
        data-name="Caminho 9244"
        d="M146.467,12h4.7V20.92a12.353,12.353,0,0,0,.359,3.619,3.159,3.159,0,0,0,1.152,1.576,3.3,3.3,0,0,0,1.955.562,3.414,3.414,0,0,0,1.972-.554,3.245,3.245,0,0,0,1.2-1.626,12.47,12.47,0,0,0,.29-3.423V12h4.649v7.851q0,4.854-.767,6.642a7.242,7.242,0,0,1-2.759,3.346A8.452,8.452,0,0,1,154.59,31a8.213,8.213,0,0,1-4.93-1.363,7.2,7.2,0,0,1-2.649-3.8,23,23,0,0,1-.545-6.131Z"
        transform="translate(6624.232 -739.094)"
        fill="#724fff"
      />
      <path
        id="Caminho_9245"
        data-name="Caminho 9245"
        d="M174.13,2.794h4.65V9.623h2.759v4H178.78V28.152h-4.65V13.626h-2.384v-4h2.384Z"
        transform="translate(6617.713 -736.721)"
        fill="#724fff"
      />
      <path
        id="Caminho_9246"
        data-name="Caminho 9246"
        d="M200.914,11.83h4.649V30.359h-4.649V28.4a9.125,9.125,0,0,1-2.73,1.865,7.65,7.65,0,0,1-2.969.571,8.247,8.247,0,0,1-6.209-2.785,9.716,9.716,0,0,1-2.62-6.923,9.956,9.956,0,0,1,2.534-7.033,8.076,8.076,0,0,1,6.158-2.742,7.814,7.814,0,0,1,3.13.631,8.546,8.546,0,0,1,2.705,1.89Zm-4.888,3.815a4.7,4.7,0,0,0-3.582,1.524,5.494,5.494,0,0,0-1.426,3.909,5.553,5.553,0,0,0,1.451,3.951,4.7,4.7,0,0,0,3.573,1.55,4.79,4.79,0,0,0,3.632-1.524,5.572,5.572,0,0,0,1.443-3.994,5.41,5.41,0,0,0-1.443-3.916,4.851,4.851,0,0,0-3.649-1.5"
        transform="translate(6613.938 -738.928)"
        fill="#724fff"
      />
      <path
        id="Caminho_9247"
        data-name="Caminho 9247"
        d="M236.762,21.553a10.12,10.12,0,0,0-2.741-7.4,9.511,9.511,0,0,0-7.117-2.8,9.171,9.171,0,0,0-6.857,2.8,9.677,9.677,0,0,0-2.733,7.041,9.372,9.372,0,0,0,2.741,6.89,9.6,9.6,0,0,0,7.1,2.75,10.985,10.985,0,0,0,4.852-1,9.253,9.253,0,0,0,3.508-3.076L231.6,24.926a6.2,6.2,0,0,1-4.479,1.822,5.426,5.426,0,0,1-3.585-1.165,4.974,4.974,0,0,1-1.728-3.143h14.936Zm-14.748-2.776a6.06,6.06,0,0,1,1.706-2.4,5.561,5.561,0,0,1,3.41-1.092,5.279,5.279,0,0,1,3.149.971,4.667,4.667,0,0,1,1.816,2.52Z"
        transform="translate(6605.962 -738.928)"
        fill="#724fff"
      />
      <path
        id="Caminho_9248"
        data-name="Caminho 9248"
        d="M58.828,31.165H54.337V19.939a8.289,8.289,0,0,1,8.279-8.279h2.245v4.49H62.616a3.793,3.793,0,0,0-3.789,3.789Z"
        transform="translate(6647.988 -739.007)"
        fill="#724fff"
      />
      <path
        id="Caminho_9249"
        data-name="Caminho 9249"
        d="M133.541,31.165H129.05V19.939a8.289,8.289,0,0,1,8.279-8.279h2.246v4.49h-2.246a3.793,3.793,0,0,0-3.789,3.789Z"
        transform="translate(6628.723 -739.007)"
        fill="#724fff"
      />
    </g>
  </svg>
)
export default LogoCompanyImg
