import React from 'react'

interface HeaderInterface {
  title: string
  titleOg?: string
  description: string
  descriptionOg?: string
  imageOg?: string
  children?: React.ReactElement
}

const Header = ({
  description,
  title,
  titleOg,
  descriptionOg,
  imageOg,
  children,
}: HeaderInterface) => {
  return (
    <head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={titleOg || title} />
      <meta property="og:url" content="https://recrutae.com.br" />
      <meta property="og:description" content={descriptionOg || description} />
      <meta property="og:type" content="website" />
      {imageOg && <meta property="og:image" content={imageOg} />}
      {children}
    </head>
  )
}

export default Header
