import { Container } from '@chakra-ui/react'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { MenuBar } from './menu-top'

const Navbar = () => {
  const { pathname } = useLocation()

  if (pathname === '/' || pathname.includes('forgot')) return <></>

  return (
    <Container>
      <MenuBar />
    </Container>
  )
}

export default Navbar
