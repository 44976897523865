import { RecruiterService } from '@recrutae/components'
import React from 'react'

import { RecruiterApiInterface } from '~/model/interfaces/api/user.interface'
import { HttpService } from '~/services/http.service'

export const useRecruiter = (id: string) => {
  const [loading, setLoading] = React.useState(false)
  const [recruiter, setRecruiter] = React.useState<RecruiterApiInterface>(
    {} as RecruiterApiInterface
  )

  const fetch = React.useCallback(async () => {
    setLoading(true)

    try {
      const http = new HttpService()
      const service = new RecruiterService(http)
      const { data } = await service.getById(id)
      React.startTransition(() => {
        setRecruiter(data)
        setLoading(false)
      })
    } catch (e) {
      React.startTransition(() => {
        setRecruiter({} as RecruiterApiInterface)
        setLoading(false)
      })
    }
  }, [id])

  React.useEffect(() => {
    if (id) fetch().then()
  }, [fetch])

  return { recruiter, loading }
}
