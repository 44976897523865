import { z } from 'zod'

export interface LoginFormInterface {
  email: string
  password: string
}

export const loginSchema = z.object({
  email: z
    .string()
    .nonempty('Campo Obrigatório')
    .email('Digite um email válido'),
  password: z.string().nonempty('Campo Obrigatório'),
})
