import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '~/store/auth.context'

export const withAuth = (WrappedComponent: React.FC) => {
  return () => {
    const { user } = useAuth()
    const navigate = useNavigate()

    React.useEffect(() => {
      if (!user) {
        return navigate('/')
      }
    }, [user])

    return <WrappedComponent />
  }
}
