import { IconInterface } from '../../../model/interfaces/components/icon.interface'

const SpanishIcon = ({
  width = 35.165,
  height = 35.166,
  ...props
}: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 35.165 35.166"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="Caminho_11478"
      data-name="Caminho 11478"
      d="M26.985,0A17.573,17.573,0,0,0,11.76,8.791H42.21A17.573,17.573,0,0,0,26.985,0"
      transform="translate(-9.402)"
      fill="#e60012"
    />
    <path
      id="Caminho_11479"
      data-name="Caminho 11479"
      d="M35.165,52.64a17.5,17.5,0,0,0-2.358-8.791H2.358a17.568,17.568,0,0,0,0,17.582h30.45a17.494,17.494,0,0,0,2.358-8.791"
      transform="translate(0 -35.058)"
      fill="#ffe200"
    />
    <path
      id="Caminho_11480"
      data-name="Caminho 11480"
      d="M42.211,131.544H11.761a17.578,17.578,0,0,0,30.45,0"
      transform="translate(-9.403 -105.17)"
      fill="#e60012"
    />
  </svg>
)
export default SpanishIcon
