import { IconInterface } from '../../model/interfaces/components/icon.interface'

const PlusIcon = ({
  width = 42.604,
  height = 42.605,
  ...props
}: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 42.604 42.605"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#02004A"
      d="M21.3 0a21.3 21.3 0 1021.3 21.3A21.326 21.326 0 0021.3 0m0 38.344A17.042 17.042 0 1138.344 21.3 17.061 17.061 0 0121.3 38.345"
      data-name="Caminho 12159"
      transform="translate(0 -.001)"
    ></path>
    <path
      fill="#02004A"
      d="M47.1 39.423h-4.26v-4.26a2.13 2.13 0 00-4.26 0v4.26h-4.26a2.13 2.13 0 100 4.26h4.26v4.26a2.13 2.13 0 104.26 0v-4.26h4.26a2.13 2.13 0 000-4.26"
      data-name="Caminho 12160"
      transform="translate(-19.409 -19.916)"
    ></path>
  </svg>
)
export default PlusIcon
