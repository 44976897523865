import { Box, ListItem, UnorderedList } from '@chakra-ui/react'
import React from 'react'

import TitleSteps from '~/components/title-steps/title-steps'
import { convertTextToList } from '~/utils/text/convert-text-to-list'

interface BenefitsInterface {
  benefits: string
}

export const Benefits = ({ benefits }: BenefitsInterface) => {
  return (
    <Box width="45%">
      <TitleSteps text="Benefícios" as="p" />

      <Box>
        <UnorderedList mt="20px">
          {convertTextToList(benefits, '- ')?.map((item) => (
            <ListItem color="primaryDark.500" key={item.indexOf.toString()}>
              {item}
            </ListItem>
          ))}
        </UnorderedList>
      </Box>
    </Box>
  )
}
