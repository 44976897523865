import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'

import TextInput from '~/components/form/text.input'

import { FormProfissionalCandidateData } from './form-profisional-data-profisional'

export const FormPersonalInfoCandidateData = ({ register, errors }: any) => {
  return (
    <Flex wrap="wrap" gap="20px" mt="40px" width="100%">
      <TextInput
        color="primaryDark.500"
        label="Nome"
        name="name"
        {...register('name')}
        error={errors?.name}
        spacing={{ width: 'calc(50% - 10px)' }}
      />
      <TextInput
        color="primaryDark.500"
        label="CPF"
        name="document"
        {...register('document')}
        error={errors?.document}
        spacing={{ width: 'calc(50% - 10px)' }}
      />
      <TextInput
        color="primaryDark.500"
        label="Telefone"
        name="phone"
        {...register('phone')}
        error={errors?.phone}
        spacing={{ width: 'calc(50% - 10px)' }}
      />
      <TextInput
        color="primaryDark.500"
        label="E-mail"
        name="email"
        isDisabled
        {...register('email')}
        error={errors?.email}
        spacing={{ width: 'calc(50% - 10px)' }}
      />
      <Box width="100%">
        <Text fontWeight="bold" mt="40px">
          Dados Profissionais
        </Text>
        <FormProfissionalCandidateData register={register} errors={errors} />
      </Box>
    </Flex>
  )
}
