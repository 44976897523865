import { Button, Container, Flex } from '@chakra-ui/react'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { withAuth } from '~/HOC/with-auth'
import { useJob } from '~/hooks/jobs/use-job'
import { useRecruiter } from '~/hooks/use-recruiter'
import { appRoutes } from '~/utils/routes/app-routes'

import { Benefits } from './components/benefits'
import { ContractType } from './components/contract-type'
import { HeaderJobs } from './components/header-jobs'
import { JobDescription } from './components/job-description'
import { Languages } from './components/languages'
import { SoftSkillRequirements } from './components/softskill-requirements'
import { TechnicalRequirements } from './components/technical-requirements'

const ClientViewScreen = () => {
  const { id } = useParams()
  const { job } = useJob({ id: id as string })

  const { recruiter } = useRecruiter(job.recruiterId)

  const hasVideo = React.useMemo(() => {
    return !!job.companyVideoUrl
  }, [job.companyVideoUrl])

  return (
    <Container mt="60px">
      <HeaderJobs job={job} hasVideo={hasVideo} recruiter={recruiter} />
      <Flex mt="90px" wrap="wrap" justifyContent="space-between">
        <JobDescription jobDescription={job.jobDescription} />
        <TechnicalRequirements
          technicalRequirements={job.technicalRequirements}
        />
      </Flex>
      <Flex justifyContent="space-between" wrap="wrap" mt={['50px']}>
        <Benefits benefits={job.benefits} />
        <SoftSkillRequirements
          softSkillRequirements={job.softSkillRequirements}
        />
      </Flex>
      <Languages languages={job.languages} />
      <ContractType
        workType={job.workType}
        level={job.level}
        contractTypes={job.contractTypes}
      />
      <Flex
        width="100%"
        mt="60px"
        justifyContent="center"
        alignItems="center"
        gap={['60px']}
      >
        {id && (
          <>
            <Button
              borderRadius="2xl"
              size="lg"
              colorScheme="secondary"
              borderColor="secondary.500"
              border="2px"
              variant="outline"
              fontSize="medium"
              width="20%"
              as={Link}
              to={appRoutes.job.detail.url(id as string)}
            >
              Voltar
            </Button>
            <Button
              borderRadius="2xl"
              colorScheme="secondary"
              size="lg"
              fontSize="medium"
              width={['20%']}
              as={Link}
              to={appRoutes.job.edit.url(id as string)}
            >
              Editar vaga
            </Button>
          </>
        )}
      </Flex>
    </Container>
  )
}

export default withAuth(ClientViewScreen)
