import { IconInterface } from '../../model/interfaces/components/icon.interface'

const LevelIcon = ({ width = 31, height = 31, ...props }: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.8253 30.3838H13.8143C13.7158 30.3823 13.6185 30.3615 13.5281 30.3225C13.4376 30.2835 13.3558 30.227 13.2871 30.1563C13.2185 30.0857 13.1645 30.0022 13.1281 29.9106C13.0918 29.8191 13.0738 29.7213 13.0753 29.6228C13.0863 29.0005 13.2334 28.3881 13.5063 27.8288C13.1368 26.783 13.183 25.6354 13.6353 24.6228C13.7052 24.4543 13.8342 24.3171 13.998 24.2368C14.1618 24.1566 14.3493 24.1388 14.5253 24.1868C15.0296 24.3167 15.5582 24.3205 16.0643 24.1978C16.2434 24.1388 16.4382 24.1495 16.6098 24.2277C16.7814 24.3059 16.9173 24.4459 16.9903 24.6198C17.4487 25.6317 17.4992 26.7815 17.1313 27.8298C17.4035 28.3911 17.5474 29.0059 17.5523 29.6298C17.5528 29.7283 17.5339 29.8259 17.4967 29.9171C17.4595 30.0083 17.4047 30.0912 17.3354 30.1613C17.2662 30.2313 17.1838 30.287 17.093 30.3251C17.0022 30.3633 16.9048 30.3832 16.8063 30.3838C16.7078 30.3843 16.6102 30.3654 16.519 30.3282C16.4278 30.291 16.3448 30.2362 16.2748 30.1669C16.2048 30.0977 16.1491 30.0153 16.1109 29.9245C16.0727 29.8337 16.0528 29.7363 16.0523 29.6378C16.0469 29.14 15.9039 28.6534 15.6393 28.2318C15.5321 28.0867 15.4808 27.9079 15.4948 27.7281C15.5087 27.5483 15.587 27.3795 15.7153 27.2528C15.9823 26.9888 15.9253 26.3248 15.7903 25.7728C15.474 25.8005 15.1557 25.7965 14.8403 25.7608C14.6993 26.3348 14.6573 26.9898 14.9233 27.2528C15.0535 27.3777 15.1334 27.5461 15.1478 27.7259C15.1621 27.9058 15.11 28.0847 15.0013 28.2288C14.7329 28.6531 14.5856 29.1428 14.5753 29.6448C14.5724 29.8418 14.4921 30.0297 14.3518 30.168C14.2114 30.3063 14.0223 30.3838 13.8253 30.3838Z"
      fill="#4ED7E7"
    />
    <path
      d="M5.6193 30.3845C5.50983 30.3844 5.40171 30.3603 5.30251 30.314C5.20331 30.2677 5.11543 30.2003 5.04503 30.1165C4.97463 30.0326 4.92341 29.9344 4.89495 29.8287C4.8665 29.723 4.8615 29.6123 4.8803 29.5045L5.2803 27.2375L5.2863 27.2095C5.44031 26.5116 5.77578 25.8667 6.25881 25.3399C6.74184 24.8132 7.35532 24.4232 8.0373 24.2095L8.0843 24.1965L10.5233 23.5965C10.6336 23.5695 10.7487 23.568 10.8597 23.592C10.9707 23.616 11.0749 23.6649 11.1642 23.735C11.2536 23.8051 11.3259 23.8946 11.3756 23.9967C11.4253 24.0988 11.4512 24.2109 11.4513 24.3245V26.8095C12.2455 26.5464 12.921 26.0108 13.3583 25.2975C13.0587 25.0922 12.8062 24.8256 12.6176 24.5152C12.429 24.2049 12.3085 23.8579 12.2643 23.4975C12.2629 23.4712 12.2629 23.4448 12.2643 23.4185V22.2715C12.2643 22.0726 12.3433 21.8818 12.484 21.7412C12.6246 21.6005 12.8154 21.5215 13.0143 21.5215C13.2132 21.5215 13.404 21.6005 13.5446 21.7412C13.6853 21.8818 13.7643 22.0726 13.7643 22.2715V23.3615C13.7967 23.5554 13.8846 23.7358 14.0173 23.8808C14.1501 24.0258 14.322 24.1292 14.5123 24.1785C14.7013 24.2251 14.8648 24.3434 14.9682 24.5084C15.0716 24.6733 15.1068 24.872 15.0663 25.0625C15.0423 25.1755 14.4353 27.8465 10.8433 28.5205C10.735 28.5408 10.6236 28.5369 10.517 28.5093C10.4103 28.4816 10.3111 28.4308 10.2263 28.3604C10.1415 28.2901 10.0733 28.2019 10.0265 28.1022C9.97963 28.0025 9.95533 27.8937 9.9553 27.7835V25.2755L8.4693 25.6395C8.04622 25.7757 7.66594 26.0198 7.36591 26.3477C7.06588 26.6756 6.85646 27.076 6.7583 27.5095L6.3583 29.7615C6.32835 29.9358 6.23777 30.0939 6.10254 30.2079C5.96732 30.3219 5.79616 30.3845 5.6193 30.3845Z"
      fill="#4ED7E7"
    />
    <path
      d="M24.8881 30.4828C24.712 30.4827 24.5415 30.4206 24.4066 30.3075C24.2716 30.1943 24.1808 30.0373 24.1501 29.8638L23.7501 27.6048C23.6502 27.1702 23.4384 26.7692 23.1359 26.4415C22.8333 26.1139 22.4504 25.871 22.0251 25.7368L20.6891 25.3368V27.7828C20.689 27.893 20.6647 28.0018 20.6179 28.1015C20.5711 28.2012 20.5028 28.2894 20.418 28.3598C20.3333 28.4301 20.234 28.4809 20.1274 28.5086C20.0208 28.5363 19.9093 28.5401 19.8011 28.5198C16.2111 27.8468 15.5931 25.1788 15.5681 25.0648C15.5267 24.8741 15.5613 24.6748 15.6646 24.5091C15.7678 24.3435 15.9316 24.2247 16.1211 24.1778C16.3128 24.1279 16.4863 24.024 16.6208 23.8785C16.7554 23.733 16.8453 23.552 16.8801 23.3568V22.2568C16.8801 22.0579 16.9591 21.8672 17.0997 21.7265C17.2404 21.5859 17.4311 21.5068 17.6301 21.5068C17.829 21.5068 18.0197 21.5859 18.1604 21.7265C18.301 21.8672 18.3801 22.0579 18.3801 22.2568V23.4128C18.3817 23.4415 18.3817 23.4702 18.3801 23.4988C18.3321 23.8573 18.2094 24.2016 18.0199 24.5096C17.8304 24.8176 17.5784 25.0824 17.2801 25.2868C17.7208 26.0005 18.3988 26.5361 19.1951 26.7998V24.3148C19.1951 24.1979 19.2225 24.0826 19.275 23.9781C19.3276 23.8736 19.4038 23.7829 19.4977 23.7131C19.5915 23.6434 19.7004 23.5965 19.8156 23.5763C19.9308 23.5561 20.0491 23.5632 20.1611 23.5968L22.4681 24.2898H22.4781C23.1581 24.5039 23.7699 24.8932 24.2519 25.4186C24.7339 25.9439 25.0692 26.5869 25.2241 27.2828C25.2241 27.2928 25.2261 27.3028 25.2301 27.3128L25.6301 29.5878C25.649 29.6958 25.6442 29.8065 25.6158 29.9124C25.5874 30.0182 25.5362 30.1165 25.4658 30.2005C25.3954 30.2844 25.3074 30.352 25.2081 30.3983C25.1089 30.4447 25.0006 30.4687 24.8911 30.4688L24.8881 30.4828Z"
      fill="#4ED7E7"
    />
    <path
      d="M13.8251 23.4568C13.7388 23.4568 13.6532 23.4419 13.5721 23.4128C12.0721 22.8728 11.2241 21.6128 10.9951 19.5718V19.5568L10.6951 16.3168C10.6437 15.9228 10.6778 15.5225 10.795 15.1429C10.9121 14.7632 11.1096 14.4133 11.3741 14.1168C11.6862 13.7746 12.0828 13.5205 12.5241 13.3798C12.8022 12.9885 13.1797 12.6785 13.6175 12.4816C14.0554 12.2847 14.5378 12.2082 15.0151 12.2598H15.6151C16.2603 12.2592 16.8991 12.3881 17.4936 12.6389C18.0881 12.8896 18.6262 13.2572 19.0761 13.7198C19.4064 14.0998 19.6505 14.5469 19.7915 15.0302C19.9325 15.5136 19.9672 16.0218 19.8931 16.5198L19.6381 19.5498C19.6381 19.5568 19.6381 19.5638 19.6381 19.5708C19.4081 21.6148 18.5661 22.8708 17.0621 23.4108C16.8748 23.478 16.6686 23.4681 16.4886 23.3833C16.3087 23.2984 16.1698 23.1455 16.1026 22.9583C16.0353 22.7711 16.0453 22.5648 16.1301 22.3848C16.215 22.2049 16.3678 22.066 16.5551 21.9988C17.2241 21.7588 17.9361 21.2628 18.1471 19.4128L18.4021 16.3808C18.4021 16.3715 18.4021 16.3625 18.4021 16.3538C18.454 16.0639 18.4406 15.7662 18.3629 15.4821C18.2853 15.1981 18.1453 14.9349 17.9531 14.7118C17.3284 14.1003 16.4892 13.7575 15.6151 13.7568H15.0151C14.2221 13.7568 13.8971 14.0098 13.8251 14.1218C13.8042 14.3048 13.7167 14.4737 13.5793 14.5963C13.442 14.719 13.2642 14.7868 13.0801 14.7868C12.8465 14.8226 12.6336 14.9412 12.4801 15.1208C12.3572 15.2644 12.2669 15.4328 12.2151 15.6145C12.1633 15.7963 12.1514 15.987 12.1801 16.1738L12.4801 19.4098C12.6901 21.2628 13.4031 21.7578 14.0731 21.9988C14.2393 22.0584 14.3792 22.1748 14.4681 22.3274C14.557 22.4801 14.5891 22.6592 14.5589 22.8332C14.5287 23.0072 14.438 23.165 14.3028 23.2787C14.1677 23.3924 13.9967 23.4548 13.8201 23.4548L13.8251 23.4568Z"
      fill="#4ED7E7"
    />
    <path
      d="M15.317 0.25C15.457 0.24991 15.5943 0.289014 15.7132 0.362887C15.8321 0.43676 15.928 0.542452 15.99 0.668L17.531 3.79L20.976 4.29C21.1145 4.31011 21.2445 4.36855 21.3515 4.45872C21.4585 4.54888 21.5382 4.66718 21.5814 4.80023C21.6247 4.93329 21.6299 5.0758 21.5964 5.21165C21.563 5.3475 21.4921 5.47128 21.392 5.569L18.898 8L19.486 11.431C19.5097 11.5689 19.4944 11.7107 19.4417 11.8404C19.389 11.9701 19.3011 12.0824 19.1879 12.1647C19.0747 12.247 18.9407 12.296 18.8011 12.3061C18.6615 12.3162 18.5219 12.2871 18.398 12.222L15.317 10.6L12.238 12.22C12.1141 12.2851 11.9745 12.3142 11.8349 12.3041C11.6953 12.294 11.5613 12.245 11.4481 12.1627C11.3349 12.0804 11.247 11.9681 11.1943 11.8384C11.1416 11.7087 11.1263 11.5669 11.15 11.429L11.738 8L9.245 5.57C9.14486 5.47228 9.07405 5.3485 9.04058 5.21265C9.0071 5.0768 9.0123 4.93429 9.05558 4.80123C9.09886 4.66818 9.17849 4.54988 9.28548 4.45972C9.39246 4.36955 9.52254 4.31111 9.661 4.291L13.106 3.791L14.647 0.669C14.7086 0.543721 14.804 0.438127 14.9223 0.364102C15.0407 0.290077 15.1774 0.250561 15.317 0.25ZM19.255 5.556L16.924 5.217C16.8036 5.19959 16.6893 5.15317 16.5909 5.08174C16.4925 5.01032 16.4129 4.91603 16.359 4.807L15.317 2.694L14.275 4.807C14.2211 4.91603 14.1415 5.01032 14.0431 5.08174C13.9447 5.15317 13.8304 5.19959 13.71 5.217L11.379 5.556L13.066 7.2C13.1532 7.28486 13.2184 7.38965 13.256 7.50533C13.2936 7.62101 13.3025 7.7441 13.282 7.864L12.882 10.186L14.967 9.086C15.0749 9.02908 15.195 8.99932 15.317 8.99932C15.439 8.99932 15.5591 9.02908 15.667 9.086L17.752 10.186L17.352 7.864C17.3315 7.7441 17.3404 7.62101 17.378 7.50533C17.4156 7.38965 17.4809 7.28486 17.568 7.2L19.255 5.556Z"
      fill="#4ED7E7"
    />
    <path
      d="M23.2619 7.17773C23.4238 7.17766 23.5813 7.22993 23.7109 7.32673L25.9159 8.97273L28.5219 8.08874C28.6544 8.04384 28.7969 8.03691 28.9331 8.06872C29.0694 8.10053 29.194 8.16981 29.2929 8.26875C29.3919 8.36768 29.4611 8.4923 29.493 8.62855C29.5248 8.76479 29.5178 8.90722 29.4729 9.03973L28.5889 11.6457L30.2349 13.8507C30.3191 13.9629 30.3701 14.0964 30.3822 14.2361C30.3943 14.3758 30.367 14.5162 30.3033 14.6411C30.2397 14.7661 30.1423 14.8707 30.0222 14.9431C29.9021 15.0155 29.7642 15.0528 29.6239 15.0507L26.8729 15.0157L25.2839 17.2617C25.2032 17.3761 25.092 17.4656 24.9631 17.5201C24.8341 17.5746 24.6924 17.5919 24.5541 17.5701C24.4158 17.5482 24.2864 17.4881 24.1805 17.3965C24.0746 17.3049 23.9965 17.1855 23.9549 17.0517L23.1379 14.4247L20.5109 13.6077C20.3772 13.5662 20.2578 13.4881 20.1662 13.3822C20.0746 13.2763 20.0144 13.1468 19.9926 13.0085C19.9707 12.8702 19.988 12.7286 20.0425 12.5996C20.097 12.4706 20.1865 12.3595 20.3009 12.2787L22.5469 10.6897L22.5119 7.93874C22.5106 7.83941 22.529 7.7408 22.5661 7.64865C22.6032 7.5565 22.6583 7.47263 22.728 7.40193C22.7978 7.33122 22.8809 7.27508 22.9726 7.23677C23.0642 7.19845 23.1626 7.17873 23.2619 7.17873V7.17773ZM25.7829 10.5587C25.6211 10.5588 25.4636 10.5065 25.3339 10.4097L24.0339 9.43774L24.0549 11.0637C24.0565 11.1853 24.0284 11.3055 23.9732 11.4139C23.918 11.5222 23.8372 11.6155 23.7379 11.6857L22.4099 12.6247L23.9629 13.1077C24.0792 13.1438 24.1849 13.2076 24.271 13.2937C24.357 13.3798 24.4209 13.4855 24.4569 13.6017L24.9399 15.1547L25.8789 13.8267C25.9492 13.7275 26.0425 13.6468 26.1509 13.5916C26.2592 13.5364 26.3793 13.5083 26.5009 13.5097L28.1269 13.5307L27.1549 12.2307C27.0823 12.1333 27.0344 12.0197 27.0154 11.8997C26.9965 11.7797 27.0069 11.6568 27.0459 11.5417L27.5679 10.0017L26.0279 10.5237C25.9488 10.5489 25.866 10.5607 25.7829 10.5587Z"
      fill="#4ED7E7"
    />
    <path
      d="M7.37109 7.17777C7.47042 7.17776 7.56877 7.19749 7.66043 7.2358C7.75208 7.27411 7.83521 7.33025 7.90498 7.40096C7.97476 7.47167 8.02979 7.55553 8.06689 7.64768C8.10399 7.73984 8.12241 7.83844 8.12109 7.93777L8.08609 10.6888L10.3321 12.2778C10.4465 12.3585 10.536 12.4697 10.5905 12.5986C10.645 12.7276 10.6623 12.8693 10.6404 13.0076C10.6186 13.1459 10.5584 13.2753 10.4668 13.3812C10.3752 13.4871 10.2558 13.5652 10.1221 13.6068L7.49508 14.4238L6.67809 17.0508C6.63655 17.1845 6.55841 17.3039 6.45252 17.3955C6.34663 17.4871 6.2172 17.5472 6.0789 17.5691C5.94059 17.591 5.79893 17.5737 5.66995 17.5192C5.54097 17.4647 5.42982 17.3752 5.34908 17.2608L3.76109 15.0138L1.01009 15.0488C0.869868 15.0508 0.731879 15.0136 0.611785 14.9411C0.49169 14.8687 0.394301 14.7641 0.330672 14.6392C0.267043 14.5142 0.239725 14.3739 0.251818 14.2342C0.26391 14.0945 0.31493 13.9609 0.399085 13.8488L2.04509 11.6438L1.16108 9.03777C1.11619 8.90526 1.10926 8.76283 1.14107 8.62658C1.17288 8.49034 1.24216 8.36571 1.34109 8.26678C1.44003 8.16785 1.56466 8.09856 1.7009 8.06675C1.83715 8.03494 1.97957 8.04187 2.11208 8.08676L4.71808 8.97077L6.91809 7.32477C7.0491 7.22785 7.20812 7.17625 7.37109 7.17777ZM8.22509 12.6248L6.90008 11.6848C6.80077 11.6146 6.72003 11.5213 6.6648 11.4129C6.60958 11.3045 6.58153 11.1844 6.58309 11.0628L6.60009 9.43677L5.30009 10.4088C5.20266 10.4814 5.08905 10.5293 4.96903 10.5482C4.84901 10.5672 4.72617 10.5568 4.61108 10.5178L3.07109 9.99577L3.59309 11.5358C3.63209 11.6509 3.64255 11.7737 3.62356 11.8937C3.60458 12.0137 3.55671 12.1273 3.48409 12.2248L2.51009 13.5278L4.13609 13.5068C4.2577 13.5052 4.37786 13.5333 4.48622 13.5885C4.59458 13.6437 4.68788 13.7245 4.75808 13.8238L5.70008 15.1528L6.18308 13.5998C6.21916 13.4835 6.28299 13.3778 6.36905 13.2917C6.45512 13.2057 6.56084 13.1418 6.67708 13.1058L8.22509 12.6248Z"
      fill="#4ED7E7"
    />
  </svg>
)
export default LevelIcon
