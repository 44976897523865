import { z } from 'zod'

const ProfileSchema = z.object({
  name: z.string().nonempty('Campo Obrigatório'),
  document: z
    .string()
    .nonempty('Campo Obrigatório')
    .min(11, { message: 'CPF incompleto' }),
  phone: z
    .string()
    .nonempty('Campo Obrigatório')
    .min(11, { message: 'Telefone incompleto' }),
  email: z
    .string()
    .nonempty('Campo Obrigatório')
    .email('Digite um email válido'),
  linkedinProfile: z
    .string()
    .nonempty('Campo Obrigatório')
    .url({ message: 'Linkedin Inválido' }),
})

export default ProfileSchema
