import {
  CandidateJourneyByJobApiInterface,
  JobService,
  PaginationApiInterface,
  SortApiType,
} from '@recrutae/components'
import React from 'react'

import { HttpService } from '~/services/http.service'

interface UseJobJourneyParams {
  id: string
  page?: number
  limit?: number
  sort?: {
    [key: string]: SortApiType | undefined
  }
}

const useCandidateJourneyInJob = ({
  id,
  page = 1,
  limit = 10,
  sort = {},
}: UseJobJourneyParams) => {
  const [loading, setLoading] = React.useState(false)

  const [jobJourneys, setJobJourneys] = React.useState<
    PaginationApiInterface<CandidateJourneyByJobApiInterface>
  >({} as PaginationApiInterface<CandidateJourneyByJobApiInterface>)

  const fetch = React.useCallback(async () => {
    setLoading(true)

    try {
      const http = new HttpService()
      const service = new JobService(http)
      const { data } = await service.getAllCandidatesInJob({
        jobId: id,
        limit,
        sort: sort as any,
        page,
      })

      React.startTransition(() => {
        setJobJourneys(data)
        setLoading(false)
      })
    } catch (e) {
      React.startTransition(() => {
        setJobJourneys(
          {} as PaginationApiInterface<CandidateJourneyByJobApiInterface>
        )
        setLoading(false)
      })
    }
  }, [id, page, limit, sort])

  React.useEffect(() => {
    if (id && !loading) fetch().then()
  }, [fetch])

  return {
    reFetch: fetch,
    jobJourneys: {
      ...jobJourneys,
      data: jobJourneys?.data || [],
      totalItems: jobJourneys?.totalItems || 0,
      totalPages: jobJourneys?.totalPages || 0,
    },
    loading,
  }
}

export default useCandidateJourneyInJob
