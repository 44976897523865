import { LocationService } from '@recrutae/components'
import React from 'react'

import { HttpService } from '~/services/http.service'

export const useCityOptions = (id?: string) => {
  const [loading, setLoading] = React.useState(false)
  const [options, setOptions] = React.useState<
    Array<{ label: string; value: any }>
  >([])

  const fetch = React.useCallback(async () => {
    try {
      setLoading(true)
      const http = new HttpService()
      const service = new LocationService(http)

      const { data } = await service.getCities(id as string)

      React.startTransition(() => {
        setLoading(false)
        setOptions(data.map((city) => ({ value: city.nome, label: city.nome })))
      })
    } catch (e) {
      React.startTransition(() => {
        setLoading(false)
        setOptions([])
      })
    }
  }, [id])

  React.useEffect(() => {
    if (id) fetch().then()
  }, [fetch])

  return { options, loading }
}
