import { Box, BoxProps, Img, Link, Text } from '@chakra-ui/react'
import React from 'react'

import { AddressApiInterface } from '~/model/interfaces/api/common/address.interface'
import { JobWorkApiType } from '~/model/interfaces/api/common/job.interface'
import { jobLevelEnum, JobLevelEnumType } from '~/utils/from-to/level'
import { workTypeEnum } from '~/utils/from-to/worktype'
import { appRoutes } from '~/utils/routes/app-routes'

interface JobSlimCardInterface extends BoxProps {
  id: string
  title: string
  level: string
  address: AddressApiInterface
  imgBackground: string
  imgLogo: string
  workType: JobWorkApiType
  status: string
}

export const JobSlimCard = React.forwardRef<
  HTMLDivElement,
  JobSlimCardInterface
>(
  (
    {
      level,
      title,
      status,
      address,
      imgBackground,
      imgLogo,
      id,
      workType,
      ...props
    },
    ref
  ) => {
    const getFormattedLocation = (address: AddressApiInterface) => {
      const location = [address?.state, address?.city, address?.neighbourhood]
      return location
        .filter((item) => item !== '' && item !== undefined)
        .join(' | ')
    }

    return (
      <Link href={appRoutes.job.detail.url(id)}>
        <Box
          minHeight="288px"
          maxHeight="288px"
          minWidth="233px"
          maxWidth="233px"
          borderRadius="3xl"
          overflow="hidden"
          bgSize="100%"
          bgRepeat="no-repeat"
          bgPos="top"
          mr="18px"
          _last={{ mr: 0 }}
          ref={ref}
          background="#FBFBFC"
          cursor="pointer"
          {...props}
        >
          <Img
            src={`${process.env.REACT_APP_GCP_CLOUD_STORAGE}${imgBackground}`}
            width="233px"
            height="157px"
            objectFit="cover"
            opacity={status === 'Finished' ? 0.3 : 1}
            objectPosition="center"
            loading="lazy"
          />
          <Box width="100%" borderRadius="3xl" p="15px">
            <Img
              src={process.env.REACT_APP_GCP_CLOUD_STORAGE + imgLogo}
              boxSize="30%"
              objectFit="cover"
              minHeight="20px"
              opacity={status === 'Finished' ? 0.5 : 1}
              maxHeight="20px"
              minWidth="48px"
              maxWidth="48px"
              loading="lazy"
            />
            <Text
              fontSize="16px"
              color="primaryDark.500"
              fontWeight="bold"
              noOfLines={2}
              mt="8px"
              width="100%"
              opacity={status === 'Finished' ? 0.5 : 1}
            >
              {title}
            </Text>
            <Text
              fontSize="x-small"
              color="primaryDark.500"
              fontWeight="bold"
              mt="4px"
              opacity={status === 'Finished' ? 0.5 : 1}
            >
              {`${jobLevelEnum[level as JobLevelEnumType]} - ${
                workTypeEnum[workType]
              }`}
            </Text>
            <Text
              fontSize="x-small"
              color="purpleLight.300"
              fontWeight="bold"
              mt="8px"
              opacity={status === 'Finished' ? 0.5 : 1}
            >
              {getFormattedLocation(address)}
            </Text>
          </Box>
        </Box>
      </Link>
    )
  }
)
