import { Button, Flex, Heading, Text, useToast } from '@chakra-ui/react'
import { CandidateJourneyService } from '@recrutae/components'
import { motion } from 'framer-motion'
import React from 'react'

import { CandidateJourneyByJobApiInterface } from '~/model/interfaces/api/job-journey-event.interface'
import { HttpService } from '~/services/http.service'

interface DeclineCandidateInterface {
  candidate?: CandidateJourneyByJobApiInterface
  jobId: string
  setStepVisible: React.Dispatch<React.SetStateAction<number>>
}

const DeclineCandidate = ({
  setStepVisible,
  candidate,
  jobId,
}: DeclineCandidateInterface) => {
  const toast = useToast()

  const handleDecline = React.useCallback(async () => {
    const http = new HttpService()
    const service = new CandidateJourneyService(http)
    try {
      await service.createJourneyEvent(jobId, candidate?.id as string, {
        eventType: 'Displacement',
        date: new Date(),
        message: '',
      })
      toast({
        title: 'Candidato declinado com sucesso',
        status: 'success',
        position: 'top-right',
      })
      setStepVisible(0)
    } catch (e) {
      toast({
        title: 'Erro ao declinar candidato',
        status: 'error',
        position: 'top-right',
        description:
          'Houve um erro ao realizar a ação. Tente novamente mais tarde',
      })
    }
  }, [jobId, candidate])

  return (
    <motion.div
      initial={{ x: 999, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 999, opacity: 0 }}
    >
      <Heading>Atenção!</Heading>
      <Text mt="m2">
        Está ação define que o candidato não está apto para a vaga e não tem
        volta. Deseja continuar?
      </Text>
      <Flex mt="m2">
        <Button onClick={() => setStepVisible(0)}>Cancelar</Button>
        <Button colorScheme="red" ml="m2" onClick={handleDecline}>
          Invalidar candidato
        </Button>
      </Flex>
    </motion.div>
  )
}

export default DeclineCandidate
