import { IconInterface } from '../../../model/interfaces/components/icon.interface'

const JapanIcon = ({
  width = 35.165,
  height = 35.165,
  ...props
}: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 35.165 35.166"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#fff"
      d="M35.769 18.385A17.385 17.385 0 1118.384 1a17.385 17.385 0 0117.385 17.385"
      data-name="Caminho 11525"
      transform="translate(-.801 -.802)"
    ></path>
    <path
      fill="#c9caca"
      d="M17.583 35.166a17.583 17.583 0 1117.583-17.583 17.6 17.6 0 01-17.583 17.583m0-34.77a17.187 17.187 0 1017.186 17.187A17.206 17.206 0 0017.583.4"
      data-name="Caminho 11526"
    ></path>
    <path
      fill="#e60012"
      d="M62.233 53.54a8.693 8.693 0 11-8.693-8.692 8.692 8.692 0 018.693 8.692"
      data-name="Caminho 11527"
      transform="translate(-35.958 -35.958)"
    ></path>
  </svg>
)
export default JapanIcon
