import { Flex, Progress, Text } from '@chakra-ui/react'

export const ProgressBar = ({ percentage = 0 }) => {
  return (
    <Flex flexDir="row" alignItems="center" justifyContent="space-between">
      <Flex flexDir="column" mr="32px" minWidth="fit-content">
        <Text fontSize="sm">Seu perfil está</Text>
        <Text fontSize="md" fontWeight="bold" mb="16px">
          {percentage}% completo
        </Text>
      </Flex>
      <Progress
        value={percentage}
        colorScheme="secondary"
        borderRadius="3xl"
        width="100%"
      />
    </Flex>
  )
}
