import { Flex, Image, Text } from '@chakra-ui/react'

import { ScheduledInterviewHomeCard } from '~/components/cards/interview/scheduled-interview-home-card'
import { useInterviews } from '~/hooks/candidate/use-interviews'

export const ScheduledInterview = () => {
  const { interviews } = useInterviews({})

  return (
    <Flex flexDir="column">
      <Text fontWeight="bold" fontSize="lg">
        Entrevista(s) próximas
      </Text>

      {interviews.length === 0 ? (
        <>
          <Text fontSize="lg" my="32px">
            Você não possui entrevistas agendadas :(
          </Text>
          <Image
            src="./no-interview-scheduled.png"
            alt="no interview scheduled"
            width="100%"
            maxWidth="100%"
          />
        </>
      ) : (
        interviews?.map((interview) => (
          <ScheduledInterviewHomeCard
            key={interview.id}
            jobId={interview.jobId}
            rescheduleLink={interview.calendlyRescheduleLink}
            meetingAppointmentDate={interview.calendlyMeetingAppointmentDate}
          />
        ))
      )}
    </Flex>
  )
}
