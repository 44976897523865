import FormItem from '~/components/form/form-item'
import TitleSteps from '~/components/title-steps/title-steps'

export const Requirements = () => {
  return (
    <>
      <TitleSteps text="Requisitos Técnicos" as="h3" mt="l4" />
      <FormItem
        name="technicalRequirements"
        inputType="textArea"
        placeholder="Requisitos Técnicos"
        minHeight="150px"
      />
      {/*<Skills />*/}
      <TitleSteps text="Requisitos Comportamentais" as="h3" mt="l4" />
      <FormItem
        name="softSkillRequirements"
        inputType="textArea"
        placeholder="Requisitos Comportamentais"
        minHeight="150px"
      />

      <TitleSteps text="Benefícios" as="h3" mt="l4" />
      <FormItem
        name="benefits"
        inputType="textArea"
        placeholder="Benefícios"
        minHeight="150px"
      />
    </>
  )
}
