export const convertTextToList = (
  text: string,
  skip: string
): Array<string> | undefined => {
  let list: Array<string>
  if (text && skip) {
    list = text.split(skip)
    const filtered = list.filter((item) => item !== '' && item !== undefined)
    return filtered
  }
}
