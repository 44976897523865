import { FirebaseApp, getApps, initializeApp } from 'firebase/app'
import {
  Auth,
  browserLocalPersistence,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  User,
} from 'firebase/auth'

import { firebaseConfig } from '~/config/firebase.config'
import { UserStoreInterface } from '~/model/store/user-store.interface'
import { HttpService } from '~/services/http.service'

export class AuthService extends HttpService {
  public app: FirebaseApp
  public auth: Auth

  constructor() {
    super()
    this.app =
      getApps().length > 0 ? getApps()[0] : initializeApp(firebaseConfig)
    this.auth = getAuth()
    this.auth.setPersistence(browserLocalPersistence).then()
  }

  public static async transformUser(user: User): Promise<UserStoreInterface> {
    return {
      userId: user.uid,
      name: user.displayName as string,
      email: user.email as string,
      profileImage: null,
    }
  }

  async signInEmailAndPassword(email: string, password: string) {
    try {
      const { user } = await signInWithEmailAndPassword(
        this.auth,
        email,
        password
      )

      this.auth.tenantId = '636d3dd93c40fe4922399b95'

      const data = await this.auth.currentUser?.getIdTokenResult()

      if (!data?.claims?.recruiter) throw new Error('user is not a recruiter')

      return AuthService.transformUser(user)
    } catch (e) {
      await this.logout()

      return Promise.reject(e)
    }
  }

  async resetPassword(email: string): Promise<void> {
    try {
      await sendPasswordResetEmail(this.auth, email)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async logout() {
    await signOut(this.auth)
  }

  async validateToken() {
    try {
      const token = await this.auth.currentUser?.getIdToken()

      if (token) {
        const { data } = await this.createApiRequest.get(`account/${token}`)

        return data
      }

      return null
    } catch (e) {
      console.log(e)
      return null
    }
  }
}
