export const appRoutes = {
  login: { url: () => '/' },
  forgot: { url: () => '/forgot' },
  home: { url: () => '/home' },
  search: { url: () => '/search' },
  profile: { url: () => '/profile' },
  policyPrivacy: { url: () => '/policy-privacy' },
  cookies: { url: () => '/policy-privacy#cookies' },
  job: {
    clientView: { url: (id: string) => `/job/${id}/clientView` },
    detail: { url: (id: string) => `/job/${id}/detail` },
    edit: { url: (id: string) => `/job/${id}` },
    create: { url: () => `/job/new` },
  },
}
