import { Button, Container, Flex, Stack, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import NotFoundImg from '~/assets/img/not-found.img'
import { appRoutes } from '~/utils/routes/app-routes'

const NotFound = () => {
  return (
    <Container>
      <Flex w="100%" h="100%" m="50px">
        <NotFoundImg height={360} width={480} />
        <Stack alignItems="center" justifyContent="center" w="50%">
          <Text fontSize="2xl" textAlign="center">
            A página que você procura <br />
            <b>não foi encontrada</b>.
          </Text>
          <Stack>
            <Button
              variant="outline"
              width="250px"
              my="40px"
              as={Link}
              to={appRoutes.home.url()}
              colorScheme="secondary"
              color="primaryDark.500"
              borderRadius="3xl"
              height="50px"
              borderColor="secondary.500"
              border="2px"
            >
              Voltar
            </Button>
          </Stack>
        </Stack>
      </Flex>
    </Container>
  )
}

export default NotFound
