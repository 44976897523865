import { Tab } from '@chakra-ui/react'

interface TabGuideMenuInterface {
  title: string
}

const TabTitleGuideMenu = ({ title }: TabGuideMenuInterface) => {
  return (
    <Tab
      padding="20px"
      _selected={{
        color: 'secondary.600',
        fontWeight: 'bold',
        borderBottom: '4px',
      }}
    >
      {title}
    </Tab>
  )
}

export default TabTitleGuideMenu
