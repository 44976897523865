import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'

import WorkTypeIcon from '~/assets/icons/work-type.icon'
import BarChart from '~/components/charts/bar/bar.chart'
import {
  ScoreApiInterface,
  ScoreData,
} from '~/model/interfaces/api/score.interface'

interface ScoreInterface {
  score: ScoreApiInterface
  maxScore: number
}

export const ScoreCandidate = ({ score, maxScore }: ScoreInterface) => {
  const chartData = React.useMemo(() => {
    const scoreCandidateFormatted = score.scores?.map(
      (scoreData: ScoreData) => {
        return {
          label: scoreData.score.toString(),
          total: scoreData.candidateQuantity,
        }
      }
    )
    return scoreCandidateFormatted
  }, [score])

  return (
    <Box background="black.100" borderRadius="16px" padding="m4">
      <Heading fontSize="h-sm">Desempenho dos candidatos</Heading>
      <Text mt="8px">
        Total de candidatos: {score.totalAppliedCandidates || 0}
      </Text>
      <Text mb="m4">Score máximo da vaga: {maxScore}</Text>
      {chartData === undefined ? (
        <Flex w="100%" alignItems="center" flexDir="column" gap="24px">
          <Text fontSize="lg"> Ainda não há dados para serem analisados.</Text>
          <WorkTypeIcon width={160} height={160} color="#201D8A" />
        </Flex>
      ) : (
        <BarChart data={chartData} />
      )}
    </Box>
  )
}
