import { JobApiInterface } from '~/model/interfaces/api/job.interface'
import { JobSchemaInterface } from '~/model/schemas/job/job.schema.interface'
import { UserStoreInterface } from '~/model/store/user-store.interface'

export const transformJobDefaultValues = (job: JobApiInterface) => ({
  jobName: job.jobName || '',
  jobDescription: job.jobDescription || '',
  level: job.level || '',
  contractTypes: job.contractTypes || [],
  workType: job.workType || '',
  benefits: job.benefits || '',
  status: job.status || 'Draft',
  softSkillRequirements: job.softSkillRequirements || '',
  technicalRequirements: job.technicalRequirements || '',
  minPrice: job?.minPaymentRange?.toString() || '0',
  maxPrice: job?.maxPaymentRange?.toString() || '0',
  companyName: job.companyName || '',
  companyRecruiterName: job.companyRecruiterName || '',
  companyRecruiterEmail: job.companyRecruiterEmail || '',
  companyRecruiterPhone: job.companyRecruiterPhone || '',
  companyVideoUrl: job.companyVideoUrl || '',
  recruitingDetails: job.recruitingDetails || '',
  typeFormId: job.typeFormId || '',
  endingDateJobApplication: job.endingDateJobApplication || '',
  address: {
    city: job?.jobAddress?.city || '',
    state: job?.jobAddress?.state || '',
    neighbourhood: job?.jobAddress?.neighbourhood || '',
  },
  languages: job.languages || [],
  cardImageLink: job.cardImageLink || '',
  profileImageLink: job.profileImageLink || '',
})

export const transformDataToFormData = (
  values: JobSchemaInterface,
  user?: UserStoreInterface
) => {
  const formData = new FormData()

  formData.append(
    'jobRequest',
    JSON.stringify({
      ...values,
      endingDateJobApplication: undefined,
      recruiterId: user?.userId,
      jobAddress: values.address,
      minPaymentRange: Number(values.minPrice),
      maxPaymentRange: Number(values.maxPrice),
      cardImageLink: undefined,
      profileImageLink: undefined,
    })
  )

  if (values.cardImageLink && typeof values.cardImageLink === 'object')
    formData.append('cardImage', values.cardImageLink)
  if (values.profileImageLink && typeof values.cardImageLink === 'object')
    formData.append('profileImage', values.profileImageLink)

  return formData
}
