import { Box, Flex, Text } from '@chakra-ui/react'
import { format } from 'date-fns'

import MessageViewer from '~/assets/icons/message-view.icon'

interface SentMessageChatInterface {
  message: string
  date: Date
}

const SentMessageChat = ({ message, date }: SentMessageChatInterface) => {
  return (
    <Flex width="100%" justifyContent="flex-end">
      <Box
        m="20px"
        backgroundColor="#CAFE8B"
        borderRadius="24px"
        w="360px"
        p="12px 16px"
      >
        <Text>{message}</Text>
        <Flex justifyContent="end" gap="5px">
          <Text fontSize="smaller">
            {format(new Date(date), `dd/MM/yyyy kk:mm`)}
          </Text>
          <MessageViewer height={15} width={15} />
        </Flex>
      </Box>
    </Flex>
  )
}

export default SentMessageChat
