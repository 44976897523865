import { AnalyticsService, JobScoreApiInterface } from '@recrutae/components'
import React from 'react'

import { ScoreApiInterface } from '~/model/interfaces/api/score.interface'
import { HttpService } from '~/services/http.service'

export const useCandidateScore = (jobId: string) => {
  const [loading, setLoading] = React.useState(false)
  const [score, setScore] = React.useState<JobScoreApiInterface>(
    {} as JobScoreApiInterface
  )

  const fetch = React.useCallback(async () => {
    setLoading(true)

    try {
      const http = new HttpService()
      const service = new AnalyticsService(http)
      const { data } = await service.getTotalCandidateInJobByScore(jobId)
      React.startTransition(() => {
        setScore(data)
        setLoading(false)
      })
    } catch (e) {
      React.startTransition(() => {
        setScore({} as ScoreApiInterface)
        setLoading(false)
      })
    }
  }, [])

  React.useEffect(() => {
    fetch().then()
  }, [fetch])

  return { score, loading }
}
