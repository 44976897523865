import {
  Box,
  Button,
  Container,
  Flex,
  Img,
  Text,
  useToast,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import LogoCompanyImg from '~/assets/img/logo-company.img'
import FormItem from '~/components/form/form-item'
import { withAlreadyAuthenticated } from '~/HOC/with-already-authenticated'
import {
  LoginFormInterface,
  loginSchema,
} from '~/model/schemas/auth/login.schema'
import { useAuth } from '~/store/auth.context'

const LoginScreen = () => {
  const { signIn } = useAuth()
  const navigate = useNavigate()
  const toast = useToast()

  const formActions = useForm<LoginFormInterface>({
    defaultValues: { email: '', password: '' },
    resolver: zodResolver(loginSchema),
  })

  const onSubmit = React.useCallback(async (values: LoginFormInterface) => {
    try {
      await signIn(values)
      navigate('/home')
    } catch (e) {
      toast({
        position: 'top-right',
        title: 'Login inválido',
        description: 'Usuário ou senha incorretos',
        status: 'error',
      })
    }
  }, [])

  return (
    <FormProvider {...formActions}>
      <Container w="100%" minWidth="100%" m={0} p={0}>
        <Flex maxHeight="100vh" minHeight="100vh" overflow="hidden">
          <Box width="50%" display="block" background="primaryLight.500">
            <Img
              objectFit="cover"
              h="100%"
              src="/background.png"
              alt="background na tela de login"
            />
          </Box>
          <Box
            width="50%"
            p="56px 140px"
            as="form"
            onSubmit={formActions.handleSubmit(onSubmit)}
          >
            <LogoCompanyImg width={140} />
            <Text fontSize="2xl" my="48px">
              Acesse com seu login
            </Text>
            <FormItem
              label="E-mail"
              type="email"
              inputType="text"
              name="email"
              spacing={{ mt: '30px' }}
            />
            <FormItem
              label="Senha"
              inputType="password"
              name="password"
              spacing={{ mt: '30px' }}
            />
            <Flex justifyContent="flex-end" mt="12px">
              <Button
                variant="link"
                colorScheme="secondary"
                size="sm"
                fontSize="xs"
              >
                Esqueceu sua senha?
              </Button>
            </Flex>
            <Button
              borderRadius="2xl"
              fontSize="sm"
              colorScheme="secondary"
              py="25px"
              width={['100%']}
              mt="40px"
              fontWeight="bold"
              type="submit"
              isLoading={formActions.formState.isSubmitting}
            >
              Login
            </Button>
            <Flex justifyContent="center" gap="5px" mt="100px">
              <Text fontSize="x-small">
                © Recrutaê 2022. Todos os direitos reservados.
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </FormProvider>
  )
}

export default withAlreadyAuthenticated(LoginScreen)
