import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
} from 'date-fns'

export const formatTimeDifference = (start: Date, end: Date) => {
  const validations = {
    years: differenceInYears(end, start),
    months: differenceInMonths(end, start),
    days: differenceInDays(end, start),
    hours: differenceInHours(end, start),
    minutes: differenceInMinutes(end, start),
    seconds: differenceInSeconds(end, start),
  }

  if (validations.years) return `${validations.years} ano(s)`
  if (validations.months) return `${validations.months} mês(es)`
  if (validations.days) return `${validations.days} dia(s)`
  if (validations.hours) return `${validations.hours} horas`
  if (validations.minutes) return `${validations.minutes} minuto(s)`

  return `${validations.seconds} segundo(s)`
}
