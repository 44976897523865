import { Button, Container, Flex, useToast } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { JobService } from '@recrutae/components'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { withAuth } from '~/HOC/with-auth'
import { useJob } from '~/hooks/jobs/use-job'
import { jobSchema } from '~/model/schemas/job/job.schema'
import { JobSchemaInterface } from '~/model/schemas/job/job.schema.interface'
import { transformJobDefaultValues } from '~/screens/job/register/transform-default-values'
import { HttpService } from '~/services/http.service'
import { useAuth } from '~/store/auth.context'

import { HeaderJobs } from './components/header-jobs'
import { JobDescription } from './components/job-description'
import { Languages } from './components/languages'
import { Localization } from './components/localization'
import { Requirements } from './components/requirements'
import { Status } from './components/status'
import { TypeJob } from './components/type-job'

const RegisterJobsScreen = () => {
  const { id } = useParams()
  const { user } = useAuth()
  const toast = useToast()
  const navigate = useNavigate()

  const { job, loading: jobLoading } = useJob({ id: id as string })

  const formMethods = useForm<JobSchemaInterface>({
    defaultValues: transformJobDefaultValues(job),
    resolver: zodResolver(jobSchema),
  })

  React.useEffect(() => {
    const values = transformJobDefaultValues(job)
    formMethods.reset(values)
  }, [job])

  const onSubmit = React.useCallback(
    async (values: JobSchemaInterface) => {
      const http = new HttpService()
      const service = new JobService(http)

      const payload = {
        ...values,
        recruiterId: user?.userId || '',
        jobAddress: values.address,
        minPaymentRange: Number(values.minPrice),
        maxPaymentRange: Number(values.maxPrice),
      }

      if (id) {
        try {
          await service.update(
            id,
            payload,
            values.cardImageLink,
            values.profileImageLink
          )

          navigate(`/job/${id}/detail`)

          toast({
            title: 'Vaga atualizada com sucesso',
            description:
              'em instantes você será redicionado para a visualização da vaga como cliente',
            position: 'top-right',
            status: 'success',
          })
        } catch (e) {
          toast({
            title: 'Erro ao editar vaga',
            description:
              'Houve algum problema interno durante a edição da vaga',
            position: 'top-right',
            status: 'error',
          })
        }
      } else {
        try {
          const { data } = await service.create(
            payload,
            values.cardImageLink,
            values.profileImageLink
          )
          navigate(`/job/${data.id}/detail`)

          toast({
            title: 'Vaga criada com sucesso',
            description: 'em instantes você será redicionado para a home',
            position: 'top-right',
            status: 'success',
          })
        } catch (e) {
          toast({
            title: 'Erro ao criar vaga',
            description:
              'Houve algum problema interno durante a criação da vaga',
            position: 'top-right',
            status: 'error',
          })
        }
      }
    },
    [id, user]
  )

  return (
    <FormProvider {...formMethods}>
      <Container as="form" onSubmit={formMethods.handleSubmit(onSubmit)}>
        {id ? (
          <HeaderJobs
            title="Editar Vaga"
            subtitle="Acrescente, exclua ou edite informações sobre a vaga."
            job={job}
          />
        ) : (
          <HeaderJobs
            title="Nova Vaga"
            subtitle="Inicie o processo de seleção por aqui"
          />
        )}
        <JobDescription job={job} />
        <Requirements />
        <Languages />
        <TypeJob job={job} jobLoading={jobLoading} />
        <Localization job={job} />
        <Status />
        <Flex mt="60px" justifyContent="center" gap="36px">
          <Button
            variant="outline"
            width="250px"
            colorScheme="secondary"
            size="lg"
            as={Link}
            to={job.id ? `/job/${job.id}/detail` : '/home'}
          >
            Voltar
          </Button>
          <Button
            variant="solid"
            size="lg"
            width="250px"
            colorScheme="secondary"
            type="submit"
          >
            Salvar
          </Button>
        </Flex>
      </Container>
    </FormProvider>
  )
}

export default withAuth(RegisterJobsScreen)
