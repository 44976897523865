import { LocationService } from '@recrutae/components'
import React from 'react'

import { HttpService } from '~/services/http.service'

export const useStateOptions = () => {
  const [loading, setLoading] = React.useState(false)
  const [options, setOptions] = React.useState<
    Array<{ label: string; value: any }>
  >([])

  const fetch = React.useCallback(async () => {
    try {
      setLoading(true)
      const http = new HttpService()
      const service = new LocationService(http)

      const { data } = await service.getStates()

      React.startTransition(() => {
        setLoading(false)
        setOptions(
          data.map((state) => ({ value: state.sigla, label: state.nome }))
        )
      })
    } catch (e) {
      React.startTransition(() => {
        setLoading(false)
        setOptions([])
      })
    }
  }, [])

  React.useEffect(() => {
    fetch().then()
  }, [])

  return { options, loading }
}
