import { IconInterface } from '../../model/interfaces/components/icon.interface'

const PhotoIcon = ({
  width = 20,
  height = 16,
  color = '#fff',
  ...props
}: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.96 4.52734C11.1839 4.52734 10.4252 4.75748 9.77994 5.18866C9.13465 5.61983 8.6317 6.23267 8.3347 6.94969C8.0377 7.66671 7.96 8.4557 8.1114 9.21688C8.26281 9.97806 8.63654 10.6773 9.18532 11.226C9.7341 11.7748 10.4333 12.1485 11.1945 12.2999C11.9556 12.4514 12.7446 12.3736 13.4617 12.0766C14.1787 11.7797 14.7915 11.2767 15.2227 10.6314C15.6539 9.9861 15.884 9.22744 15.884 8.45134C15.8827 7.41104 15.4688 6.41372 14.7332 5.67812C13.9976 4.94251 13.0003 4.52867 11.96 4.52734ZM11.96 11.4633C11.3643 11.4633 10.7819 11.2867 10.2866 10.9557C9.79131 10.6248 9.40525 10.1544 9.17728 9.60398C8.9493 9.05361 8.88966 8.448 9.00587 7.86373C9.12209 7.27946 9.40896 6.74277 9.8302 6.32154C10.2514 5.9003 10.7881 5.61343 11.3724 5.49722C11.9567 5.381 12.5623 5.44065 13.1126 5.66862C13.663 5.89659 14.1334 6.28264 14.4644 6.77797C14.7953 7.27329 14.972 7.85563 14.972 8.45134C14.9709 9.24985 14.6533 10.0153 14.0886 10.58C13.524 11.1446 12.7585 11.4623 11.96 11.4633Z"
      fill={color}
    />
    <path
      d="M11.96 11.4633C11.3643 11.4633 10.7819 11.2867 10.2866 10.9557C9.79131 10.6248 9.40525 10.1544 9.17728 9.60398C8.9493 9.05361 8.88966 8.448 9.00587 7.86373C9.12209 7.27946 9.40896 6.74277 9.8302 6.32154C10.2514 5.9003 10.7881 5.61343 11.3724 5.49722C11.9567 5.381 12.5623 5.44065 13.1126 5.66862C13.663 5.89659 14.1334 6.28264 14.4644 6.77797C14.7953 7.27329 14.972 7.85563 14.972 8.45134C14.9709 9.24985 14.6533 10.0153 14.0886 10.58C13.524 11.1446 12.7585 11.4623 11.96 11.4633M11.96 4.52734C11.1839 4.52734 10.4252 4.75748 9.77994 5.18866C9.13465 5.61983 8.6317 6.23267 8.3347 6.94969C8.0377 7.66671 7.96 8.4557 8.1114 9.21688C8.26281 9.97806 8.63654 10.6773 9.18532 11.226C9.7341 11.7748 10.4333 12.1485 11.1945 12.2999C11.9556 12.4514 12.7446 12.3736 13.4617 12.0766C14.1787 11.7797 14.7915 11.2767 15.2227 10.6314C15.6539 9.9861 15.884 9.22744 15.884 8.45134C15.8827 7.41104 15.4688 6.41372 14.7332 5.67812C13.9976 4.94251 13.0003 4.52867 11.96 4.52734Z"
      stroke={color}
      strokeWidth="0.5"
    />
    <path
      d="M7.192 3.59424H3.392V3.62424C3.26571 3.66252 3.15502 3.74029 3.07619 3.84612C2.99735 3.95195 2.95453 4.08027 2.954 4.21224V5.48924C2.954 5.65314 3.01911 5.81033 3.13501 5.92623C3.25091 6.04213 3.4081 6.10724 3.572 6.10724H7.193C7.3569 6.10724 7.5141 6.04213 7.62999 5.92623C7.74589 5.81033 7.811 5.65314 7.811 5.48924V4.21224C7.81074 4.04842 7.74554 3.89138 7.6297 3.77554C7.51386 3.6597 7.35682 3.5945 7.193 3.59424H7.192ZM6.936 5.22724H3.836V4.46824H6.936V5.22724Z"
      fill={color}
      stroke={color}
      strokeWidth="0.5"
    />
    <path
      d="M18.227 1.56212H9.1C9.06979 1.22516 8.91461 0.911679 8.66498 0.683328C8.41535 0.454976 8.08932 0.328271 7.751 0.328125L5.007 0.328125C4.66868 0.328271 4.34265 0.454976 4.09302 0.683328C3.84339 0.911679 3.68821 1.22516 3.658 1.56212H3.085C2.53209 1.56292 2.00208 1.78298 1.61121 2.17404C1.22034 2.5651 1.00053 3.09522 1 3.64812L1 13.6211C1.00079 14.1739 1.22072 14.7037 1.61156 15.0946C2.0024 15.4854 2.53227 15.7053 3.085 15.7061H18.227C18.7797 15.7053 19.3096 15.4854 19.7004 15.0946C20.0913 14.7037 20.3112 14.1739 20.312 13.6211V3.64812C20.3112 3.09539 20.0913 2.56553 19.7004 2.17468C19.3096 1.78384 18.7797 1.56392 18.227 1.56313V1.56212ZM19.435 13.6211C19.4342 13.9412 19.3066 14.2479 19.0802 14.4741C18.8538 14.7003 18.547 14.8276 18.227 14.8281H3.085C2.76478 14.8276 2.45783 14.7002 2.2314 14.4737C2.00497 14.2473 1.87753 13.9403 1.877 13.6201V3.64812C1.87726 3.32773 2.00459 3.02052 2.23105 2.79388C2.4575 2.56723 2.76461 2.43965 3.085 2.43913H4.53V1.68012C4.53026 1.55379 4.58064 1.43272 4.67006 1.34348C4.75949 1.25424 4.88066 1.20412 5.007 1.20412H7.751C7.87716 1.20439 7.99808 1.25462 8.08729 1.34383C8.1765 1.43304 8.22674 1.55396 8.227 1.68012V2.43913H18.227C18.5476 2.43939 18.8549 2.56685 19.0816 2.79353C19.3083 3.0202 19.4357 3.32756 19.436 3.64812L19.435 13.6211Z"
      fill={color}
      stroke={color}
      strokeWidth="0.5"
    />
  </svg>
)
export default PhotoIcon
