import { IconInterface } from '../../../model/interfaces/components/icon.interface'

const ItalianIcon = ({
  width = 35.165,
  height = 35.165,
  ...props
}: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 35.165 35.166"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#fff"
      d="M58.447 1.026v33.112a17.651 17.651 0 0011.8.027V1a17.654 17.654 0 00-11.8.022"
      data-name="Caminho 11520"
      transform="translate(-46.727)"
    ></path>
    <path
      fill="#00913a"
      d="M11.72 5v33.16a17.588 17.588 0 010-33.16"
      data-name="Caminho 11521"
      transform="translate(0 -4.001)"
    ></path>
  </svg>
)
export default ItalianIcon
