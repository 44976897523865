import { HttpServiceInterface } from '@recrutae/components'
import axios, { Axios, AxiosRequestConfig } from 'axios'

import { AuthService } from '~/services/api/auth.service'

export class HttpService implements HttpServiceInterface {
  createApiRequest: Axios

  constructor() {
    this.createApiRequest = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
    this.createApiRequest.interceptors.request.use(
      async (config) => await this.interceptRequest(config)
    )
    this.createApiRequest.interceptors.response.use(
      (response) => response,
      async (error) => await this.interceptResponseError(error)
    )
  }

  async interceptResponseError(error: any) {
    if (error?.response?.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken')
      try {
        throw new Error('not implemented refresh token')
        //refresh token and resend request
        const config = {
          ...error.config,
          headers: { ...error.config.headers, Authorization: '' },
        }
        return await axios.request(config)
      } catch (e) {
        const service = new AuthService()
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
        await service.logout()
        return Promise.reject(error)
      }
    }

    return Promise.reject(error)
  }

  async interceptRequest(
    config: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> {
    const token = localStorage.getItem('token')
    const tenantId =
      localStorage.getItem('tenantId') || '636d3dd93c40fe4922399b95'

    const header = token ? { Authorization: token, 'tenant-id': tenantId } : {}
    return { ...config, headers: { ...config.headers, ...header } }
  }
}
