import { Button, Text } from '@chakra-ui/react'
import { DataTable } from '@recrutae/components'
import { format } from 'date-fns'
import { motion } from 'framer-motion'
import React from 'react'

import { JobJourneyApiInterface } from '~/model/interfaces/api/job-journey.interface'
import { JobJourneyEventHistoryApiInterface } from '~/model/interfaces/api/job-journey-event.interface'
import {
  candidateStatusFormatter,
  CandidateStatusFormatterType,
} from '~/utils/from-to/candidate-status.formatter'

const CandidateHistoryList = ({
  setStepVisible,
  journey,
}: {
  setStepVisible: React.Dispatch<React.SetStateAction<number>>
  journey: JobJourneyApiInterface
}) => {
  return (
    <motion.div
      initial={{ x: 999, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 999, opacity: 0 }}
    >
      <DataTable<JobJourneyEventHistoryApiInterface>
        data={journey.eventHistory || []}
        columns={[
          {
            headerLabel: 'Evento',
            selector: ({ eventType }) => (
              <Text fontSize="t-sm">
                {
                  candidateStatusFormatter[
                    eventType as CandidateStatusFormatterType
                  ]
                }
              </Text>
            ),
          },
          {
            headerLabel: 'Data',
            selector: ({ date }) => (
              <Text fontSize="t-sm">
                {format(new Date(date), 'dd/MM/yyyy')}
              </Text>
            ),
          },
          {
            headerLabel: 'Via',
            selector: ({ by }) => <Text fontSize="t-sm">{by}</Text>,
          },
          {
            headerLabel: 'Mensagem',
            selector: ({ message }) => (
              <Text fontSize="t-sm">{message || 'N/A'}</Text>
            ),
          },
        ]}
        withPagination={false}
      />
      <Button onClick={() => setStepVisible(0)} colorScheme="primary" mt="m3">
        Voltar
      </Button>
    </motion.div>
  )
}

export default CandidateHistoryList
