import { IconInterface } from '../../model/interfaces/components/icon.interface'

const HomeCardImg = ({
  width = 359.33,
  height = 255.5,
  ...props
}: IconInterface) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 359.33 255.5"
    {...props}
  >
    <g data-name="Grupo 9377" transform="translate(-788.973 -169.25)">
      <g data-name="Grupo 9378" transform="translate(20043.699 8110.35)">
        <path
          fill="#ffabb3"
          d="M0 0H36.272V49.937H0z"
          data-name="Retângulo 3607"
          transform="translate(-19005.889 -7795.748)"
        ></path>
        <path
          fill="#ffd821"
          d="M328.867 217.85l-20.424-44.229a68.354 68.354 0 00-120.25-7.2l-31.715 51.473z"
          data-name="Caminho 12119"
          transform="translate(-19225.477 -7904.705)"
        ></path>
        <path
          fill="#36174d"
          d="M309.915 219.321a1.21 1.21 0 01-1.1-.7l-20.424-44.23a67.344 67.344 0 00-60.957-38.992 1.211 1.211 0 110-2.423 69.774 69.774 0 0163.156 40.4l20.424 44.224a1.212 1.212 0 01-.593 1.608 1.2 1.2 0 01-.506.112"
          data-name="Caminho 12120"
          transform="translate(-19206.521 -7904.963)"
        ></path>
        <path
          fill="#fff"
          d="M0 0H158.957V191.55H0z"
          data-name="Retângulo 3608"
          transform="translate(-19208.066 -7924.921)"
        ></path>
        <path
          fill="#efefef"
          d="M0 0H158.957V12.926H0z"
          data-name="Retângulo 3609"
          transform="translate(-19208.066 -7924.921)"
        ></path>
        <path
          fill="#36174d"
          d="M206.343 205.741H47.386a1.211 1.211 0 01-1.212-1.211V12.979a1.211 1.211 0 011.212-1.211h158.957a1.21 1.21 0 011.211 1.211V204.53a1.21 1.21 0 01-1.211 1.211M48.6 203.318h156.531V14.189H48.6z"
          data-name="Caminho 12121"
          transform="translate(-19255.451 -7937.901)"
        ></path>
        <path
          fill="#efefef"
          d="M0 0H89.71V7.295H0z"
          data-name="Retângulo 3610"
          transform="translate(-19164.221 -7891.309)"
        ></path>
        <path
          fill="#ffd821"
          d="M78.568 43.244a5.734 5.734 0 11-5.734-5.734 5.734 5.734 0 015.734 5.734"
          data-name="Caminho 12122"
          transform="translate(-19249.766 -7930.906)"
        ></path>
        <path
          fill="#efefef"
          d="M0 0H89.71V7.295H0z"
          data-name="Retângulo 3611"
          transform="translate(-19164.221 -7868.363)"
        ></path>
        <path
          fill="#ffd821"
          d="M78.568 61.287a5.734 5.734 0 11-5.734-5.733 5.734 5.734 0 015.734 5.733"
          data-name="Caminho 12123"
          transform="translate(-19249.766 -7926.002)"
        ></path>
        <path
          fill="#efefef"
          d="M0 0H89.71V7.295H0z"
          data-name="Retângulo 3612"
          transform="translate(-19164.221 -7845.417)"
        ></path>
        <path
          fill="#50dbeb"
          d="M78.568 79.33a5.734 5.734 0 11-5.735-5.73 5.734 5.734 0 015.734 5.733"
          data-name="Caminho 12124"
          transform="translate(-19249.766 -7921.099)"
        ></path>
        <path
          fill="#efefef"
          d="M0 0H89.71V7.295H0z"
          data-name="Retângulo 3613"
          transform="translate(-19164.221 -7822.47)"
        ></path>
        <path
          fill="#50dbeb"
          d="M78.568 97.373a5.734 5.734 0 11-5.734-5.733 5.735 5.735 0 015.734 5.733"
          data-name="Caminho 12125"
          transform="translate(-19249.766 -7916.196)"
        ></path>
        <path
          fill="#36174d"
          d="M85.941 136.572a27.736 27.736 0 01-4.8-.418 14.7 14.7 0 01-4.646-1.5 9.193 9.193 0 01-5.029 1.3 6.551 6.551 0 01-3.363-.974 1.211 1.211 0 111.315-2.035 4.253 4.253 0 002.139.589A6.69 6.69 0 0074.4 133a6.068 6.068 0 01-1.7-4.558 4.829 4.829 0 012.746-4.043 3.383 3.383 0 014.093.53c1.422 1.5 1.353 4.291.474 6.015a8.91 8.91 0 01-1.426 2.03 14.7 14.7 0 002.973.795c7.859 1.38 15.452-.773 24.625-6.979a3.569 3.569 0 014.616.707 1.211 1.211 0 11-1.88 1.527 1.164 1.164 0 00-1.38-.229c-5.146 3.482-12.662 7.778-21.6 7.778m-8.671-10.18a2.127 2.127 0 00-.839.22 2.412 2.412 0 00-1.314 2.013 3.893 3.893 0 001.357 3.031 6.519 6.519 0 001.381-1.812 3.356 3.356 0 00-.075-3.248.629.629 0 00-.51-.2"
          data-name="Caminho 12126"
          transform="translate(-19249.643 -7907.409)"
        ></path>
        <path
          fill="#f6b399"
          d="M177.934 203.87s1.4-15.734-5.011-35.19c-6.993-21.212-28.49-30.52-52.713-36.122 0 0-4.107 24.358-2.016 29.315s59.74 42 59.74 42"
          data-name="Caminho 12127"
          transform="translate(-19236.041 -7905.076)"
        ></path>
        <path
          fill="#36174d"
          d="M124.7 130.28l69.63 53.02A12.65 12.65 0 01179 203.433l-69.628-53.026a5.658 5.658 0 01-1.862-2.49l-4.7-12.369a5.656 5.656 0 015.818-7.641l13.173 1.241a5.662 5.662 0 012.9 1.132"
          data-name="Caminho 12128"
          transform="translate(-19240.162 -7906.347)"
        ></path>
        <path
          fill="#724fff"
          d="M158.893 188.734l4.767 3.63a12.65 12.65 0 1015.329-20.127l-4.768-3.631z"
          data-name="Caminho 12129"
          transform="translate(-19224.82 -7895.279)"
        ></path>
        <path
          fill="#36174d"
          d="M102.629 126.636l5.471 4.164-2.46 3.23-5.475-4.17a2.03 2.03 0 112.46-3.229"
          data-name="Caminho 12130"
          transform="translate(-19240.996 -7906.798)"
        ></path>
        <path
          fill="#ffd821"
          d="M107.541 150.784a1.211 1.211 0 01-.963-1.945l15.266-20.048a1.211 1.211 0 011.927 1.468L108.5 150.307a1.208 1.208 0 01-.964.477"
          data-name="Caminho 12131"
          transform="translate(-19239.104 -7906.229)"
        ></path>
        <path
          fill="#36174d"
          d="M226.843 48.334s-44.037.1-50.757 39.711c-7.456 43.963 46.922 49.7 46.922 49.7z"
          data-name="Caminho 12132"
          transform="translate(-19220.338 -7927.964)"
        ></path>
        <path
          fill="#36174d"
          d="M186.068 93.428a44.343 44.343 0 1043.205-45.451 44.343 44.343 0 00-43.205 45.451"
          data-name="Caminho 12133"
          transform="translate(-19217.438 -7928.065)"
        ></path>
        <path
          fill="#f6b399"
          d="M181.425 101.074c0 31.122 7.4 39.622 38.818 39.622 31.241 0 36.307-18.877 36.307-39.622a37.563 37.563 0 00-37.563-37.563c-20.745 0-37.563 14.415-37.563 37.563"
          data-name="Caminho 12134"
          transform="translate(-19218.695 -7923.84)"
        ></path>
        <path
          fill="#36174d"
          d="M205.938 90.178a2.148 2.148 0 10-2.148 2.148 2.148 2.148 0 002.148-2.148"
          data-name="Caminho 12135"
          transform="translate(-19213.201 -7917.177)"
        ></path>
        <path
          fill="#36174d"
          d="M188.491 90.178a2.148 2.148 0 10-2.148 2.148 2.148 2.148 0 002.148-2.148"
          data-name="Caminho 12136"
          transform="translate(-19217.943 -7917.177)"
        ></path>
        <path
          fill="#36174d"
          d="M226.959 51.919L250.7 74.725l.926 26.857a27.732 27.732 0 11-24.666-49.663"
          data-name="Caminho 12137"
          transform="translate(-19210.65 -7926.99)"
        ></path>
        <path
          fill="#ee796a"
          d="M197.295 86.291a1.211 1.211 0 01-.8-2.119c8.347-7.37 17.25-.41 17.339-.338a1.211 1.211 0 01-1.511 1.894c-.3-.238-7.419-5.748-14.225.259a1.206 1.206 0 01-.8.3"
          data-name="Caminho 12138"
          transform="translate(-19214.713 -7919.144)"
        ></path>
        <path
          fill="#ee796a"
          d="M190.036 102.522a1.212 1.212 0 01-1.05-1.814c.027-.048 2.742-4.867 1.814-9.391-1.09-5.318-4.5-7.964-10.421-8.087a1.211 1.211 0 01.05-2.421c7.024.145 11.431 3.612 12.744 10.023 1.115 5.434-1.956 10.856-2.086 11.083a1.211 1.211 0 01-1.05.607"
          data-name="Caminho 12139"
          transform="translate(-19219.303 -7919.139)"
        ></path>
        <path
          fill="#ff8398"
          d="M221.122 104.14a10.685 10.685 0 10-10.684 10.684 10.684 10.684 0 0010.684-10.684"
          data-name="Caminho 12140"
          opacity="0.5"
          transform="translate(-19213.715 -7915.702)"
        ></path>
        <path
          fill="#ff8398"
          d="M181.452 95.661a86 86 0 001.278 14.37 10.643 10.643 0 00-1.278-14.37"
          data-name="Caminho 12141"
          opacity="0.5"
          transform="translate(-19218.689 -7915.103)"
        ></path>
        <path
          fill="#724fff"
          d="M22.559 79.737l9.212 16.769-15.1-10.942L3.034 96.936l8.266-18.7L0 73.14l12.165-2.3 4.76-16.561 4.353 17.672 17.253-2.513z"
          data-name="Caminho 12142"
          transform="translate(-19254.727 -7911.653)"
        ></path>
        <path
          fill="#724fff"
          d="M197.408 26.69l-18.137-8.829 19.66.228L203.747 0l4.267 21.129 14.567-6.529-6.815 9.292-3.994 4.134 5.982 17.153-14.5-12.564-13.325 12.666z"
          data-name="Caminho 12143"
          transform="translate(-19219.281 -7941.1)"
        ></path>
        <path
          fill="#36174d"
          d="M280.937 134.658c-7.025-19.218-8.319-51.666-8.319-51.666l-38.992-6.632 1.774 25.152s-49.576 54.3-40.484 63.285c7.446 7.357 32.144-11.406 32.144-11.406s9.552 42.011 26.126 43.927c12.571 1.454 16.683-10.079 15.847-18.219 7 10.942 19.477 21.046 30.072 15.8 14.52-7.2-12.816-45.593-18.168-60.237"
          data-name="Caminho 12144"
          transform="translate(-19215.332 -7920.348)"
        ></path>
        <path
          fill="#f6b399"
          d="M244 98.151a13.432 13.432 0 10-13.432 13.431A13.432 13.432 0 00244 98.151"
          data-name="Caminho 12145"
          transform="translate(-19208.992 -7918.076)"
        ></path>
        <path
          fill="#ee796a"
          d="M225.3 100.658a1.211 1.211 0 01-.856-2.068l7.405-7.405a1.211 1.211 0 111.713 1.712l-7.407 7.407a1.206 1.206 0 01-.856.355"
          data-name="Caminho 12146"
          transform="translate(-19207.102 -7916.416)"
        ></path>
        <path
          fill="#36174d"
          d="M225.241 52.732a1.212 1.212 0 01-1.176-1.5c2.376-9.606-4.286-12.168-4.571-12.271a1.212 1.212 0 01.834-2.275c.089.033 8.98 3.438 6.088 15.129a1.212 1.212 0 01-1.175.921"
          data-name="Caminho 12147"
          transform="translate(-19208.566 -7931.151)"
        ></path>
        <path
          fill="#f6b399"
          d="M189.351 217.068l-3.562-14.4s-30.771-29.717-48.6-45.566-31.37-25.344-37.893-17.118c-7.833 9.879 19.22 40.064 19.22 40.064s-10.824 4.338-12.852 16.008c0 0-11.076 7.347-9.7 14.958 0 0-6.656 1.438-7.262 6.048z"
          data-name="Caminho 12148"
          transform="translate(-19243.895 -7903.878)"
        ></path>
        <path
          fill="#ee796a"
          d="M88.961 209.33a1.354 1.354 0 01-.159-.01 1.21 1.21 0 01-1.043-1.358c.552-4.206 5.045-6.113 7.142-6.781-.35-7.049 7.893-13.343 9.921-14.782 2.33-11.816 13.041-16.234 13.5-16.418a1.211 1.211 0 01.9 2.247c-.135.055-10.23 4.273-12.111 15.092a1.215 1.215 0 01-.524.8c-.1.069-10.4 7-9.18 13.734a1.212 1.212 0 01-.935 1.4c-.057.011-5.831 1.321-6.317 5.021a1.211 1.211 0 01-1.2 1.054"
          data-name="Caminho 12149"
          transform="translate(-19244.154 -7894.93)"
        ></path>
        <path
          fill="#ee796a"
          d="M121.952 181.709a1.211 1.211 0 01-.85-.347l-9.548-9.393a1.211 1.211 0 011.7-1.727l9.55 9.393a1.211 1.211 0 01-.85 2.074"
          data-name="Caminho 12150"
          transform="translate(-19237.783 -7894.929)"
        ></path>
        <path
          fill="#ee796a"
          d="M111.511 194.3a1.2 1.2 0 01-.865-.364l-9.214-9.394a1.211 1.211 0 011.73-1.7l9.214 9.4a1.211 1.211 0 01-.865 2.059"
          data-name="Caminho 12151"
          transform="translate(-19240.529 -7891.509)"
        ></path>
        <path
          fill="#ee796a"
          d="M101.131 202.645a1.2 1.2 0 01-.824-.324l-6.331-5.888a1.211 1.211 0 011.651-1.773l6.33 5.887a1.212 1.212 0 01-.825 2.1"
          data-name="Caminho 12152"
          transform="translate(-19242.566 -7888.288)"
        ></path>
        <path
          fill="#ffd821"
          d="M38.254 182.78a7.59 7.59 0 11-7.59-7.59 7.59 7.59 0 017.59 7.59"
          data-name="Caminho 12153"
          transform="translate(-19261.73 -7893.49)"
        ></path>
        <path
          fill="#fff"
          d="M257.67 46.286a8.8 8.8 0 118.8-8.8 8.811 8.811 0 01-8.8 8.8m0-15.18a6.379 6.379 0 106.379 6.379 6.386 6.386 0 00-6.379-6.379"
          data-name="Caminho 12154"
          transform="translate(-19180.367 -7913.305)"
        ></path>
        <path
          fill="#36174d"
          d="M193.519 67s-5.914 10.942 6.108 12.1 26.763-13.025 26.763-13.025l-13.09-8.881z"
          data-name="Caminho 12155"
          transform="translate(-19215.805 -7925.557)"
        ></path>
        <path
          fill="#36174d"
          d="M227.5 205.375a1.212 1.212 0 01-1.03-1.847l11.4-18.508a1.211 1.211 0 112.062 1.272l-11.4 18.507a1.207 1.207 0 01-1.031.576"
          data-name="Caminho 12156"
          transform="translate(-19206.502 -7890.975)"
        ></path>
        <path
          fill="#ee796a"
          d="M191.585 104.528a8.387 8.387 0 01-5.592-2.079 1.211 1.211 0 011.661-1.764c.155.142 3.584 3.2 8.352-.111a1.211 1.211 0 111.382 1.989 10.088 10.088 0 01-5.8 1.965"
          data-name="Caminho 12157"
          transform="translate(-19217.557 -7913.827)"
        ></path>
      </g>
    </g>
  </svg>
)
export default HomeCardImg
