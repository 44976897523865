import { onAuthStateChanged } from 'firebase/auth'
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'

import { UserStoreInterface } from '~/model/store/user-store.interface'
import { AuthService } from '~/services/api/auth.service'

interface SignInDataInterface {
  email?: string
  password?: string
}

interface AuthContextInterface {
  user: UserStoreInterface | null
  signIn: (data: SignInDataInterface) => Promise<string | undefined>
  reset: (email: string) => void
  logout: () => void
}

export const AuthContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface
)

export const useAuth = () => useContext(AuthContext)

const userStorage = localStorage.getItem('user')

export const AuthProvider: FC<any> = ({ children }) => {
  const [user, setUser] = useState<UserStoreInterface | null>(
    userStorage ? (JSON.parse(userStorage) as UserStoreInterface) : null
  )

  const authService = new AuthService()
  const navigate = useNavigate()

  const setNewUser = useCallback(async (user: UserStoreInterface | null) => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user))
      setUser(user)
    } else {
      localStorage.removeItem('user')
      setUser(null)
    }
  }, [])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      authService.auth,
      async (fireUser) => {
        if (fireUser)
          await setNewUser(await AuthService.transformUser(fireUser))
        else setUser(null)
      }
    )

    return () => unsubscribe()
  }, [])

  const logout = useCallback(async () => {
    await authService.logout()
    await setNewUser(null)
  }, [])

  const reset = useCallback(async (email: string) => {
    try {
      await authService.resetPassword(email)
      await navigate('/')
    } catch (e) {
      return Promise.reject(e)
    }
  }, [])

  const signIn = useCallback(
    async ({ email, password }: SignInDataInterface = {}) => {
      try {
        const userSignin = await authService.signInEmailAndPassword(
          email as string,
          password as string
        )

        await setNewUser(userSignin)
        return userSignin.userId
      } catch (e) {
        console.log(e)
        return Promise.reject(e)
      }
    },
    []
  )

  return (
    <AuthContext.Provider value={{ user, reset, signIn, logout }}>
      {children}
    </AuthContext.Provider>
  )
}
