import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'

import {
  CandidateJourneyStatusApiType,
  CandidateJourneyStatusApiTypes,
} from '~/model/interfaces/api/common/job-journey-event.interface'
import { JobJourneyEventHistoryApiInterface } from '~/model/interfaces/api/job-journey-event.interface'
import { candidateStatusFormatter } from '~/utils/from-to/candidate-status.formatter'

import CandidateEventsOptions from './candidate-events-options'

interface StepListingInterface {
  name: string
  statusIncluded: CandidateJourneyStatusApiType[]
}

export const candidateStatusDescriptionFormatter = {
  None: 'Não foi localizado nenhuma interação com este candidato',
  Approached:
    'O candidato foi abordado pela Recrutaê e seguirá as próxima estapas do processo',
  Applied:
    'O candidato aplicou para a vaga e está aguardando as próximas instruções para teste',
  TestStarted:
    'O candidato inicializou o teste e tem até 3 dias para finalizar',
  TestFinish: 'O candidato finalizou o teste e está aguardando sua aprovação',
  TestApproved:
    'O candidato teve seu teste aprovado e seguirá para as próximas estapas de entrevista',
  TestDisapproved:
    'O candidato teve seu teste desaprovado e não seguirá para as próximas etapas do processo de seleção',
  InterviewScheduled:
    'O candiato está com a entrevista agendada com o(s) recrutador(es)',
  InterviewCancelled:
    'O candidato infelizmente cancelou a entrevista, ele ainda tem a possibilidade de reagendar a entrevista',
  InterviewRescheduled:
    'O candidato pode ter tido um imprevisto e optou por reagendar a entrevista',
  MeetingJoined:
    'O candidato acabou de entrar ou aguarda sua aprovação para o meeting',
  Placement: 'O Candidato recebeu a confirmação e foi contratado',
  Displacement:
    'O candidato infelizmente não passou no processo seletivo e não participa de mais nenhuma etapa',
  CandidateInteraction: 'Iteração do candidato',
}

const stepListing: StepListingInterface[] = [
  {
    name: 'Aplicou para a vaga',
    statusIncluded: [
      CandidateJourneyStatusApiTypes.Applied,
      CandidateJourneyStatusApiTypes.Approached,
      CandidateJourneyStatusApiTypes.None,
    ],
  },
  {
    name: 'Passando por testes',
    statusIncluded: [
      CandidateJourneyStatusApiTypes.TestApproved,
      CandidateJourneyStatusApiTypes.TestDisapproved,
      CandidateJourneyStatusApiTypes.TestStarted,
      CandidateJourneyStatusApiTypes.TestFinish,
    ],
  },
  {
    name: 'Em processo de entrevista',
    statusIncluded: [
      CandidateJourneyStatusApiTypes.InterviewScheduled,
      CandidateJourneyStatusApiTypes.InterviewCancelled,
      CandidateJourneyStatusApiTypes.InterviewRescheduled,
      CandidateJourneyStatusApiTypes.MeetingJoined,
    ],
  },
  {
    name: 'Finalizando o processo',
    statusIncluded: [
      CandidateJourneyStatusApiTypes.Placement,
      CandidateJourneyStatusApiTypes.Displacement,
    ],
  },
]

const CandidateEventsList = ({
  actualStatus = 'None',
  setStepVisible,
}: {
  loading: boolean
  data?: JobJourneyEventHistoryApiInterface[]
  actualStatus?: CandidateJourneyStatusApiType
  setStepVisible: React.Dispatch<React.SetStateAction<number>>
}) => {
  return (
    <Flex mt="l1">
      <Box>
        {stepListing.map(({ name, statusIncluded }, index) => (
          <Flex key={name} minHeight="70px">
            <Flex width="160px">
              <Flex alignItems="center" direction="column">
                <Flex
                  minWidth="32px"
                  minHeight="32px"
                  alignItems="center"
                  justifyContent="center"
                  background={`secondary.${
                    statusIncluded.includes(actualStatus) ? '500' : '100'
                  }`}
                  borderRadius="180px"
                  color="white"
                  fontWeight="bold"
                >
                  {index + 1}
                </Flex>
                {index !== stepListing.length - 1 && (
                  <Box width="2px" height="100%" background="primary.50" />
                )}
              </Flex>
              <Box ml="m2">{name}</Box>
            </Flex>
          </Flex>
        ))}
      </Box>
      <Flex direction="column" ml="l5" justifyContent="space-between">
        <Box>
          <Heading size="h-md">
            {candidateStatusFormatter?.[actualStatus]}
          </Heading>
          <Text mt="m1">
            {candidateStatusDescriptionFormatter?.[actualStatus]}
          </Text>
          <Button
            variant="link"
            size="sm"
            mt="m2"
            colorScheme="secondary"
            onClick={() => setStepVisible(3)}
          >
            Ver histórico completo
          </Button>
        </Box>
        <CandidateEventsOptions setStepVisible={setStepVisible} />
      </Flex>
    </Flex>
  )
}

export default CandidateEventsList
