export const languageEnum = {
  Spanish: 'Espanhol',
  English: 'Inglês',
  French: 'Francês',
  German: 'Alemão',
  Japanese: 'Japonês',
}

export const languageLevelEnum = {
  Basic: 'Básico',
  Intermediate: 'Intermediário',
  Advanced: 'Avançado',
  Fluent: 'Fluente',
}

export type LanguageEnumType = keyof typeof languageEnum
export type LanguageLevelEnumType = keyof typeof languageLevelEnum
