import { Box } from '@chakra-ui/react'

import { JobJourneyCountByStatusAnalyticsApiInterface } from '~/model/interfaces/api/job-analytics.interface'
import { ScoreApiInterface } from '~/model/interfaces/api/score.interface'

import { ScoreCandidate } from './score-candidate.tab'
import TalentByStage from './talent-by-stage.tab'

interface AnalyticsInterface {
  countByStatus: JobJourneyCountByStatusAnalyticsApiInterface
  score: ScoreApiInterface
  maxScore: number
}

const AnalyticsTab = ({
  countByStatus,
  maxScore,
  score,
}: AnalyticsInterface) => {
  return (
    <Box>
      <TalentByStage countByStatus={countByStatus} />
      <ScoreCandidate score={score} maxScore={maxScore} />
    </Box>
  )
}

export default AnalyticsTab
