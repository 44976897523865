import { Flex } from '@chakra-ui/react'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import FormItem from '~/components/form/form-item'
import TitleSteps from '~/components/title-steps/title-steps'
import { useCityOptions } from '~/hooks/select-options/use-city-options'
import { useStateOptions } from '~/hooks/select-options/use-state-options'
import { JobApiInterface } from '~/model/interfaces/api/job.interface'
import { JobSchemaInterface } from '~/model/schemas/job/job.schema.interface'

export const Localization = ({ job }: { job?: JobApiInterface }) => {
  const { watch, resetField } = useFormContext<JobSchemaInterface>()

  const { options: stateOptions } = useStateOptions()

  const { options: cityOptions } = useCityOptions(watch('address.state'))

  React.useEffect(() => {
    if (job?.jobAddress?.city) {
      resetField('address.city', { defaultValue: job.jobAddress.city })
    }
  }, [job, cityOptions])

  React.useEffect(() => {
    if (job && job?.jobAddress?.state)
      resetField('address.state', { defaultValue: job.jobAddress.state })
  }, [stateOptions, job])

  return (
    <>
      <TitleSteps text="Localização" as="h3" mt="l4" />
      <Flex gap="m3" pt="20px" alignItems="center">
        <FormItem
          name="address.state"
          inputType="select"
          options={stateOptions}
          label="Estado"
          placeholder="Selecione"
        />
        <FormItem
          name="address.city"
          inputType="select"
          options={cityOptions}
          label="Cidade"
          placeholder="Selecione"
        />
        <FormItem
          name="address.neighbourhood"
          inputType="text"
          label="Bairro"
        />
      </Flex>
    </>
  )
}
