import { Box, Heading, Stack, Text } from '@chakra-ui/react'

import { JobApiInterface } from '~/model/interfaces/api/job.interface'

import { UploadBanner } from './upload-banner'
import { UploadLogo } from './upload-logo'

interface HeaderJobInterface {
  title: string
  subtitle: string
  job?: JobApiInterface
}

export const HeaderJobs = ({ title, subtitle, job }: HeaderJobInterface) => {
  return (
    <Box
      bgImage="/background-jobdetail-web.png"
      bgPos="center"
      bgSize="100% 100%"
      bgRepeat="no-repeat"
      height="348px"
      width="100%"
      position="relative"
      borderRadius="35px"
      display="flex"
      flexDir="column"
      padding="40px 78px"
      justifyContent="flex-start"
      alignContent="space-between"
      gap="12px"
      flexWrap="wrap"
      my="60px"
    >
      <Stack gap="12px">
        <UploadLogo job={job} />
        <Heading color="white" mt="40px">
          {title}
        </Heading>
        <Text color="white"> {subtitle}</Text>
      </Stack>
      <UploadBanner job={job} />
    </Box>
  )
}
