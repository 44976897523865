import {
  Button,
  Flex,
  FormControl,
  FormControlProps,
  FormHelperText,
  Input,
  InputGroup,
  InputProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SpaceProps,
  Text,
} from '@chakra-ui/react'
import * as c from '@chakra-ui/react'
import React, { FC } from 'react'
import ReactPhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'

import ChevronDownIcon from '~/assets/icons/chevron-down.icon'
import { ErrorMessage } from '~/components/form/error-message'
import { ErrorMessageInterface } from '~/model/interfaces/components/error-message.interface'

interface PhoneInputProps extends InputProps {
  name: string
  label?: string
  helperText?: string
  spacing?: SpaceProps & FormControlProps
  error?: ErrorMessageInterface
  onChange?: (value: any) => void
}

interface PhoneCountryIconsInterface {
  'aria-label': string
  disabled: boolean
  iconComponent: FC<any>
  name: string
  onBlur: () => void
  onChange: (value: any) => void
  onFocus: () => void
  options: Array<{ value: string; label: string }>
  readonly: boolean
  value?: keyof typeof flags
}

const Flag = ({ flag }: { flag: keyof typeof flags }) => {
  const Icon: any = flags[flag]

  return Icon ? <Icon width="24px" title="" /> : <></>
}

const PhoneCountryIcons = ({
  iconComponent,
  disabled,
  readonly,
  options,
  onChange,
  value,
}: PhoneCountryIconsInterface) => {
  const Icon: any = React.useMemo(() => {
    if (!value) return iconComponent

    return flags[value]
  }, [value])

  return (
    <Flex alignItems="center" mt="4px">
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="8px"
          disabled={disabled || readonly}
        >
          <Icon width="28px" />
        </MenuButton>
        <MenuList>
          {options.map((option) => (
            <MenuItem key={option.value} onClick={() => onChange(option.value)}>
              <Flag flag={option.value as any} />
              <Text ml="4px">{option.label}</Text>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  )
}

const Container = ({ children }: any) => (
  <Flex alignItems="center" gap="16px">
    {children}
  </Flex>
)

const PhoneInput = React.forwardRef<React.ElementRef<'input'>, PhoneInputProps>(
  (
    { name, label, spacing, error, helperText, defaultValue, onChange },
    ref
  ) => {
    const [value, setValue] = React.useState<any>(defaultValue)

    React.useEffect(() => {
      if (defaultValue) setValue(defaultValue)
    }, [defaultValue])

    return (
      <FormControl isInvalid={!!error} variant="filled" {...spacing}>
        <InputGroup display="flex" flexDir="column">
          {label && <c.FormLabel htmlFor={name}>{label}</c.FormLabel>}
          <ReactPhoneInput
            countries={['BR', 'US', 'AR', 'CA', 'UY', 'PT']}
            international
            withCountryCallingCode
            countryCallingCodeEditable={false}
            defaultCountry="BR"
            countrySelectProps={{ unicodeFlags: true }}
            countrySelectComponent={PhoneCountryIcons}
            containerComponent={Container}
            inputComponent={Input}
            name={name}
            onChange={(phoneValue) => {
              setValue(phoneValue)
              if (onChange) onChange(phoneValue)
            }}
            value={value}
            ref={ref as any}
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {error?.message && <ErrorMessage error={error} />}
        </InputGroup>
      </FormControl>
    )
  }
)

export default PhoneInput
