import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { useParams } from 'react-router-dom'

import { useCandidateJourney } from '~/hooks/candidate/use-candidate-journey'
import { JobJourneyApiInterface } from '~/model/interfaces/api/job-journey.interface'
import { CandidateJourneyByJobApiInterface } from '~/model/interfaces/api/job-journey-event.interface'
import CandidateHistoryList from '~/screens/job/detail/components/candidates/candidate-history-list'

import CandidateEventForm from './candidate-event-form'
import CandidateEventsList from './candidate-events-list'
import DeclineCandidate from './decline-candidate'

interface ModalCandidateHistoryInterface {
  isOpen: boolean
  onClose: () => void
  candidate?: CandidateJourneyByJobApiInterface
}

const MainContent = ({
  candidate,
  setStepVisible,
  loading,
  journey,
}: {
  candidate?: CandidateJourneyByJobApiInterface
  setStepVisible: React.Dispatch<React.SetStateAction<number>>
  journey: JobJourneyApiInterface
  loading: boolean
}) => {
  return (
    <motion.div
      initial={{ x: -999, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -999, opacity: 0 }}
    >
      <Heading fontSize="h-sm">Detalhes da candidatura</Heading>
      <CandidateEventsList
        loading={loading}
        data={journey.eventHistory}
        actualStatus={candidate?.status}
        setStepVisible={setStepVisible}
      />
    </motion.div>
  )
}

const ModalCandidateHistory = ({
  isOpen,
  onClose,
  candidate,
}: ModalCandidateHistoryInterface) => {
  const [stepVisible, setStepVisible] = React.useState(0)
  const { id } = useParams()

  const { journey, loading } = useCandidateJourney({
    candidateId: candidate?.id as string,
    jobId: id as string,
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setStepVisible(0)
      }}
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent minWidth="800px" padding="m2">
        <ModalCloseButton />
        <ModalBody>
          <AnimatePresence initial={false} key="changeToFormModal">
            {stepVisible === 0 && (
              <MainContent
                candidate={candidate}
                setStepVisible={setStepVisible}
                loading={loading}
                journey={journey}
              />
            )}
            {stepVisible === 1 && (
              <CandidateEventForm
                candidate={candidate}
                jobId={id as string}
                setStepVisible={setStepVisible}
              />
            )}
            {stepVisible === 2 && (
              <DeclineCandidate
                candidate={candidate}
                jobId={id as string}
                setStepVisible={setStepVisible}
              />
            )}
            {stepVisible === 3 && (
              <CandidateHistoryList
                setStepVisible={setStepVisible}
                journey={journey}
              />
            )}
          </AnimatePresence>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalCandidateHistory
