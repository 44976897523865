import { Box, ListItem, UnorderedList } from '@chakra-ui/react'
import React from 'react'

import TitleSteps from '~/components/title-steps/title-steps'
import { convertTextToList } from '~/utils/text/convert-text-to-list'

interface SoftSkillRequirementsInterface {
  softSkillRequirements: string
}

export const SoftSkillRequirements = ({
  softSkillRequirements,
}: SoftSkillRequirementsInterface) => {
  return (
    <Box width="45%">
      <TitleSteps text="Requisitos comportamentais" as="h2" />
      <Box>
        <UnorderedList mt="20px">
          {convertTextToList(softSkillRequirements, '- ')?.map((item) => (
            <ListItem
              as="h3"
              color="primaryDark.500"
              key={item.indexOf.toString()}
            >
              {item}
            </ListItem>
          ))}
        </UnorderedList>
      </Box>
    </Box>
  )
}
