import { IconInterface } from '../../model/interfaces/components/icon.interface'

const ClockIcon = ({
  width = 21,
  height = 21,
  color = '#FFD821',
  ...props
}: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.2179 20.4299C8.19789 20.4301 6.22317 19.8313 4.5435 18.7091C2.86382 17.587 1.55464 15.9919 0.781521 14.1257C0.00839877 12.2595 -0.193937 10.2059 0.200102 8.2247C0.594141 6.24348 1.56686 4.4236 2.99523 2.99523C4.4236 1.56686 6.24348 0.594141 8.2247 0.200102C10.2059 -0.193937 12.2595 0.00839877 14.1257 0.781521C15.9919 1.55464 17.587 2.86382 18.7091 4.5435C19.8313 6.22317 20.4301 8.19789 20.4299 10.2179C20.4267 12.9253 19.3498 15.5209 17.4354 17.4354C15.5209 19.3498 12.9253 20.4267 10.2179 20.4299ZM10.2179 1.49591C8.49306 1.49591 6.80694 2.00739 5.37278 2.96566C3.93862 3.92394 2.82083 5.28597 2.16076 6.87953C1.50069 8.47308 1.32798 10.2266 1.66448 11.9183C2.00098 13.61 2.83158 15.1639 4.05123 16.3836C5.27089 17.6032 6.82482 18.4338 8.51653 18.7703C10.2082 19.1068 11.9617 18.9341 13.5553 18.2741C15.1488 17.614 16.5109 16.4962 17.4692 15.062C18.4274 13.6279 18.9389 11.9418 18.9389 10.2169C18.9363 7.90477 18.0166 5.68809 16.3817 4.05316C14.7467 2.41822 12.5301 1.49856 10.2179 1.49591Z"
      fill={color}
    />
    <path
      d="M12.2767 13.0174C12.1788 13.0175 12.0819 12.9983 11.9915 12.9609C11.901 12.9235 11.8189 12.8686 11.7497 12.7994L9.69067 10.7404C9.55107 10.6006 9.47267 10.411 9.47267 10.2134V4.72643C9.469 4.62624 9.48556 4.52633 9.52137 4.43267C9.55719 4.33902 9.61151 4.25355 9.6811 4.18137C9.75068 4.10919 9.83411 4.05177 9.92639 4.01255C10.0187 3.97334 10.1179 3.95312 10.2182 3.95312C10.3184 3.95312 10.4177 3.97334 10.5099 4.01255C10.6022 4.05177 10.6856 4.10919 10.7552 4.18137C10.8248 4.25355 10.8791 4.33902 10.915 4.43267C10.9508 4.52633 10.9673 4.62624 10.9637 4.72643V9.90944L12.8037 11.7494C12.9078 11.8538 12.9787 11.9866 13.0074 12.1312C13.0361 12.2758 13.0213 12.4256 12.9649 12.5618C12.9086 12.698 12.8131 12.8144 12.6906 12.8964C12.5681 12.9784 12.4241 13.0222 12.2767 13.0224"
      fill={color}
    />
  </svg>
)
export default ClockIcon
