import { IconInterface } from '../../model/interfaces/components/icon.interface'

const MessageViewer = ({
  width = 23,
  height = 13,
  ...props
}: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 23 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.9476 12.6289L0.933594 6.61485L1.8826 5.66586L6.9476 10.7309L17.0476 0.630859L17.9976 1.58086L6.9476 12.6289Z"
      fill="#009245"
    />
    <path
      d="M10.9762 12.6289L9.15723 10.8099L10.1072 9.85991L10.9772 10.7289L21.0772 0.628906L22.0273 1.57891L10.9762 12.6289Z"
      fill="#009245"
    />
  </svg>
)
export default MessageViewer
