import { Box, Text } from '@chakra-ui/react'
import React from 'react'

import TitleSteps from '~/components/title-steps/title-steps'

interface JobDescriptionInterface {
  jobDescription: string
}

export const JobDescription = ({ jobDescription }: JobDescriptionInterface) => {
  return (
    <Box width={['45%']}>
      <TitleSteps text="Perfil da Empresa" as="p" />
      <Text display="flex" mt="20px">
        {jobDescription}
      </Text>
    </Box>
  )
}
