import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react'
import React from 'react'

import { AddressApiInterface } from '~/model/interfaces/api/common/address.interface'
import { JobApiInterface } from '~/model/interfaces/api/job.interface'
import { RecruiterApiInterface } from '~/model/interfaces/api/user.interface'
import { JobStatusEnum, JobStatusEnumType } from '~/utils/from-to/status'

import { PlayerVideo } from './player-video'

interface HeaderJobInfoInterface {
  job: JobApiInterface
  recruiter?: RecruiterApiInterface
  hasInterviewScheduled?: boolean
  hasVideo: boolean
}

export const HeaderJobs = ({
  job: {
    jobName,
    workType,
    status: jobStatus,
    level: jobLevel,
    jobAddress,
    profileImageLink,
    companyVideoUrl,
  },
  recruiter,
}: HeaderJobInfoInterface) => {
  const getFormatedLocation = (
    address: AddressApiInterface,
    workType: string
  ): string => {
    const location = [address?.state, address?.city, address?.neighbourhood]

    if (workType === 'Remote') location.push('Remoto')

    return location
      .filter((item) => item !== '' && item !== undefined)
      .join(' | ')
  }

  const statusJob = React.useMemo(() => {
    switch (jobStatus) {
      case 'Canceled':
        return 'purpleLight.100'

      case 'Draft':
        return 'secondary.500'

      case 'Finished':
        return 'primaryLight.900'

      case 'Recruiting':
        return 'primaryLight.500'

      default:
        return 'primaryLight.500'
    }
  }, [jobStatus])

  return (
    <Flex
      bgImage="/background-jobdetail-web.png"
      bgPos="center"
      bgSize="100% 100%"
      bgRepeat="no-repeat"
      height={['412px']}
      width="100%"
      borderRadius="35px"
      padding="30px 48px"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      mt="40px"
    >
      <Box>
        <Box
          padding={['8px 26px']}
          bgColor={statusJob}
          borderRadius="xl"
          w="fit-content"
          fontWeight="bold"
          fontSize="smaller"
          color="primaryDark.500"
        >
          <Text fontSize="14px">
            {JobStatusEnum[jobStatus as JobStatusEnumType]}
          </Text>
        </Box>
        <Image
          bgColor="white"
          width="130px"
          borderRadius="xl"
          mt="20px"
          height="60px"
          src={
            (process.env.REACT_APP_GCP_CLOUD_STORAGE as string) +
            profileImageLink
          }
          alt=""
          objectFit="cover"
          objectPosition="center"
        />
        <Heading
          as="h1"
          color="white"
          fontWeight="bold"
          fontSize="2xl"
          mt="17px"
        >
          {jobName}
        </Heading>
        <Heading
          as="h2"
          color="white"
          fontWeight="hairline"
          fontSize="medium"
          mt="10px"
        >
          {jobLevel} | {getFormatedLocation(jobAddress, workType)}
        </Heading>
        <Flex mt="80px" wrap={['wrap']} justifyContent="space-between">
          <Box minWidth="fit-content">
            <Text color="secondary.500" fontWeight="bold" fontSize="small">
              Recrutador
            </Text>
            <Text color="white" fontWeight="hairline" fontSize="small" mt="4px">
              {recruiter?.name}
            </Text>
          </Box>
          <Box minWidth="fit-content" ml="40px">
            <Text color="secondary.500" fontWeight="bold" fontSize="small">
              Linkedin
            </Text>
            <Button
              onClick={() => window.open(recruiter?.linkedinProfile, '_blank')}
              variant="link"
              color="white"
              fontWeight="hairline"
              fontSize="small"
              noOfLines={1}
              mt="4px"
            >
              Contatar Recrutador
            </Button>
          </Box>
        </Flex>
      </Box>
      <Flex mr="100px" display="flex" width="fit-content">
        {companyVideoUrl && <PlayerVideo companyVideoUrl={companyVideoUrl} />}
      </Flex>
    </Flex>
  )
}
