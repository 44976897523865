import { IconInterface } from '../../model/interfaces/components/icon.interface'

const RecruiterCardImg = ({
  width = 489.192,
  height = 449.493,
  ...props
}: IconInterface) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 489.192 449.493"
    {...props}
  >
    <g data-name="Grupo 9395" transform="translate(13311 7681)">
      <path
        fill="#02004a"
        d="M354.809 314H42.269a17.289 17.289 0 01-17.287-17.287V66.872a17.289 17.289 0 0117.287-17.287h312.54A17.287 17.287 0 01372.1 66.872v229.844A17.287 17.287 0 01354.809 314"
        data-name="Caminho 12163"
        transform="translate(-13270.507 -7600.629)"
      ></path>
      <path
        fill="#dadef1"
        d="M314.815 262.729L106.2 328.5 33.055 89.07l206.481-65.108a17.949 17.949 0 0122.505 11.715l64.489 204.544a17.952 17.952 0 01-11.715 22.508"
        data-name="Caminho 12164"
        transform="translate(-13257.422 -7643.511)"
      ></path>
      <path
        fill="#f5f5fa"
        d="M159.777 287.719L87.828 310.4a17.932 17.932 0 01-22.5-11.715L.833 94.149a17.947 17.947 0 0111.724-22.508l74.076-23.352z"
        data-name="Caminho 12165"
        transform="translate(-13311 -7602.73)"
      ></path>
      <path
        fill="#02004a"
        d="M315.616 35.682L.836 134.927a17.944 17.944 0 0111.715-22.505l280.56-88.457a17.944 17.944 0 0122.505 11.718"
        data-name="Caminho 12166"
        transform="translate(-13310.999 -7643.51)"
      ></path>
      <path
        fill="#fff"
        d="M107.235 33.992A3.541 3.541 0 11106 29.138a3.54 3.54 0 011.232 4.854"
        data-name="Caminho 12167"
        transform="translate(-13147.854 -7634.582)"
      ></path>
      <path
        fill="#f66957"
        d="M111.4 32.7a3.541 3.541 0 11-1.232-4.854A3.54 3.54 0 01111.4 32.7"
        data-name="Caminho 12168"
        transform="translate(-13141.105 -7636.676)"
      ></path>
      <path
        fill="#ffbb4d"
        d="M115.631 31.384a3.539 3.539 0 11-1.231-4.854 3.543 3.543 0 011.229 4.854"
        data-name="Caminho 12169"
        transform="translate(-13134.245 -7638.806)"
      ></path>
      <path
        fill="#f6b399"
        d="M117.338 118.836l13.267 23.75 26.979-12.85-18.592-23.452z"
        data-name="Caminho 12170"
        transform="translate(-13120.809 -7508.727)"
      ></path>
      <path
        fill="#f6b399"
        d="M182.478 121.7c0 21.182-13.306 33.453-34.782 36.092-7.032.868-17.3.33-22.988-3.03a38.364 38.364 0 01-4.932-2.734 39.056 39.056 0 01-14.391-16.816 40.178 40.178 0 01-2.707-23.625 1.347 1.347 0 011.266-.957 8.917 8.917 0 003.926-1.386 38.344 38.344 0 0174.608 12.456"
        data-name="Caminho 12171"
        transform="translate(-13145.7 -7545.894)"
      ></path>
      <path
        fill="#19c5ff"
        d="M209.138 145.155a38.346 38.346 0 11-38.343-38.346 38.343 38.343 0 0138.343 38.346"
        data-name="Caminho 12172"
        transform="translate(-13096.321 -7507.876)"
      ></path>
      <path
        fill="#02004a"
        d="M163.44 93.147a18.174 18.174 0 00-2.8-3.887 8.52 8.52 0 00-7.071-2.162 8.676 8.676 0 00-4.775-5 7.713 7.713 0 00-8.169 1.711c-.723-1.08-1.308-2.267-2.118-3.287a8.945 8.945 0 00-4.107-2.807A13.948 13.948 0 00116.455 90.9a15.848 15.848 0 00-9.642 11.288 11.874 11.874 0 002.2 9.537 15.936 15.936 0 006.531 4.437 10.482 10.482 0 00-2.4 10.311 10.644 10.644 0 009.047 7.076 57.421 57.421 0 006.764 15.083 27.846 27.846 0 005.973 6.45c2.71 2.084 5.866 3.26 8.89 4.794 3.085 1.562 6.172 3.819 6.7 7.48a1.319 1.319 0 001.609.915q2.646-.712 5.273-1.478a92.403 92.403 0 006.531-1.46 1.319 1.319 0 00.579-2.183 22.818 22.818 0 01-1.908-2.23s6.432-5.284 7.949-6.751c9.886-9.59 12.373-17.754 12.308-28.594-.081-14.341-8.9-26.568-19.415-32.425"
        data-name="Caminho 12173"
        transform="translate(-13138.213 -7556.046)"
      ></path>
      <path
        fill="#f6b399"
        d="M128.326 107.649a8.532 8.532 0 11-8.534-8.534 8.535 8.535 0 018.534 8.534"
        data-name="Caminho 12174"
        transform="translate(-13130.659 -7520.348)"
      ></path>
      <path
        fill="#ee796a"
        d="M111.225 101.63a1.336 1.336 0 00-1.79.469 13.779 13.779 0 01-2.259 2.529c-3.454 1.68-3.942 2.388-3.719 3.08a1.331 1.331 0 001.609.915 12.15 12.15 0 006.628-5.2 1.324 1.324 0 00-.469-1.79"
        data-name="Caminho 12175"
        transform="translate(-13143.384 -7516.541)"
      ></path>
      <path
        fill="#ee796a"
        d="M120.982 102.08a1.338 1.338 0 00-1.706-.715 43.876 43.876 0 00-6.958 3.09 1.342 1.342 0 00-.715 1.706 1.322 1.322 0 001.706.716 43.881 43.881 0 016.958-3.09 1.317 1.317 0 00.715-1.706"
        data-name="Caminho 12176"
        transform="translate(-13130.282 -7516.836)"
      ></path>
      <path
        fill="#36174d"
        d="M111.433 97.89a3.146 3.146 0 11-4.141-1.628 3.148 3.148 0 014.141 1.628"
        data-name="Caminho 12177"
        transform="translate(-13140.156 -7525.397)"
      ></path>
      <path
        fill="#02004a"
        d="M117.1 102.26a17.238 17.238 0 00-3.5-7.716c-.9-1.166-1.832-2.466-3.378-2.739-1.607-.28-3.53.6-3.748 2.364-.278 2.27 1.753 2.642 3.289 3.331a10 10 0 014.248 4.571 5.258 5.258 0 00.87 1.368c.776.755 2.367.459 2.272-.833-.01-.118-.031-.233-.05-.346"
        data-name="Caminho 12178"
        transform="translate(-13138.462 -7532.278)"
      ></path>
      <path
        fill="#ffd821"
        d="M326.245 268.19L298.2 147.525l-19.748-40.341-52.051 16.506c-26.665 8.458-45.5 12.4-72.168 20.857-10.064 3.192-26.741 4.917-37.324 16.606-8.759 9.674-12.6 20.249-16.8 31.875-8.927 24.707-17.945 49.414-25.168 74.685a6.18 6.18 0 005.884 8.049h63.655a6.173 6.173 0 005.928-4.445l7.857-26.951 8.117 27a6.169 6.169 0 005.91 4.4h147.941a6.174 6.174 0 006.012-7.572"
        data-name="Caminho 12179"
        transform="translate(-13189.987 -7507.269)"
      ></path>
      <path
        fill="#ffd821"
        d="M279.4 158.945c-6.825 26.935-73.385 148.5-73.385 146.3 0-1.622-12.017-20.723-24.6-47.65A36.748 36.748 0 01170.178 264a44.964 44.964 0 01-25.454.983A56.6 56.6 0 01132 260.278c-1.57-.82-3.174-2.021-3.4-3.895a3.453 3.453 0 011.4-3.315 200.917 200.917 0 0116.213-16.553c10-9.228 57.961-78.653 57.961-78.653s-27.1-65.611-42.34-88.2L198.987 34.2c16.336 18.524 32.444 37.29 49.286 55.335 1.421 1.528 2.859 3.069 4.306 4.657 16.247 17.754 33.23 39.463 26.822 64.749"
        data-name="Caminho 12180"
        transform="translate(-13102.595 -7625.56)"
      ></path>
      <path
        fill="#f6b399"
        d="M132.844 200.783c-4.128-6.3-8.227-12.355-12.067-17.715-7.294-21.153-34.855-50.122-45-54.126-4.062-1.612-8.927-3.446-13.241-2.516-3.889.844-6.647 2.516-8.245 6.405-1.578 3.858 1.318 7.289 4.083 9.972 3.1 3 6.932 5.16 10.19 7.981a4.463 4.463 0 011.054 4.964 6.029 6.029 0 01-2.768 2.99 24.175 24.175 0 01-3.247 1.816 24.842 24.842 0 01-5.588 1.822 43.739 43.739 0 01-14.391-5.176 19.954 19.954 0 01-6-4.733 7.766 7.766 0 01-1.234-7c.621-2.592 1.74-5.074 1.746-7.781a8.4 8.4 0 00-2.453-6 10.657 10.657 0 00-12.864-1.486c-4.859 2.731-7.365 7.8-8.5 13.081a37.3 37.3 0 00-.2 15.979 22.507 22.507 0 008.167 12.827A48.72 48.72 0 0029.9 176.8c.419.212.831.425 1.245.634a14.439 14.439 0 002.72 11.443 22.737 22.737 0 006.653 10.723 9.236 9.236 0 003.559 9.637c3.342 2.65 7.834 3.116 11.93 3.538q14.742 1.5 29.485 3.006c.681.071 3.47 11.228 3.47 11.228z"
        data-name="Caminho 12181"
        transform="translate(-13289.374 -7476.486)"
      ></path>
      <path
        fill="#ffd821"
        d="M102.567 198.837H51.5a6.176 6.176 0 01-5.989-4.676l-3.321-13.29 43.884-26.23c7.661 11.726 15.419 24.3 21.779 34.839a6.173 6.173 0 01-5.292 9.357"
        data-name="Caminho 12182"
        transform="translate(-13242.607 -7430.347)"
      ></path>
      <path
        fill="#f6b399"
        d="M233.143 89.644L196 125.1c-5.066-7.5-9.231-13.712-11.846-17.615-6.757-1.248-12.022-3.913-15.77-8.264-6.094-4.8-12.344-9.53-19.36-12.91A44.062 44.062 0 00139 82.845a36.815 36.815 0 00-5.42-.7 19.629 19.629 0 01-5.512-.765c-5.561-1.989-6.432-8.431-4.243-13.27 2.755-6.094 9.883-7.732 15.987-7.724 3.266.01 6.675.5 10.056.734.073-.4.142-.8.212-1.2-3.163-4.466-6.974-9.005-12.509-9.834a8.691 8.691 0 00-6.94 1.667c-1.753 1.489-2.658 3.745-3.635 5.769-1.848 3.853-4.587 7.506-9.37 7.034-5.205-.522-8.481-5.011-9.579-9.8-2.034-8.893 1.8-18.532 8.743-24.269 7.331-6.075 17.319-6.694 26.272-4.411a19.621 19.621 0 012.563-1.63 18.635 18.635 0 017.967-2.264 16.561 16.561 0 013.163.147 24 24 0 015-2.676c-2.857-1.109-10.47.571-12.84.459-5.645-.267-12.5-6.66-5.056-13.474 7.215-6.647 21.6-9.207 29.875-3.441 6.419 4.474 10.643 12.494 15.122 18.758 4.131 5.779 8.167 11.629 11.951 17.644 3.171 5.029 6.162 10.19 8.882 15.482a147.271 147.271 0 017.218 16.31c.024 0 8.489 9.519 8.945 10.025q3.656 4.1 7.286 8.227"
        data-name="Caminho 12183"
        transform="translate(-13136.751 -7681)"
      ></path>
      <path
        fill="#fff"
        d="M233.926 64.929L62.038 119.12A15.855 15.855 0 1152.5 88.878l171.888-54.194a15.857 15.857 0 119.537 30.245"
        data-name="Caminho 12184"
        transform="translate(-13243.878 -7625.979)"
      ></path>
      <path
        fill="#dadef1"
        d="M63.145 92.321l-35.219 11.1a15.856 15.856 0 11-9.535-30.241l35.217-11.1a15.857 15.857 0 119.537 30.245"
        data-name="Caminho 12185"
        transform="translate(-13299.175 -7581.581)"
      ></path>
      <path
        fill="#724fff"
        d="M138 98.264a35.385 35.385 0 01-3.006 8.995.978.978 0 001.355 1.295 25.984 25.984 0 0010.093-8.313c.574.018 13.678.018 13.678.018a20.924 20.924 0 0014.433-5.666 18.8 18.8 0 005.981-13.686c0-10.685-9.136-19.347-20.414-19.347h-13.1a20.9 20.9 0 00-14.425 5.666 18.789 18.789 0 00-5.978 13.681A19.278 19.278 0 00138 98.264"
        data-name="Caminho 12186"
        transform="translate(-13105.78 -7581.221)"
      ></path>
      <path
        fill="#724fff"
        d="M138.577 123.677a37.587 37.587 0 009.005 10.767c1.106.9-.123 2.406-1.263 2.178A37.918 37.918 0 01125.59 124.8H87.732a25.9 25.9 0 110-51.794h43.286a25.9 25.9 0 017.559 50.669"
        data-name="Caminho 12187"
        transform="translate(-13210.777 -7562.664)"
      ></path>
      <path
        fill="#ffd821"
        d="M219.9 137.856c-1.436 9.92-5.221 19.735-11.739 27.443a37.611 37.611 0 01-11.561 9.246 47.931 47.931 0 01-16.884 4.579c-12.135 1.245-24.712.094-36.632-2.354a134.5 134.5 0 01-25.242-7.865q5.083 5.413 10.494 10.523c1.229 1.158-.626 3.006-1.848 1.848q-3.44-3.243-6.754-6.607-4.053-4.132-7.9-8.465a128.257 128.257 0 01-3.512-1.725 1.259 1.259 0 01.052-2.233c-.532-.626-1.072-1.245-1.607-1.882-1.8-2.162-3.824-4.474-4.217-7.367-.411-2.964 1.413-5.124 3.137-7.3a120.44 120.44 0 0122.561-21.74 126.806 126.806 0 0157.568-23.674 121.173 121.173 0 0115.741-1.219c2.582-.029 5.153.034 7.732.181 2.765.157 5.546.183 7 2.938 1.334 2.527 1.892 5.614 2.511 8.387a78.785 78.785 0 011.51 9.6 74.032 74.032 0 01-.411 17.694"
        data-name="Caminho 12188"
        transform="translate(-13144.867 -7520.449)"
      ></path>
      <path
        fill="#ff9e00"
        d="M188.631 124.059a61.358 61.358 0 00-6.19-14.672c-2.5-4.254-5.706-8.258-10.138-10.6-1.486-.784-2.81 1.473-1.321 2.259a23.377 23.377 0 018.662 8.762 57.179 57.179 0 015.645 12.229 60.316 60.316 0 012.534 26.534c-1.077 8.2-3.984 16.517-9.448 22.849-5.281 6.125-12.612 9.613-20.477 11.05-8.984 1.641-18.325.823-27.273-.642-1.649-.27-2.356 2.251-.7 2.521 16.281 2.665 35.72 3.352 48.421-9.079 6.272-6.141 9.936-14.588 11.534-23.116a62.493 62.493 0 00-1.253-28.1"
        data-name="Caminho 12189"
        transform="translate(-13101.936 -7521.14)"
      ></path>
      <path
        fill="#ff9e00"
        d="M193.845 50.368c-.794 3.68-3.433 6.539-6.146 8.979q-3.585 3.228-7.215 6.416-8.83 9.427-17.636 18.847c-1.09 1.158-2.17 2.325-3.26 3.486a7.935 7.935 0 01-2.634 2.031c-2.492 1.038-4.673-.626-6.188-2.482-2.642-3.211-5.205-6.5-7.758-9.784a6.847 6.847 0 01-1.953-2.492c-.577-.723-1.143-1.455-1.717-2.186a1.428 1.428 0 011.02-2.293 14.8 14.8 0 013.179-6.974 129.049 129.049 0 0112.04-13.716 143.618 143.618 0 0112.24-10.94q3.125-2.485 6.361-4.8c1.4-.985 3.69-2.935 5.457-2.223a74.912 74.912 0 016.487 4.856 53.819 53.819 0 014.7 3.992 9.918 9.918 0 013.025 9.283"
        data-name="Caminho 12190"
        transform="translate(-13085.648 -7628.997)"
      ></path>
      <path
        fill="#ff9e00"
        d="M97.231 172.956A10.39 10.39 0 0194.118 175a399.376 399.376 0 01-3.7 2q-15.037 8.028-30.573 15.107c-2.571 1.166-5.142 2.359-7.755 3.449-1.945.81-4.448 1.326-6.075-.409a8.829 8.829 0 01-1.6-3.294 62.521 62.521 0 01-1.174-3.748c-2.147-3.234-3.357-5.719-3.47-8.368-.123-2.762 1.324-4.736 3.475-6.277 5.058-3.609 10.313-6.956 15.631-10.164q7.061-4.25 14.357-8.072a76.165 76.165 0 017.3-3.625 5.853 5.853 0 016.408.878c1.848 1.711 3 4.332 4.28 6.46l4.427 7.365c1.135 1.892 3.41 4.5 1.588 6.652"
        data-name="Caminho 12191"
        transform="translate(-13246.552 -7436.178)"
      ></path>
      <path
        fill="#fff"
        d="M86.7 100.362q-6.239-5.441-12.473-10.874 5.661-4.438 11.584-8.52c1.525-1.051.079-3.562-1.46-2.5q-6.88 4.732-13.415 9.938a1.451 1.451 0 000 2.05l13.712 11.954c1.4 1.219 3.454-.82 2.047-2.047"
        data-name="Caminho 12192"
        transform="translate(-13196.708 -7554.23)"
      ></path>
      <path
        fill="#fff"
        d="M97.814 87.581c-1.187-1.046-2.456-2.013-3.68-3.017l-7.365-6.036c-1.289-1.059-3.15.781-1.848 1.848l6.358 5.213q1.592 1.305 3.182 2.605c.5.411 1.02.8 1.5 1.237-4.222 4.165-8.29 7.8-12.35 11.453-1.255 1.127.6 2.972 1.848 1.85q3.526-3.169 7.05-6.34 1.679-1.514 3.363-3.027c.928-.833 2.38-1.735 2.878-2.92a2.477 2.477 0 00-.938-2.867"
        data-name="Caminho 12193"
        transform="translate(-13176.148 -7554.23)"
      ></path>
      <path
        fill="#fff"
        d="M164.086 74.81a7.116 7.116 0 01-1.953 1.937 7.689 7.689 0 01-1.145.624c.094-.4.16-.888.259-1.211.152-.49.315-.98.472-1.468a19.7 19.7 0 00.836-3.329c.246-1.8-.875-3.722-2.917-3.019-1.622.556-2.935 2.511-3.921 3.821-2.112 2.807-3.714 6.08-6.4 8.416a9.425 9.425 0 01-.951.729c-1.1.018-.443-1.428-.252-2.039a20.765 20.765 0 012.909-5.774c.928-1.3 2.54-3.082 1.266-4.71-1.321-1.7-3.716-.359-5.216.317a28.959 28.959 0 00-3.2 1.7 7.383 7.383 0 00-.451-1.921c-.729-1.84-2.325-3.3-4.408-3.03-2.063.27-4.01 1.974-5.357 3.47a15.4 15.4 0 00-3.266 5.782c-.5 1.612 2.026 2.3 2.521.695a12.537 12.537 0 013.415-5.465 7.711 7.711 0 012.692-1.824c1.006-.309 1.656.469 1.989 1.345a5.62 5.62 0 01.265 2.713 31.3 31.3 0 00-3.735 3.323 18.554 18.554 0 00-3.457 4.442c-.6 1.274-.634 3.289.949 3.85 1.02.364 1.628-.252 2.33-.9.828-.765 1.641-1.546 2.4-2.38a17.089 17.089 0 003.394-5.263 11.954 11.954 0 00.485-1.557 28.286 28.286 0 012.891-1.8 25.192 25.192 0 013.15-1.457 9.814 9.814 0 01-1.02 1.5 22.838 22.838 0 00-3.373 7.31 5.5 5.5 0 00-.181 3.344 2.4 2.4 0 002.831 1.423c2.173-.48 4.112-2.663 5.423-4.327 1.47-1.863 2.686-3.9 4.06-5.831a21.664 21.664 0 011.96-2.414c.48-.071.346.411.212.878-.5 1.764-3.129 6.977.121 7.532a5.748 5.748 0 003.6-1.151 10.262 10.262 0 003.03-2.962c.954-1.392-1.313-2.7-2.259-1.321"
        data-name="Caminho 12194"
        transform="translate(-13099.755 -7574.32)"
      ></path>
      <path
        fill="#dadef1"
        d="M66.839 78.218a24.609 24.609 0 01-6.673 5.624c-2.354 1.172-2.33-2.262-2.217-3.716.123-1.549-2.1-1.662-2.568-.349a35.682 35.682 0 01-5.771 10.486 3.932 3.932 0 01-.909 1c-.684-.81-.168-1.866-.06-2.579a3.863 3.863 0 00-1.723-4.084c-3.192-1.958-6.308.422-8.455 2.731-1.08-.626-2.031-1.633-3.378-1.832-1.6-.238-3.177.778-4.419 1.672a16.848 16.848 0 00-3.357 3.2 5.3 5.3 0 00-1.027-1.457c-2.257-2.194-4.707.081-6.453 1.7a51.377 51.377 0 00-5.894 6.505A1.314 1.314 0 0014.4 98.9a1.34 1.34 0 001.79-.469 47.621 47.621 0 014.238-4.781q1.156-1.152 2.393-2.22c.705-.608 1.324-1.234 1.929-.238a6.353 6.353 0 01.744 1.987 17.118 17.118 0 00-1.738 10.408c.178 1.164 1.866 1.2 2.39.312a15.67 15.67 0 002.2-7.834 18.1 18.1 0 00-.142-2.424 14.68 14.68 0 012.369-3.014 13.143 13.143 0 012.592-1.96c.44-.254 1.093-.723 1.622-.566.464.139.768.718.946 1.13-1.145 3.135-2.094 5.509-1.465 8.148a1.314 1.314 0 002.183.577 10.277 10.277 0 002.312-7 20.064 20.064 0 013.218-3.423c.959-.786 2.587-1.588 3.724-.611.666.577.333 1.444.178 2.181a5.754 5.754 0 00-.147 2.361 2.8 2.8 0 003.114 2.492c1.709-.273 2.778-2.018 3.719-3.3a37.407 37.407 0 003.515-5.892 3.374 3.374 0 002.983 1.96c1.95.081 3.651-1.365 5.108-2.49a34.918 34.918 0 004.513-4.178c1.127-1.245-.718-3.1-1.85-1.848"
        data-name="Caminho 12195"
        transform="translate(-13288.731 -7554.895)"
      ></path>
      <path
        fill="#dadef1"
        d="M71.642 89.467c-.621-1.541-3.15-.868-2.524.695.275.681-.854.831-1.324.563-.9-.514-1.334-1.79-1.685-2.7a1.324 1.324 0 00-1.607-.915 13.365 13.365 0 00-4.55 2.836c.026-.522.045-1.048.042-1.573a1.321 1.321 0 00-2.437-.66 108.376 108.376 0 01-9.327 10.956c-.391.4-1.316 1.533-1.892.97 2.146-3.832 4.057-7.716 1.481-10.546a1.319 1.319 0 00-2.052.262 15.558 15.558 0 00-2.43 8.5 6.112 6.112 0 01-1.46 2.068 23.2 23.2 0 01-2.157 1.877c-.619.461-1.6 1.337-2.422 1.337-1.583 0-.042-2.776.28-3.329.933-1.6 1.766-3.467.878-5.281A4.115 4.115 0 0035 92.371c-2.631-.259-5.116 1.038-7.2 2.629a3.031 3.031 0 00-2.786-2.013c-1.9-.01-3.821 1.528-5.048 2.859A12.075 12.075 0 0017 106.385c.333 1.649 2.854.951 2.521-.695a9.3 9.3 0 012.188-7.881c.535-.6 2.71-2.815 3.562-2.052a62.3 62.3 0 00-4.943 9.275c-.964 2.589-2.191 6.9.21 9.1a3.275 3.275 0 004.39-.1 11.224 11.224 0 002.752-4.862 22.471 22.471 0 00.886-11.482 10.207 10.207 0 015.268-2.713c.891-.1 2.251-.026 2.4 1.1.186 1.462-1.413 3-1.877 4.335a4.729 4.729 0 00.11 3.753 3.275 3.275 0 003.834 1.421 12.766 12.766 0 004.513-2.964c.516-.456 1-.944 1.478-1.441a2.708 2.708 0 003.011 1.174 6.813 6.813 0 003.2-2.272q1.671-1.749 3.271-3.567c1.135-1.289 2.23-2.61 3.3-3.947a28.35 28.35 0 01-.435 2.364 1.308 1.308 0 002.521.692 10.4 10.4 0 015-5.538c.8 1.7 2 3.363 4.015 3.425 2.034.063 4.332-1.882 3.462-4.044m-47.535 21.4c-.44.8-1.573 2.285-2.126.781a7.089 7.089 0 01.278-4.049 19.2 19.2 0 013.992-7.572 19.786 19.786 0 01-2.144 10.84"
        data-name="Caminho 12196"
        transform="translate(-13283.822 -7539.917)"
      ></path>
      <path
        fill="#ee796a"
        d="M44.748 159.655c-11.147-3.032-23.871-7.9-28.541-19.5-.621-1.541-3.153-.867-2.524.695 5.064 12.572 18.178 18.011 30.371 21.329a1.308 1.308 0 00.695-2.521"
        data-name="Caminho 12197"
        transform="translate(-13288.983 -7455.242)"
      ></path>
      <path
        fill="#ee796a"
        d="M27.024 155.825A11.985 11.985 0 0122 147.1a1.336 1.336 0 00-1.308-1.308 1.32 1.32 0 00-1.308 1.308 14.668 14.668 0 006.324 10.984 1.32 1.32 0 001.787-.472 1.335 1.335 0 00-.469-1.787"
        data-name="Caminho 12198"
        transform="translate(-13279.589 -7444.689)"
      ></path>
      <path
        fill="#ee796a"
        d="M29.446 160.171a9.5 9.5 0 01-6.2-9.537 1.307 1.307 0 10-2.613 0 12.111 12.111 0 008.117 12.059 1.308 1.308 0 10.695-2.521"
        data-name="Caminho 12199"
        transform="translate(-13277.603 -7438.961)"
      ></path>
      <path
        fill="#ee796a"
        d="M30.17 164.238a6.692 6.692 0 01-2.964-2.275 9.055 9.055 0 01-.763-1.284c-.7-2.875-.692-3.36-.639-3.84.54-1.937.05-2.7-.6-2.878a1.335 1.335 0 00-1.609.915 9.7 9.7 0 005.884 11.883 1.308 1.308 0 10.695-2.521"
        data-name="Caminho 12200"
        transform="translate(-13273.496 -7431.525)"
      ></path>
      <path
        fill="#ee796a"
        d="M147.787 22.179a33.932 33.932 0 00-15.579-11.5 23.767 23.767 0 00-19.326 1.654 1.307 1.307 0 001.318 2.257 21.241 21.241 0 0117.646-1.274c5.682 2 10.41 6.025 14.09 10.709 1.04 1.321 2.88-.543 1.85-1.85"
        data-name="Caminho 12201"
        transform="translate(-13129.06 -7665.798)"
      ></path>
      <path
        fill="#ee796a"
        d="M142.99 16.321c-3.08-3.352-6.945-6.56-11.587-7.276a11.311 11.311 0 00-11.01 4.06c-1.009 1.347 1.261 2.652 2.257 1.318 1.992-2.66 5.808-3.4 8.9-2.7 3.871.886 6.972 3.6 9.587 6.447 1.145 1.24 2.988-.613 1.85-1.85"
        data-name="Caminho 12202"
        transform="translate(-13116.269 -7666.6)"
      ></path>
      <path
        fill="#ee796a"
        d="M145.536 14.956c-2.817-3.651-6.55-6.966-11.309-7.525-3.968-.464-8.421 1.35-10.169 5.1-.705 1.515 1.549 2.846 2.259 1.318 1.365-2.93 4.762-4.243 7.863-3.811 3.758.527 6.867 3.344 9.1 6.235a1.36 1.36 0 001.79.472 1.32 1.32 0 00.469-1.793"
        data-name="Caminho 12203"
        transform="translate(-13110.137 -7669.071)"
      ></path>
      <path
        fill="#ee796a"
        d="M145.376 10.938C143.09 7.416 138.638 7.245 134.911 8c-2.38.485-4.828 1.337-7.289.985a8.926 8.926 0 01-5.478-3.1c-1.1-1.276-2.941.579-1.848 1.85 3 3.486 7.362 4.406 11.736 3.567 3.245-.619 8.748-2.652 11.084.949.915 1.407 3.179.1 2.259-1.318"
        data-name="Caminho 12204"
        transform="translate(-13116.556 -7672.149)"
      ></path>
      <path
        fill="#ff9e00"
        d="M167.232 119.2c-9.144 1.942-18.3 3.837-27.425 5.834-7.422 1.622-14.892 3.509-21.706 6.953a40.557 40.557 0 00-9.254 6.329 41.145 41.145 0 00-7.441 9.553c-2.175 3.714-3.979 7.635-5.816 11.521-1.926 4.07-3.816 8.161-5.611 12.289a172.83 172.83 0 00-10.012 28.84c-.388 1.635 2.133 2.335 2.521.7a162.45 162.45 0 018.434-25.142c3.36-8.02 6.972-16.029 11-23.735a47.514 47.514 0 016.814-10.208 35.337 35.337 0 018.6-6.772c6.468-3.693 13.757-5.674 20.97-7.318 9.839-2.241 19.743-4.225 29.613-6.322 1.646-.349.949-2.87-.695-2.521"
        data-name="Caminho 12205"
        transform="translate(-13181.448 -7487.842)"
      ></path>
      <path
        fill="#dadef1"
        d="M61.452 71.1a3.738 3.738 0 01-3.077-.6c-.925-.535-1.858-1.064-2.789-1.6l-4.143-2.35a1.144 1.144 0 01-1.219-.349c-1.544-2.817-1.525-4.4-.828-5.813a1.337 1.337 0 011.785-.472q3.4 1.608 6.793 3.216c.975.464 1.95.923 2.928 1.386s2.076.873 2.44 1.987c.532 1.6-.207 4.013-1.89 4.594"
        data-name="Caminho 12206"
        transform="translate(-13231.672 -7584.128)"
      ></path>
      <path
        fill="#dadef1"
        d="M63.981 65.808a10.168 10.168 0 11-10.166-10.169 10.167 10.167 0 0110.166 10.169"
        data-name="Caminho 12207"
        transform="translate(-13240.255 -7590.817)"
      ></path>
      <path
        fill="#f5f5fa"
        d="M60.223 64.37a7.846 7.846 0 11-7.844-7.844 7.844 7.844 0 017.844 7.844"
        data-name="Caminho 12208"
        transform="translate(-13238.819 -7589.379)"
      ></path>
      <path
        fill="#ffbb4d"
        d="M134.735 50.865a4.494 4.494 0 00-2.146-1.966 4.241 4.241 0 00-2.752-.183 5.393 5.393 0 00-2.789 1.84 4.174 4.174 0 00-.949 2.825 4.839 4.839 0 003.47 4.233 4.715 4.715 0 002.634.034 4.3 4.3 0 002.367-1.732 5.043 5.043 0 00.165-5.05"
        data-name="Caminho 12209"
        transform="translate(-13106.623 -7602.274)"
      ></path>
      <path
        fill="#f66957"
        d="M20.178 51.529Q14.8 53.9 9.424 56.276a1.77 1.77 0 00.3 3.294l9.333 7.058a1.759 1.759 0 002.757-1.248l.8-12.087a1.765 1.765 0 00-2.443-1.764M13.7 58.2l5.257-2.322-.4 5.994q-2.43-1.831-4.857-3.672"
        data-name="Caminho 12210"
        transform="translate(-13297.353 -7597.717)"
      ></path>
      <path
        fill="#19c5ff"
        d="M23.252 113.575a6.564 6.564 0 00-3.148-2.561 6.158 6.158 0 00-4.411.163 7.044 7.044 0 00-4.3 7.931 6.667 6.667 0 002.471 3.5 7.488 7.488 0 003.5 1.287 6.32 6.32 0 006.358-3.124 7.183 7.183 0 00-.472-7.2"
        data-name="Caminho 12211"
        transform="translate(-13292.834 -7501.604)"
      ></path>
      <path
        fill="#ffd821"
        d="M10.665 80.213a5.5 5.5 0 01-6.487 1.829 5.728 5.728 0 01.985-11.157 5.43 5.43 0 015.989 2.886 6.479 6.479 0 01-.487 6.442"
        data-name="Caminho 12212"
        transform="translate(-13310.77 -7566.27)"
      ></path>
    </g>
  </svg>
)
export default RecruiterCardImg
