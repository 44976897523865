import { IconInterface } from '../../model/interfaces/components/icon.interface'

const EyeOpenIcon = ({
  width = 24,
  height = 24,
  color = '#4B1CD2',
  ...props
}: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.025 18.999C6.153 18.999 2.755 13.583 2.133 12.499C2.04523 12.347 1.99902 12.1745 1.99902 11.999C1.99902 11.8235 2.04523 11.651 2.133 11.499C2.987 10.013 6.2 5.14399 11.73 4.99899C11.83 4.99899 11.93 4.99899 12.024 4.99899C14.5883 5.04344 17.0403 6.05912 18.885 7.84099C20.0572 8.90381 21.0628 10.137 21.868 11.499C21.9558 11.651 22.002 11.8235 22.002 11.999C22.002 12.1745 21.9558 12.347 21.868 12.499C21.014 13.988 17.797 18.857 12.268 18.999H12.025ZM11.967 6.99899H11.781C9.9699 7.07187 8.23652 7.75444 6.862 8.93599C5.81097 9.81005 4.9036 10.8436 4.173 11.999C5.161 13.499 7.906 16.999 12.048 16.999H12.22C14.0303 16.9271 15.7632 16.246 17.138 15.066C18.1892 14.1933 19.0967 13.1607 19.827 12.006C18.839 10.499 16.1 6.99899 11.967 6.99899ZM12 15.499C11.3078 15.499 10.6311 15.2937 10.0555 14.9091C9.47993 14.5246 9.03133 13.9779 8.76642 13.3384C8.50151 12.6988 8.4322 11.9951 8.56725 11.3162C8.7023 10.6372 9.03564 10.0136 9.52512 9.52412C10.0146 9.03464 10.6382 8.70129 11.3172 8.56624C11.9961 8.4312 12.6998 8.50051 13.3394 8.76541C13.9789 9.03032 14.5256 9.47893 14.9101 10.0545C15.2947 10.6301 15.5 11.3068 15.5 11.999C15.5 12.9273 15.1312 13.8175 14.4749 14.4739C13.8185 15.1302 12.9283 15.499 12 15.499V15.499ZM12 10.499C11.7033 10.499 11.4133 10.587 11.1666 10.7518C10.92 10.9166 10.7277 11.1509 10.6142 11.425C10.5006 11.6991 10.4709 12.0007 10.5288 12.2916C10.5867 12.5826 10.7296 12.8499 10.9393 13.0597C11.1491 13.2694 11.4164 13.4123 11.7074 13.4702C11.9983 13.528 12.2999 13.4983 12.574 13.3848C12.8481 13.2713 13.0824 13.079 13.2472 12.8323C13.412 12.5857 13.5 12.2957 13.5 11.999C13.5 11.6012 13.342 11.2196 13.0607 10.9383C12.7794 10.657 12.3978 10.499 12 10.499V10.499Z"
      fill={color}
    />
  </svg>
)

export default EyeOpenIcon
