import {
  CandidateJourneyApiInterface,
  CandidateJourneyService,
} from '@recrutae/components'
import React from 'react'

import { HttpService } from '~/services/http.service'

export const useCandidateJourney = ({
  candidateId,
  jobId,
}: {
  candidateId: string
  jobId: string
}) => {
  const [loading, setLoading] = React.useState(false)
  const [journey, setJourney] = React.useState<CandidateJourneyApiInterface>(
    {} as CandidateJourneyApiInterface
  )

  const fetch = React.useCallback(async () => {
    setLoading(true)

    try {
      const http = new HttpService()
      const service = new CandidateJourneyService(http)
      const { data } = await service.getJourney({ candidateId, jobId })

      React.startTransition(() => {
        setJourney((data as any)?.data?.[0] || [])
        setLoading(false)
      })
    } catch (e) {
      React.startTransition(() => {
        setJourney({} as CandidateJourneyApiInterface)
        setLoading(false)
      })
    }
  }, [candidateId, jobId])

  React.useEffect(() => {
    if (candidateId && jobId) fetch().then()
  }, [fetch])

  return { journey, loading }
}
