import { Flex, Text } from '@chakra-ui/react'

export const CTAWithoutConversation = () => {
  return (
    <Flex
      p="8px"
      my="8px"
      w="100%"
      h="95px"
      alignItems="center"
      justifyContent="center"
    >
      <Text>Não há conversas disponíveis no momento.</Text>
    </Flex>
  )
}
