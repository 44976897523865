import { Box, ListItem, UnorderedList } from '@chakra-ui/react'
import { convertTextToList } from '@recrutae/components'

import TitleSteps from '~/components/title-steps/title-steps'

interface TechnicalRequirementsInterface {
  technicalRequirements: string
}

export const TechnicalRequirements = ({
  technicalRequirements,
}: TechnicalRequirementsInterface) => {
  return (
    <Box color="primaryDark.500" width="45%">
      <TitleSteps text="Requisitos técnicos" as="h2" />
      <Box display="flex">
        <UnorderedList mt="20px">
          {convertTextToList(technicalRequirements || '', '- ')?.map((item) => (
            <ListItem
              as="h3"
              color="primaryDark.500"
              key={item.indexOf.toString()}
            >
              {item}
            </ListItem>
          ))}
        </UnorderedList>
      </Box>
    </Box>
  )
}
