import { Box, Flex } from '@chakra-ui/react'
import React from 'react'

import { ChatApiInterface } from '~/model/interfaces/api/chat.interface'

import BoxChatActive from '../../../../components/chat/components/box-chat-active'
import ChatList from '../../../../components/chat/components/chat-list'

const ChatTab = ({ chats }: { chats: ChatApiInterface[] }) => {
  const [activeChat, setActiveChat] = React.useState<ChatApiInterface>(
    {} as ChatApiInterface
  )

  return (
    <Flex width="100%" h="752px">
      <Box w="40%">
        <ChatList chats={chats} setActiveChat={setActiveChat} />
      </Box>
      <Box w="60%">
        <BoxChatActive activeChat={activeChat} />
      </Box>
    </Flex>
  )
}

export default ChatTab
