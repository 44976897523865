import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'

import ChevronDownIcon from '~/assets/icons/chevron-down.icon'
import { useAuth } from '~/store/auth.context'

export const DropDownMenu = () => {
  const { logout } = useAuth()

  return (
    <Flex mr="8px">
      <Menu>
        <MenuButton>
          <Flex alignItems="center">
            <ChevronDownIcon />
            <Box
              ml="8px"
              borderRadius="35%"
              minWidth="50px"
              maxWidth="50px"
              maxHeight="50px"
              minHeight="50px"
              bgColor="primaryLight.500"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                src="/user-nav.svg"
                alt=""
                width="75%"
                height="50%"
                objectFit="fill"
                objectPosition="center"
              />
            </Box>
          </Flex>
        </MenuButton>
        <MenuList>
          <MenuItem onClick={logout}>Sair</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  )
}
