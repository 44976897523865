import EnglishIcon from '~/assets/icons/languages/english.icon'
import FrenchIcon from '~/assets/icons/languages/french.icon'
import GermanyIcon from '~/assets/icons/languages/germany.icon'
import ItalianIcon from '~/assets/icons/languages/italian.icon'
import JapanIcon from '~/assets/icons/languages/japan.icon'
import RussianIcon from '~/assets/icons/languages/russian.icon'
import EspanholIcon from '~/assets/icons/languages/spanish.icon'

import { languageEnum, LanguageEnumType } from './languages'

export const swapLanguageForIcons = (language: string) => {
  const languageToPortuguese = languageEnum[language as LanguageEnumType]

  switch (languageToPortuguese) {
    case 'Inglês':
      return <EnglishIcon />
    case 'Espanhol':
      return <EspanholIcon />
    case 'Francês':
      return <FrenchIcon />
    case 'Alemão':
      return <GermanyIcon />
    case 'Japonês':
      return <JapanIcon />
    case 'Russo':
      return <RussianIcon />
    case 'Italiano':
      return <ItalianIcon />
    default:
      return <></>
  }
}
