import { Box, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import React from 'react'

import TitleSteps from '~/components/title-steps/title-steps'
import { LanguageRequirementsApiInterface } from '~/model/interfaces/api/common/language-requirements.interface'
import {
  languageEnum,
  LanguageEnumType,
  languageLevelEnum,
  LanguageLevelEnumType,
} from '~/utils/from-to/languages'
import { swapLanguageForIcons } from '~/utils/from-to/langugesIcons'

interface ILanguages {
  languages: Array<LanguageRequirementsApiInterface>
}

export const Languages = ({ languages }: ILanguages) => {
  return (
    <Box mt="50px">
      <TitleSteps text="Idiomas" as="p" />
      <Flex
        alignItems="center"
        gap="20px"
        justifyContent="flex-start"
        mt="30px"
      >
        {languages?.map((item: LanguageRequirementsApiInterface) => (
          <Flex gap="100px" justifyContent="flex-start" key={item.language}>
            <Flex alignItems="center" gap="20px">
              {swapLanguageForIcons(item.language)}
              <Box alignItems="flex-start">
                <UnorderedList>
                  <ListItem color="primaryLight.200">
                    <Text color="primaryDark.500">Idioma</Text>
                  </ListItem>
                  <ListItem
                    listStyleType="none"
                    color="primaryDark.500"
                    fontWeight="bold"
                  >
                    {languageEnum[item.language as LanguageEnumType]}
                  </ListItem>
                </UnorderedList>
              </Box>
            </Flex>
            <Box alignItems="center">
              <UnorderedList>
                <ListItem color="primaryLight.200">
                  <Text color="primaryDark.500">Escrita</Text>
                </ListItem>
                <ListItem
                  listStyleType="none"
                  color="primaryDark.500"
                  fontWeight="bold"
                >
                  {languageLevelEnum[item.written as LanguageLevelEnumType]}
                </ListItem>
              </UnorderedList>
            </Box>
            <Box alignItems="flex-start">
              <UnorderedList>
                <ListItem color="primaryLight.200">
                  <Text color="primaryDark.500">Leitura</Text>
                </ListItem>
                <ListItem
                  listStyleType="none"
                  color="primaryDark.500"
                  fontWeight="bold"
                >
                  {languageLevelEnum[item.reading as LanguageLevelEnumType]}
                </ListItem>
              </UnorderedList>
            </Box>
            <Box alignItems="flex-start">
              <UnorderedList>
                <ListItem color="primaryLight.200">
                  <Text color="primaryDark.500">Oratória</Text>
                </ListItem>
                <ListItem
                  listStyleType="none"
                  color="primaryDark.500"
                  fontWeight="bold"
                >
                  {languageLevelEnum[item.spoken as LanguageLevelEnumType]}
                </ListItem>
              </UnorderedList>
            </Box>
          </Flex>
        ))}
      </Flex>
    </Box>
  )
}
