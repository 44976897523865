import {
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Link,
} from '@chakra-ui/react'
import React from 'react'

interface ScoreProgressInterface {
  typeformId: string
  score: number
  jobScore: number
}

export const ScoreProgress = ({
  jobScore,
  score,
  typeformId,
}: ScoreProgressInterface) => {
  const scorePercentual = React.useMemo(() => {
    if (score > jobScore || jobScore === 0) return 100
    return (score / jobScore) * 100
  }, [score, jobScore])

  const scoreColorPercentual = React.useMemo(() => {
    if (scorePercentual === 100) return 'primaryLight.900'
    if (scorePercentual >= 80) return 'secondary.600'
    if (scorePercentual < 80) return 'red'
  }, [scorePercentual])

  return (
    <Flex justifyContent="center">
      <Link
        isExternal={true}
        href={`https://admin.typeform.com/form/${typeformId}/results#responses`}
        fontSize="t-sm"
        fontWeight="bold"
      >
        <CircularProgress
          value={scorePercentual}
          color={scoreColorPercentual}
          capIsRound={true}
        >
          <CircularProgressLabel>
            {score} / {jobScore}
          </CircularProgressLabel>
        </CircularProgress>
      </Link>
    </Flex>
  )
}
