import { Box, Flex } from '@chakra-ui/react'

import { ChatApiInterface } from '~/model/interfaces/api/chat.interface'

import { CTASelectConversation } from './cta-select-conversation'
import NavChatActive from './nav-chat-active'
import ReceivedMessageChat from './received-message-chat'
import SentMessageChat from './sent-message-chat'

interface BoxChatActiveInterface {
  activeChat?: ChatApiInterface
}

const BoxChatActive = ({ activeChat }: BoxChatActiveInterface) => {
  return (
    <Box
      w="100%"
      h="100%"
      borderRadius="0 16px 16px 0"
      backgroundImage="/background-chat.jpg"
      backgroundSize="cover"
      overflow="auto"
    >
      {activeChat?.id ? (
        <>
          <NavChatActive chatActive={activeChat} />
          <Box
            as={Flex}
            flexDir="column"
            justifyContent="flex-end"
            w="100%"
            minHeight="calc(100% - 86px)"
            maxHeight="calc(100% - 86px)"
            overflowY="auto"
          >
            {activeChat?.messages?.map((chat) =>
              chat.user === 'Rê da Recrutaê' ? (
                <SentMessageChat message={chat.message} date={chat.date} />
              ) : (
                <ReceivedMessageChat message={chat.message} date={chat.date} />
              )
            )}
            {/* <SendMessage /> */}
          </Box>
        </>
      ) : (
        <CTASelectConversation />
      )}
    </Box>
  )
}

export default BoxChatActive
