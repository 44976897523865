import { As, Box, Heading, SpaceProps } from '@chakra-ui/react'

const TitleSteps = ({
  text,
  as = 'h3',
  ...props
}: { text: string; as?: As } & SpaceProps) => {
  return (
    <Box {...props}>
      <Heading as={as} fontWeight="bold" fontSize="xl">
        {text}
      </Heading>
      <Box width="40px" mt="8px" height="4px" bgColor="primaryLight.500" />
    </Box>
  )
}

export default TitleSteps
