import { IconComponentInterface } from '@recrutae/components'

const CalendarIcon = ({
  width = 12,
  height = 14,
  color = '#fff',
  ...props
}: IconComponentInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33333 0.333008C3.70152 0.333008 4 0.631485 4 0.999674V1.66634H8V0.999674C8 0.631485 8.29848 0.333008 8.66667 0.333008C9.03486 0.333008 9.33333 0.631485 9.33333 0.999674V1.66634H10C11.1046 1.66634 12 2.56177 12 3.66634V11.6663C12 12.7709 11.1046 13.6663 10 13.6663H2C0.895431 13.6663 0 12.7709 0 11.6663V3.66634C0 2.56177 0.895431 1.66634 2 1.66634H2.66667V0.999674C2.66667 0.631485 2.96514 0.333008 3.33333 0.333008ZM2.66667 2.99967H2C1.63181 2.99967 1.33333 3.29815 1.33333 3.66634V4.99967H10.6667V3.66634C10.6667 3.29815 10.3682 2.99967 10 2.99967H9.33333V3.66634C9.33333 4.03453 9.03486 4.33301 8.66667 4.33301C8.29848 4.33301 8 4.03453 8 3.66634V2.99967H4V3.66634C4 4.03453 3.70152 4.33301 3.33333 4.33301C2.96514 4.33301 2.66667 4.03453 2.66667 3.66634V2.99967ZM10.6667 6.33301H1.33333V11.6663C1.33333 12.0345 1.63181 12.333 2 12.333H10C10.3682 12.333 10.6667 12.0345 10.6667 11.6663V6.33301Z"
      fill={color}
    />
  </svg>
)

export default CalendarIcon
