import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react'
import React from 'react'

import RecruiterCardImg from '~/assets/img/recruiter-card.img'
import { JobApiInterface } from '~/model/interfaces/api/job.interface'
import { JobStatusEnum, JobStatusEnumType } from '~/utils/from-to/status'

interface HeaderJobInfoInterface {
  job: JobApiInterface
}

export const HeaderJobs = ({ job }: HeaderJobInfoInterface) => {
  const statusJob = React.useMemo(() => {
    switch (job.status) {
      case 'Canceled':
        return 'secondary.100'

      case 'Draft':
        return 'primary.500'

      case 'Finished':
        return 'primary.900'

      case 'Recruiting':
        return 'primary.500'

      default:
        return 'primary.500'
    }
  }, [job.status])

  return (
    <Flex
      bgImage="/background-jobdetail-web.png"
      bgPos="center"
      bgSize="100% 100%"
      bgRepeat="no-repeat"
      height="412px"
      width="100%"
      position="relative"
      borderRadius="35px"
      padding="30px 48px"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
    >
      <Box>
        <Box
          padding="8px 26px"
          bgColor={statusJob}
          borderRadius="xl"
          w="fit-content"
          fontWeight="bold"
          color="primary.500"
        >
          <Text fontSize="14px">
            {JobStatusEnum[(job?.status as JobStatusEnumType) || 'Recruiting']}
          </Text>
        </Box>
        <Image
          bgColor="white"
          width="100px"
          borderRadius="xl"
          mt="20px"
          height="50px"
          src={
            (process.env.REACT_APP_GCP_CLOUD_STORAGE as string) +
            job?.profileImageLink
          }
          alt=""
          objectFit="cover"
          objectPosition="center"
        />
        <Heading
          as="h1"
          color="white"
          fontWeight="bold"
          fontSize="2xl"
          mt="17px"
        >
          {job?.jobName}
        </Heading>
        <Heading
          as="h2"
          color="white"
          fontWeight="hairline"
          fontSize="medium"
          mt="10px"
        >
          {job?.companyName || ''}
        </Heading>
        <Flex mt="60px" wrap="wrap" gap="80px">
          <Box minWidth="fit-content">
            <Text color="primary.500" fontWeight="bold" fontSize="small">
              Faixa Salarial
            </Text>
            <Text
              color="white"
              fontWeight="hairline"
              fontSize="small"
              mt="14px"
            >
              {` R$ ${job.minPaymentRange},00 - R$ ${job.maxPaymentRange},00`}
            </Text>
          </Box>

          <Box minWidth="fit-content">
            <Text color="primary.500" fontWeight="bold" fontSize="small">
              Score
            </Text>
            <Button
              bgColor="primary.600"
              _hover={{ backgroundColor: 'primary.900' }}
              borderRadius="5px"
              size="xs"
              mt="m1"
              padding="0 12px"
            >
              {job.scoreToApprove}
            </Button>
          </Box>
        </Flex>
      </Box>
      <Stack spacing={0} pos="absolute" right={20} bottom={0}>
        <RecruiterCardImg height={450} />
      </Stack>
    </Flex>
  )
}
