import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'

interface PopoverInterface {
  trigger: React.ReactElement
  arrow: boolean
  close: boolean
  content: React.ReactElement
}

export const PopoversContent = ({
  trigger,
  arrow,
  content,
  close,
}: PopoverInterface) => {
  return (
    <Popover>
      <PopoverTrigger>{trigger}</PopoverTrigger>

      <PopoverCloseButton color="white" mr="8px" mt="8px" />
      <PopoverContent
        py="24px"
        backgroundColor="purpleDark.450"
        borderRadius="24px"
        w="280px"
        alignItems="center"
        justifyContent="center"
      >
        {arrow && <PopoverArrow backgroundColor="purpleDark.450" />}
        {close && <PopoverCloseButton color="white" mt="4px" mr="4px" />}
        <PopoverBody>{content}</PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
