import { IconInterface } from '../../model/interfaces/components/icon.interface'

const ChevronDownIcon = ({
  width = 14,
  height = 9,
  ...props
}: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.337942 1.00588C0.230815 1.11295 0.145834 1.24009 0.0878534 1.38001C0.0298729 1.51994 3.05176e-05 1.66992 3.05176e-05 1.82138C3.05176e-05 1.97284 0.0298729 2.12282 0.0878534 2.26275C0.145834 2.40268 0.230815 2.52981 0.337942 2.63688L5.69794 7.99688C5.80502 8.10401 5.93215 8.18899 6.07207 8.24697C6.212 8.30495 6.36198 8.33479 6.51344 8.33479C6.66491 8.33479 6.81488 8.30495 6.95481 8.24697C7.09474 8.18899 7.22187 8.10401 7.32894 7.99688L12.6889 2.63688C12.796 2.52979 12.881 2.40265 12.9389 2.26273C12.9969 2.1228 13.0267 1.97283 13.0267 1.82138C13.0267 1.51551 12.9052 1.22216 12.6889 1.00588C12.4727 0.789597 12.1793 0.668089 11.8734 0.668089C11.5676 0.668089 11.2742 0.789597 11.0579 1.00588L6.51394 5.55088L1.96894 1.00588C1.86187 0.898753 1.73474 0.813771 1.59481 0.755791C1.45488 0.697811 1.30491 0.667969 1.15344 0.667969C1.00198 0.667969 0.851999 0.697811 0.712073 0.755791C0.572147 0.813771 0.445016 0.898753 0.337942 1.00588Z"
      fill="#02004A"
    />
  </svg>
)
export default ChevronDownIcon
