import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import PlusIcon from '~/assets/icons/plus.icon'
import HomeCardImg from '~/assets/img/home-card.img'
import { useAuth } from '~/store/auth.context'

export const InitialCard = () => {
  const { user } = useAuth()
  return (
    <Flex
      backgroundColor="cyan.50"
      width="100%"
      h="120px"
      borderRadius="16px"
      padding="40px 40px 24px 40px"
      position="relative"
      justifyContent="space-between"
      overflow="hidden"
    >
      <Box>
        <Heading fontWeight="hairline" size="md">
          Olá, {user?.name}
        </Heading>
        <Text fontWeight="hairline" fontSize="md" color="cyan.800">
          Bem vindo(a) à área de Recrutador
        </Text>
      </Box>
      <Box
        position="absolute"
        right="330px"
        bottom={0}
        top="-20px"
        display="block"
        opacity="0.2"
        zIndex="0"
      >
        <HomeCardImg width={220} height={150} />
      </Box>
      <Button
        leftIcon={<PlusIcon width={24} />}
        colorScheme="primary"
        size="lg"
        as={Link}
        to="/job/new"
      >
        Cadastrar nova vaga
      </Button>
    </Flex>
  )
}
