export enum JobJourneyEventApiTypes {
  Approached = 'Approached',
  Applied = 'Applied',
  TestStarted = 'TestStarted',
  TestFinish = 'TestFinish',
  TestApproved = 'TestApproved',
  TestDisapproved = 'TestDisapproved',
  InterviewScheduled = 'InterviewScheduled',
  InterviewCancelled = 'InterviewCancelled',
  InterviewRescheduled = 'InterviewRescheduled',
  Placement = 'Placement',
  Displacement = 'Displacement',
  CandidateInteraction = 'CandidateInteraction',
}

export type JobJourneyEventApiType = keyof typeof JobJourneyEventApiTypes

export enum JobJourneyEventByApiTypes {
  Api = 'Api',
  Portal = 'Portal',
  WebhookSurvey = 'WebhookSurvey',
  WebhookTypeForm = 'WebhookTypeForm',
  WebhookCalendly = 'WebhookCalendly',
  WebhookWpp = 'WebhookWpp',
  WebhookSms = 'WebhookSms',
  WebhookEmail = 'WebhookEmail',
  Recruiter = 'Recruiter',
}

export type JobJourneyEventByApiType = keyof typeof JobJourneyEventByApiTypes

export enum CandidateJourneyStatusApiTypes {
  None = 'None',
  Approached = 'Approached',
  Applied = 'Applied',
  TestStarted = 'TestStarted',
  TestFinish = 'TestFinish',
  TestApproved = 'TestApproved',
  TestDisapproved = 'TestDisapproved',
  InterviewScheduled = 'InterviewScheduled',
  InterviewCancelled = 'InterviewCancelled',
  InterviewRescheduled = 'InterviewRescheduled',
  MeetingJoined = 'MeetingJoined',
  Placement = 'Placement',
  Displacement = 'Displacement',
  CandidateInteraction = 'CandidateInteraction',
}

export type CandidateJourneyStatusApiType =
  keyof typeof CandidateJourneyStatusApiTypes

export enum JobJourneyEventChannelApiTypes {
  SMS = 'SMS',
  Email = 'Email',
  WhatsApp = 'WhatsApp',
  LinkedIn = 'LinkedIn',
  None = 'None',
}
export type JobJourneyEventChannelApiType =
  keyof typeof JobJourneyEventChannelApiTypes
