export const jobLevelEnum = {
  Intern: 'Estagiário(a)',
  Trainee: 'Trainee',
  Junior: 'Junior',
  Pleno: 'Pleno',
  Senior: 'Sênior',
  Lead: 'Liderança',
  Manager: 'Gestor(a)',
}

export type JobLevelEnumType = keyof typeof jobLevelEnum
