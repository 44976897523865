import { TabList } from '@chakra-ui/react'

import TabTitleGuideMenu from './tab-title-guide-menu'

interface TabMenu {
  header: Array<string>
}

const TabMenu = ({ header }: TabMenu) => {
  return (
    <TabList mb="20px">
      {header.map((item) => (
        <TabTitleGuideMenu title={item} key={header.indexOf(item)} />
      ))}
    </TabList>
  )
}

export default TabMenu
