import {
  CheckboxProps,
  InputProps,
  RadioProps,
  SelectProps,
  TextareaProps,
} from '@chakra-ui/react'
import {
  CheckboxInput,
  FormItemInterface,
  PasswordInput,
  SelectInput,
  TextAreaInput,
  TextInput,
} from '@recrutae/components'
import { FC } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useFormContext } from 'react-hook-form'

import PhoneInput from '~/components/form/phone.input'

const FormItem: FC<
  | (FormItemInterface & InputProps)
  | (FormItemInterface & TextareaProps)
  | (FormItemInterface & SelectProps)
  | (FormItemInterface & CheckboxProps)
  | (FormItemInterface & RadioProps)
> = ({
  name,
  inputType,
  label,
  options,
  spacing,
  helperText,
  children,
  value,
  currency,
  ...props
}) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext()

  const formValue = inputType === 'checkbox' ? watch(name) : undefined

  return (
    <>
      {inputType === 'textArea' && (
        <TextAreaInput
          {...register(name)}
          label={label}
          spacing={spacing}
          helperText={helperText}
          error={errors?.[name]}
          {...(props as any)}
        />
      )}
      {inputType === 'select' && (
        <SelectInput
          {...register(name)}
          label={label}
          spacing={spacing}
          options={options as any}
          helperText={helperText}
          error={errors?.[name]}
          {...(props as any)}
        />
      )}
      {inputType === 'checkbox' && (
        <CheckboxInput
          helperText={helperText}
          error={errors?.[name]}
          value={value}
          isChecked={
            Array.isArray(formValue)
              ? formValue.includes(value)
              : value === formValue
          }
          {...register(name)}
          {...(props as any)}
        >
          {children}
        </CheckboxInput>
      )}
      {inputType === 'password' && (
        <PasswordInput
          {...register(name)}
          label={label}
          spacing={spacing}
          helperText={helperText}
          error={errors?.[name]}
          {...(props as any)}
        />
      )}
      {inputType === 'text' && (
        <TextInput
          {...register(name)}
          label={label}
          spacing={spacing}
          helperText={helperText}
          error={errors?.[name]}
          {...(props as any)}
        />
      )}
      {inputType === 'currency' && (
        <CurrencyInput
          name={name}
          decimalSeparator={currency?.decimalSeparator || '.'}
          groupSeparator={currency?.groupSeparator || ' '}
          decimalsLimit={2}
          prefix={currency?.prefix || 'R$ '}
          defaultValue={props.defaultValue as number}
          onValueChange={(value) => {
            setValue(name, value as string)
          }}
          customInput={(input) => (
            <TextInput label={label} {...input} {...props} />
          )}
        />
      )}
      {inputType === 'phone' && (
        <PhoneInput
          {...register(name)}
          onChange={(value) => setValue(name, value)}
          label={label}
          spacing={spacing}
          helperText={helperText}
          error={errors?.[name]}
          defaultValue={props.defaultValue as string}
          {...(props as any)}
        />
      )}
    </>
  )
}

export default FormItem
