import {
  JobLevelApiTypes,
  JobStatusApiTypes,
  JobWorkApiTypes,
} from '~/model/interfaces/api/common/job.interface'
import {
  LanguageLevelTypes,
  LanguageTypes,
} from '~/model/interfaces/api/common/language-requirements.interface'

export const languageOptions = [
  {
    value: LanguageTypes.English,
    label: 'Inglês',
  },
  {
    value: LanguageTypes.Spanish,
    label: 'Espanhol',
  },
  {
    value: LanguageTypes.French,
    label: 'França',
  },
  {
    value: LanguageTypes.German,
    label: 'Alemão',
  },
  {
    value: LanguageTypes.Japanese,
    label: 'Japonês',
  },
]

export const TechsOption = [
  {
    value: 'ReactJS',
    label: 'ReactJS',
  },
  {
    value: 'Java',
    label: 'Java',
  },
  {
    value: 'Microsservice',
    label: 'Microsservice',
  },
]

export const languageLevelOptions = [
  {
    value: LanguageLevelTypes.Basic,
    label: 'Básico',
  },
  {
    value: LanguageLevelTypes.Intermediate,
    label: 'Intermediário',
  },
  {
    value: LanguageLevelTypes.Advanced,
    label: 'Avançado',
  },
  {
    value: LanguageLevelTypes.Fluent,
    label: 'Fluente',
  },
]

export const statusOptions = [
  {
    value: JobStatusApiTypes.Draft,
    label: 'Rascunho',
  },
  {
    value: JobStatusApiTypes.Recruiting,
    label: 'Recrutando',
  },
  {
    value: JobStatusApiTypes.Finished,
    label: 'Finalizado',
  },
  {
    value: JobStatusApiTypes.Canceled,
    label: 'Cancelado',
  },
]

export const workTypeOptions = [
  {
    value: JobWorkApiTypes.Remote,
    label: 'Remoto',
  },
  {
    value: JobWorkApiTypes.Hybrid,
    label: 'Híbrido',
  },
  {
    value: JobWorkApiTypes.OnSite,
    label: 'Presencial',
  },
]

export const XPOption = [
  {
    value: '0 years',
    label: 'Até 1 ano(s)',
  },
  {
    value: '0-1 years',
    label: '0-1 ano(s)',
  },
  {
    value: '2-3 years',
    label: '2-3 ano(s)',
  },
  {
    value: '4-5 years',
    label: '4-5 ano(s)',
  },
  {
    value: '6-7 years',
    label: '6-7 ano(s)',
  },
  {
    value: '+7 years',
    label: 'Mais de 7 ano(s)',
  },
]

export const levelOptions = [
  {
    value: JobLevelApiTypes.Intern,
    label: 'Estágio',
  },
  {
    value: JobLevelApiTypes.Trainee,
    label: 'Trainee',
  },
  {
    value: JobLevelApiTypes.Junior,
    label: 'Júnior',
  },
  {
    value: JobLevelApiTypes.Pleno,
    label: 'Pleno',
  },
  {
    value: JobLevelApiTypes.Senior,
    label: 'Sênior',
  },
  {
    value: JobLevelApiTypes.Lead,
    label: 'Tech Lead',
  },

  {
    value: JobLevelApiTypes.Manager,
    label: 'Gerente',
  },
]

export const ContractTypeOption = [
  {
    value: 'CLT',
    label: 'CLT',
  },
  {
    value: 'PJ',
    label: 'PJ',
  },
]
