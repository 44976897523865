import { AnalyticsService } from '@recrutae/components'
import React from 'react'

import { JobJourneyCountByStatusAnalyticsApiInterface } from '~/model/interfaces/api/job-analytics.interface'
import { HttpService } from '~/services/http.service'

export const useJobAnalytics = (id: string) => {
  const [loading, setLoading] = React.useState(false)

  const [countByStatus, setCountByStatus] =
    React.useState<JobJourneyCountByStatusAnalyticsApiInterface>({
      totalApplied: 0,
      totalApproached: 0,
      totalCandidateInteraction: 0,
      totalMeetingJoined: 0,
      totalDisplacement: 0,
      totalInterviewCancelled: 0,
      totalInterviewScheduled: 0,
      totalPlacement: 0,
      totalTestApproved: 0,
      totalTestDisapproved: 0,
      totalTestFinish: 0,
      totalTestStarted: 0,
    })

  const fetch = React.useCallback(async () => {
    setLoading(true)

    try {
      const http = new HttpService()
      const service = new AnalyticsService(http)
      const { data } = await service.getTotalCandidateInJobByStatus(id)

      React.startTransition(() => {
        setCountByStatus(data)
        setLoading(false)
      })
    } catch (e) {
      React.startTransition(() => {
        setCountByStatus({} as JobJourneyCountByStatusAnalyticsApiInterface)
        setLoading(false)
      })
    }
  }, [id])

  React.useEffect(() => {
    if (id) fetch().then()
  }, [fetch])

  return { countByStatus, loading }
}
