import { JobApiInterface, JobService } from '@recrutae/components'
import React from 'react'

import { HttpService } from '~/services/http.service'

interface UseJobsParams {
  page?: number
  limit?: number
}

export const useJobs = ({ page = 1, limit = 100 }: UseJobsParams) => {
  const [loading, setLoading] = React.useState(false)
  const [jobs, setJobs] = React.useState<JobApiInterface[]>([])

  const fetch = React.useCallback(async () => {
    setLoading(true)

    try {
      const http = new HttpService()
      const service = new JobService(http)
      const { data } = await service.getJobs({ page, limit })
      React.startTransition(() => {
        setJobs(data.data)
        setLoading(false)
      })
    } catch (e) {
      React.startTransition(() => {
        setJobs([])
        setLoading(false)
      })
    }
  }, [])

  React.useEffect(() => {
    fetch().then()
  }, [fetch])

  return { jobs, loading }
}
