import {
  CandidateJourneyApiInterface,
  CandidateJourneyService,
} from '@recrutae/components'
import React from 'react'

import { HttpService } from '~/services/http.service'

interface UseInterviewsInterface {
  limit?: number
  pendingInterviews?: boolean
}

export const useInterviews = ({
  limit = 10,
  pendingInterviews = true,
}: UseInterviewsInterface) => {
  const [loading, setLoading] = React.useState(false)
  const [interviews, setInterviews] = React.useState<
    CandidateJourneyApiInterface[]
  >([])

  const fetch = React.useCallback(async () => {
    setLoading(true)

    try {
      const http = new HttpService()
      const service = new CandidateJourneyService(http)
      const { data } = await service.getAllInterviews({
        limit,
        pendingInterviews,
      })
      React.startTransition(() => {
        setInterviews(data.data)
        setLoading(false)
      })
    } catch (e) {
      React.startTransition(() => {
        setInterviews([])
        setLoading(false)
      })
    }
  }, [])

  React.useEffect(() => {
    fetch().then()
  }, [fetch])

  return { interviews, loading }
}
