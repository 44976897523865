import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import FormItem from '~/components/form/form-item'
import TitleSteps from '~/components/title-steps/title-steps'
import { JobContractApiTypes } from '~/model/interfaces/api/common/job.interface'
import { JobApiInterface } from '~/model/interfaces/api/job.interface'
import { JobSchemaInterface } from '~/model/schemas/job/job.schema.interface'

import { levelOptions, workTypeOptions } from '../register-options'

export const TypeJob = ({
  job,
  jobLoading,
}: {
  job?: JobApiInterface
  jobLoading: boolean
}) => {
  const {
    formState: { errors },
    unregister,
  } = useFormContext<JobSchemaInterface>()

  React.useEffect(() => {
    unregister('minPrice')
    unregister('maxPrice')
  }, [])

  return (
    <>
      <Grid gap="l4" mt="l4" gridTemplateColumns="1fr 1fr 1fr">
        <Box>
          <TitleSteps text="Tipo de contratação" as="h3" mb="m3" />
          <FormItem
            name="workType"
            inputType="select"
            options={workTypeOptions}
            label="Contratação"
            placeholder="Selecione"
          />
        </Box>
        <Box>
          <TitleSteps text="Nivel hierárquico" as="h3" mb="m3" />
          <FormItem
            name="level"
            inputType="select"
            options={levelOptions}
            label="Contratação"
            placeholder="Selecione"
          />
        </Box>
        <Box>
          <TitleSteps text="Faixa Salarial" as="h3" mb="m3" />
          <Flex gap="m2">
            {!jobLoading && (
              <>
                <FormItem
                  name="minPrice"
                  inputType="currency"
                  defaultValue={job?.minPaymentRange}
                  label="Menor preço"
                />
                <FormItem
                  name="maxPrice"
                  inputType="currency"
                  defaultValue={job?.maxPaymentRange}
                  label="Maior preço"
                />
              </>
            )}
          </Flex>
        </Box>
      </Grid>
      <TitleSteps text="Modelo de trabalho" as="h3" mt="l4" mb="m3" />
      <FormItem
        name="contractTypes"
        inputType="checkbox"
        value={JobContractApiTypes.CLT}
      >
        <Text>{JobContractApiTypes.CLT}</Text>
      </FormItem>
      <FormItem
        name="contractTypes"
        inputType="checkbox"
        value={JobContractApiTypes.PJ}
        mt="m1"
      >
        <Text>{JobContractApiTypes.PJ}</Text>
      </FormItem>
      {errors.contractTypes && (
        <Text mt="m3" color="red.500" fontSize="t-xs">
          Selecione ao menos um modelo de trabalho
        </Text>
      )}
    </>
  )
}
