import { TabPanel, TabPanels, Tabs as ChakraTabs } from '@chakra-ui/react'
import React from 'react'
import { v4 as createId } from 'uuid'

import TabMenu from '~/components/tab/tab-menu'

interface TabsContentInterface {
  header: Array<string>
  children: Array<React.ReactElement>
  onChange?: (index: number) => void
}

const TabsList = ({ header, children, onChange }: TabsContentInterface) => {
  return (
    <ChakraTabs mt="40px" variant="unstyled" w="100%" onChange={onChange}>
      <TabMenu header={header} />
      <TabPanels>
        {children.map((item) => (
          <TabPanel key={createId()}>{item}</TabPanel>
        ))}
      </TabPanels>
    </ChakraTabs>
  )
}

export default TabsList
