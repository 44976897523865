import { Box, Button, IconButton, Image } from '@chakra-ui/react'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import EditIcon from '~/assets/icons/edit.icon'
import PhotoIcon from '~/assets/icons/photo.icon'
import { JobApiInterface } from '~/model/interfaces/api/job.interface'
import { JobSchemaInterface } from '~/model/schemas/job/job.schema.interface'

export const UploadLogo = ({ job }: { job?: JobApiInterface }) => {
  const [filePath, setFilePath] = React.useState<string | undefined>(undefined)
  const { register, setValue } = useFormContext<JobSchemaInterface>()

  React.useEffect(() => {
    if (job && job.profileImageLink)
      setFilePath(
        process.env.REACT_APP_GCP_CLOUD_STORAGE + job.profileImageLink
      )
  }, [job])

  return (
    <Box
      height="100px"
      width="180px"
      overflow="hidden"
      borderRadius="3xl"
      border="2px"
      bgColor="whitesmoke"
      borderColor="purple.500"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      <Image
        src={filePath}
        alt=""
        position="absolute"
        width="100%"
        height="100%"
        objectFit="cover"
        objectPosition="center"
      />
      <input
        {...register('profileImageLink')}
        type="file"
        id="upload-profile"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={(event: any) => {
          if (event.target?.files?.length > 0) {
            const src = URL.createObjectURL(event.target.files[0])
            setFilePath(src)
            setValue('profileImageLink', event.target.files[0])
          } else setFilePath(undefined)
        }}
      />
      {!filePath && (
        <Button
          leftIcon={<PhotoIcon color="white" />}
          colorScheme="secondary"
          as="label"
          htmlFor="upload-profile"
          cursor="pointer"
        >
          Add Logo
        </Button>
      )}
      {filePath && (
        <IconButton
          colorScheme="secondary"
          as="label"
          htmlFor="upload-profile"
          cursor="pointer"
          position="absolute"
          right="8px"
          top="8px"
          aria-label="edit"
          size="sm"
        >
          <EditIcon width={15} height={15} />
        </IconButton>
      )}
    </Box>
  )
}
