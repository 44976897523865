import { z } from 'zod'

import { validationMessages } from '~/utils/validation/validation-messages'

const specialCharacters = ['#', '_', '*', '&', '%', '@', '!', '?', '/', '$']

export const jobSchema = z.object({
  jobName: z
    .string()
    .min(1, validationMessages.required)
    .refine(
      (value) =>
        !specialCharacters.some((character) => value.includes(character)),
      'Não são aceitos caracteres especiais'
    ),
  jobDescription: z.string().min(1, validationMessages.required),
  technicalRequirements: z.string().min(1, validationMessages.required),
  softSkillRequirements: z.string().min(1, validationMessages.required),
  benefits: z.string().min(1, validationMessages.required),
  status: z.string().min(1, validationMessages.required),
  level: z.string().min(1, validationMessages.required),
  workType: z.string().min(1, validationMessages.required),
  contractTypes: z.array(z.string()).min(1, validationMessages.chooseOne),
  minPrice: z.string().min(1, validationMessages.required),
  maxPrice: z.string().min(1, validationMessages.required),
  endingDateJobApplication: z.string(),

  cardImageLink: z.any(),
  profileImageLink: z.any(),

  address: z.object({
    state: z.string().min(1, validationMessages.required),
    city: z.string().min(1, validationMessages.required),
    neighbourhood: z.string().min(1, validationMessages.required),
  }),

  companyName: z.string().min(1, validationMessages.required),
  companyRecruiterName: z.string().min(1, validationMessages.required),
  companyRecruiterEmail: z.string().min(1, validationMessages.required),
  companyRecruiterPhone: z.string().min(1, validationMessages.required),
  companyVideoUrl: z.string(),
  recruitingDetails: z.string(),

  languages: z.array(
    z.object({
      language: z.string().min(1, validationMessages.required),
      written: z.string().min(1, validationMessages.required),
      reading: z.string().min(1, validationMessages.required),
      spoken: z.string().min(1, validationMessages.required),
    })
  ),

  typeFormId: z.string().min(1, validationMessages.required),
})
