import { IconInterface } from '../../model/interfaces/components/icon.interface'

const PhoneIcon = ({ width = 31, height = 32, ...props }: IconInterface) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.732 6.62939L28.302 8.2924C28.5428 8.37991 28.7639 8.51403 28.9528 8.68707C29.1417 8.86012 29.2946 9.06871 29.4028 9.3009C29.511 9.53309 29.5723 9.78433 29.5834 10.0403C29.5944 10.2962 29.5548 10.5518 29.467 10.7924L22.578 29.7184C22.3999 30.2036 22.037 30.5986 21.5685 30.8169C21.1 31.0352 20.5641 31.0591 20.078 30.8834L10.308 27.3284C9.82279 27.1503 9.42783 26.7874 9.20951 26.3189C8.99119 25.8504 8.96728 25.3145 9.14301 24.8284L11.154 19.3044"
      stroke="#4FD8E8"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M17.305 27.1082L14.863 26.2192"
      stroke="#4FD8E8"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M28.936 12.0122L24.299 10.3252"
      stroke="#4FD8E8"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M23.466 27.2762L10.034 22.3872"
      stroke="#4FD8E8"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M22.438 9.3123C22.438 4.7833 17.713 1.1123 11.885 1.1123C6.05699 1.1123 1.33099 4.7843 1.33099 9.3123C1.33099 13.8403 6.05599 17.5123 11.884 17.5123C12.5151 17.5105 13.1453 17.4644 13.77 17.3743C15.2661 18.5837 16.8885 19.6277 18.609 20.4883C19.376 20.8883 19.583 20.6213 19.181 19.8573C18.5464 18.6438 18.1676 17.3131 18.068 15.9473C20.715 14.4583 22.438 12.0453 22.438 9.3123Z"
      stroke="#4FD8E8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M16.257 5.33838H8.57599C7.47142 5.33838 6.57599 6.23381 6.57599 7.33838V11.1254C6.57599 12.2299 7.47142 13.1254 8.57599 13.1254H16.257C17.3616 13.1254 18.257 12.2299 18.257 11.1254V7.33838C18.257 6.23381 17.3616 5.33838 16.257 5.33838Z"
      stroke="#4FD8E8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M17.545 6.57617L12.715 9.80318C12.4664 9.96106 12.178 10.0449 11.8835 10.0449C11.589 10.0449 11.3006 9.96106 11.052 9.80318L6.22198 6.57617"
      stroke="#4FD8E8"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
)
export default PhoneIcon
