import { Box, Flex, Text } from '@chakra-ui/react'

import { JobSlimCard } from '~/components/cards/job/job-slim-card'
import { useJobs } from '~/hooks/jobs/use-jobs'

export const JobLists = () => {
  const { jobs } = useJobs({})

  return (
    <Box mt="60px">
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontWeight="bold" fontSize="xl">
          Últimas vagas publicadas
        </Text>
        {/* <Button
          variant="link"
          color="primary.700"
          fontSize="sm"
          fontWeight="bold"
          as={Link}
          to={appRoutes.search.url()}
        >
          Histórico de vaga(s)
        </Button> */}
      </Flex>
      {/* <SearchFilter /> */}
      <Flex mt="43px" wrap="wrap" gap="60px">
        {jobs.map((job) => (
          <JobSlimCard
            key={job.id}
            id={job.id}
            imgBackground={job.cardImageLink}
            imgLogo={job.profileImageLink}
            address={job.jobAddress}
            level={job.level}
            title={job.jobName}
            workType={job.workType}
            status={job.status}
          />
        ))}
      </Flex>
    </Box>
  )
}
