import { Button, Flex } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import LogoCompanyImg from '~/assets/img/logo-company.img'
import { DropDownMenu } from '~/components/nav/dropdown-menu'
import { appRoutes } from '~/utils/routes/app-routes'

export const MenuBar = () => {
  const user: any = {}

  return (
    <Flex
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      marginTop={['24px', '44px']}
      display={['none', 'flex']}
      as="nav"
    >
      <Flex alignItems="center" minWidth="fit-content">
        <Link to={appRoutes.home.url()}>
          <Button variant="link">
            <LogoCompanyImg />
          </Button>
        </Link>
      </Flex>
      {!user && (
        <Flex justifyContent="flex-end" alignItems="center">
          <Link to={appRoutes.login.url()}>
            <Button variant="link" mr="52px">
              Não possui cadastro?
            </Button>
          </Link>
          <Link to={appRoutes.login.url()}>
            <Button
              colorScheme="secondary"
              variant="outline"
              color="primary.600"
              borderColor="secondary.500"
            >
              Cadastraê
            </Button>
          </Link>
        </Flex>
      )}
      {user && (
        <Flex justifyContent="flex-end" alignItems="center">
          <Link to={appRoutes.home.url()}>
            <Button variant="link" mr="52px">
              Início
            </Button>
          </Link>
          <Link to={appRoutes.profile.url()}>
            <Button variant="link" mr="67px">
              Meu Perfil
            </Button>
          </Link>
          <DropDownMenu />
        </Flex>
      )}
    </Flex>
  )
}
