import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'

import {
  ChatApiInterface,
  MessageApiInterface,
} from '~/model/interfaces/api/chat.interface'
import { formatTimeDifference } from '~/utils/format-time-difference'

import CandidatePicture from '../../candidate-picture'

interface CardChatInterface {
  onClick: () => void
  chat: ChatApiInterface
}

const CardChat = ({ chat, onClick }: CardChatInterface) => {
  const lastMessage: MessageApiInterface | undefined = React.useMemo(() => {
    if (!chat.messages.length) return undefined

    const lastPosition = chat?.messages?.length - 1
    return chat.messages[lastPosition]
  }, [chat])

  return (
    <Flex
      p="8px"
      my="8px"
      w="100%"
      h="95px"
      alignItems="center"
      transition="all 0.2s"
      cursor="pointer"
      _hover={{ backgroundColor: 'black.200' }}
      onClick={onClick}
    >
      <Flex alignItems="center" w="80%">
        <CandidatePicture name={chat.candidateName || 'Candidato(a)'} />

        <Box alignItems="center" w="75%" ml="16px">
          <Text fontWeight="bold" noOfLines={1}>
            {chat.candidateName || 'Candidato(a)'}
          </Text>
          <Text fontSize="small" noOfLines={1}>
            {lastMessage?.message}
          </Text>
        </Box>
      </Flex>

      <Text fontSize="smaller">
        {lastMessage &&
          formatTimeDifference(new Date(lastMessage?.date), new Date())}
      </Text>
    </Flex>
  )
}

export default CardChat
